import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel
} from "@material-tailwind/react";
import { ProfileCard } from "./ProfileCard.js";

export function TabsAboutUs({ dataDetail }) {
  return (
    <Tabs value="overview">
      <TabsHeader>
        {dataDetail?.map(({ label, value, icon }) => (
          <Tab key={value} value={value}>
            <div className="flex items-center gap-2">
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {dataDetail?.map(({ value, desc }) => (
          <TabPanel key={value} value={value} classNames={["w-full h-max text-gray-700 p-4"]}>
            {value != "ourteam" ? <div className="text-justify md:py-4" dangerouslySetInnerHTML={{ __html: desc }} /> : GenerateProfile(desc)}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

function GenerateProfile(params) {
  return (
    <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 md:gap-4">
      {params?.map((item) => (
        <div className="flex justify-center items-center w-full h-full md:mt-4">
          <ProfileCard data={item} />
        </div>
      ))}
    </div>
  )
}