import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { dateConverter } from "../../app/helper/helper";
import logo from "../../images/logo.png";
import { useSelector } from "react-redux";

export function CardNews({ item }) {
    const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
    return (
        <Card className="mt-6 w-90 bg-ifmpallet2 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none" style={
            {
                paddingBottom: "0"
            }
        }>
            <CardHeader color="white" className="relative h-1/3 flex justify-center items-center">
                {
                    item?.imgUrl ?
                        <img
                            className="object-cover w-full"
                            src={item?.imgUrl}
                            alt="card-image"
                        /> : <img
                            className="object-cover w-full p-4"
                            src={logo}
                            alt="card-image"
                        />
                }
            </CardHeader>
            <CardBody className="mt-4" style={{ paddingTop: "0", paddingBottom: "0" }}>
                <Typography className="line-clamp-1" color="white" >
                    {item?.publisher}
                </Typography>
                <Typography variant="h5" className="text-ifmpallet3 line-clamp-2" color="yellow">
                    {item?.title}
                </Typography>
                <Typography className="line-clamp-1 mb-2" variant="small" color="white">
                    {item?.publishDate ? dateConverter(item?.publishDate) : null}
                </Typography>
                <Typography variant="p" className="line-clamp-5 text-justify" color="white">
                    {item?.description ? parse(item?.source) : null}
                </Typography>
            </CardBody>
            <CardFooter className="flex justify-end" style={{ paddingBottom: "0" }}>
                <Link to={`/news/${item?.id}/${item?.title.replace(/ /g,"-")}`} className="inline-block w-full flex justify-end">
                    <Button variant="text" className="flex items-center gap-1 text-ifmpallet3" color="orange">
                    {languageCode == 1033 ? "Read More" : "Selengkapnya"}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                        </svg>
                    </Button>
                </Link>
            </CardFooter>
        </Card>
    )
}