import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

export function ProfileModal({ open, cb, data }) {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  return (
    <>
      <Dialog
        open={open}
        handler={cb}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="p-2"
      >
        <DialogBody>
          <div className="flex h-full w-full justify-start items-center gap-4">
            <div className="w-1/4">
              <img src={data?.imgUrl} alt="profile-picture" className="h-28 w-28 object-cover rounded-full" />
            </div>
            <div className="w-3/4">
              <Typography type="lead" className="font-bold">{data?.name}</Typography>
              <Typography type="lead" className="">{ languageCode == 1033 ? data?.jobTitle : data?.jobTitleId}</Typography>
            </div>
          </div>
          <div className="w-full mt-4">
              {languageCode == 1033 ? parse(data?.profileDescriptionEn ? data?.profileDescriptionEn : "") : parse(data?.profileDescriptionId ? data?.profileDescriptionId : "")}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button className="bg-ifmpallet2 border-none" onClick={cb}>
            <span>{
              languageCode === 1033 ? "Close" : "Tutup"
            }</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}