import React, { useEffect, useRef, useState } from "react";
import { InstagramEmbed } from 'react-social-media-embed';
import { OurMission, TheGoals, NewsList, ProgramsList, SingleBanner, PublicationsList, useIsVisible, NewsCarousel, StoryList, MapPartnerComponent } from "../../components/index.js";
import {
  Button,
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { getClientHomeData, setLoaderVisibility } from "../../app/actions/action-creators.js";
import { Link } from "react-router-dom";
import { enLabel, idLabel } from "../../data/static-config.js";

const Home = () => {
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const clientLang = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const data = useSelector((state) => state?.combineReducer?.clientHome);

  const dispatch = useDispatch();

  const [label, setLabel] = useState({
    instagram: "Ikuti kami di instagram :",
    programs: "Program",
    news: "Berita Terbaru",
    publication: "Publikasi"
  }
  );

  useEffect(() => {
    clientLang === 1045 ? setLabel(idLabel) : setLabel(enLabel);
    dispatch(getClientHomeData({ languageCode: clientLang }));
  }, [clientLang]);

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      dispatch(setLoaderVisibility(true))
    } else {
      dispatch(setLoaderVisibility(false))
    }
  }, [data]);

  return (
    <>
      <SingleBanner data={data?.data?.banner} />
      <div ref={ref1} className={`transition-opacity ease-in duration-700 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
        <div className="md:flex w-full h-full justify-center">
          <div className="h-full md:w-2/3 p-2 md:p-10 h-[42em]">
            <OurMission data={data?.data?.mission[0]} />
            <TheGoals data={data?.data?.goals} />
          </div>
          <div className="w-full md:w-1/3 md:p-10 md:h-[42em]">
            <Card className="w-96 w-full p-2 rounded-none" shadow={true}>
              <Typography
                variant="h6"
                className="mb-1 p-1 text-ifmpallet1"
              >
                {data?.data?.media?.title}
              </Typography>
              {data?.data?.media?.link != null ? <CardBody className="overflow-scroll p-2" style={{ height: 510 }}><InstagramEmbed url={data?.data?.media?.link} /> </CardBody> : ""}
            </Card>
          </div>
        </div>
      </div>
      <div ref={ref2} className={`transition-opacity ease-in duration-700 ${isVisible2 ? "opacity-100" : "opacity-0"}`}>
        <div className="w-full h-full bg-gray-100 flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 px-2 py-2 md:px-8 md:pt-8">
            <Typography variant="h3" color="black" className="w-full p-5 text-start text-ifmpallet1">
              {label?.news}
            </Typography>
            <NewsList latestNews={data?.data?.latestnews} />
            {/* <NewsCarousel /> */}
            <Link to="/news" className="px-2 md:hidden md:px-4 inline-block w-full flex justify-end bg-transparent">
              <ButtonMore params={label?.more} />
            </Link>
          </div>
          <div className="w-full md:w-1/3 px-2 py-2 md:pr-12 md:py-8 flex flex-col">
            <Typography variant="h3" color="black" className="w-full p-5 text-start text-ifmpallet1">
              {label?.story}
            </Typography>
            {
              data?.data?.lateststory?.length > 0 ?
                <div>
                  <StoryList progList={data?.data?.lateststory} />
                  <Link to="/news" className="md:hidden px-2 md:px-4 inline-block w-full flex justify-end bg-transparent items-end">
                    <ButtonMore params={label?.more} />
                  </Link>
                </div> : <div className="h-full w-full flex justify-center items-center">
                  <Typography variant="lead" color="black" className="w-full p-5 text-center text-ifmpallet1">
                    {clientLang == 1033 ? "-- Data not found --" : "-- Data tidak tersedia --"}
                  </Typography>
                </div>
            }
          </div>
        </div>
        <div className="md:flex w-full md:pb-8 hidden md:block bg-gray-100">
          <div className="w-full h-full flex flex-col justify-around md:w-2/3">
            <Link to="/news" className="px-2 md:px-4 inline-block w-full flex justify-end bg-transparent">
              <ButtonMore params={label?.more} />
            </Link>
          </div>
          <div className="w-full h-full flex flex-col justify-around md:w-1/3 md:pr-12">
            <Link to="/story" className="px-2 md:px-4 inline-block w-full flex justify-end bg-transparent items-end">
              <ButtonMore params={label?.more} />
            </Link>
          </div>
        </div>
      </div>
      <div ref={ref3} className={`transition-opacity ease-in duration-700 ${isVisible3 ? "opacity-100" : "opacity-0"}`}>
        <div className="md:flex w-full md:px-12 md:pt-8">
          <div className="w-full h-full flex flex-col justify-around md:w-1/2">
            <Typography variant="h3" color="black" className="w-full p-5 text-start text-ifmpallet1">
              {label?.programs}
            </Typography>
            <ProgramsList progList={data?.data?.latestprograms} />
            <Link to="/program" className="px-2 md:hidden md:px-4 inline-block w-full flex justify-end bg-transparent">
              <ButtonMore params={label?.more} />
            </Link>
          </div>
          <div className="w-full h-full flex flex-col justify-around md:w-1/2">
            <Typography variant="h3" color="black" className="w-full p-5 text-start mb-2 text-ifmpallet1">
              {label?.partner}
            </Typography>
            <MapPartnerComponent markers={data?.data?.partnerlist}/>
            <Link to="/partner" className="md:hidden px-2 md:px-4 inline-block w-full flex justify-end bg-transparen">    <ButtonMore params={label?.more} /></Link>
          </div>
        </div>
        <div className="md:flex w-full md:pb-8 hidden md:block">
          <div className="w-full h-full flex flex-col justify-around md:w-1/2">
            <Link to="/program" className="px-2 md:px-4 inline-block w-full flex justify-end bg-transparent">
              <ButtonMore params={label?.more} />
            </Link>
          </div>
          <div className="w-full h-full flex flex-col justify-around md:w-1/2">
            <Link to="/partner" className="px-2 md:px-4 inline-block w-full flex justify-end bg-transparen">    <ButtonMore params={label?.more} /></Link>
          </div>
        </div>
      </div>
    </>
  )
}

function ButtonMore({ params }) {
  return (
    <Button className="mt-2 mb-2 flex items-end justify-end gap-1 bg-transparent shadow-none"><Typography className="flex gap-2 justify-center items-center text-ifmpallet1" type="md" variant="h6" color="orange">{params} <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      className="h-4 w-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
      />
    </svg></Typography></Button>
  )
}

export default Home;
