import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Select, Option, Checkbox, Textarea
} from "@material-tailwind/react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../data/static-config";
import { setLoaderVisibility } from "../../app/actions/action-creators";

export function ModalFormRequestData({ callback, id }) {
  const [label, setLabel] = useState("");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const dispatch = useDispatch();
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

  useEffect(() => {
    languageCode == 1033 ? setLabel("Request Raw Data") : setLabel("Ajukan Permohonan Data")
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //dispatch(setLoaderVisibility(true))
    const reqParams = {
      "name": name,
      "email": email,
      "description": description
    };

    dispatch(setLoaderVisibility(true))

    axios
      .post(`${API_BASE_URL}/api/client/module/requestdata`, reqParams)
      .then(({ data }) => {
        dispatch(setLoaderVisibility(false));
        swal(data?.data, "", "success").then((_) => {
          callback();
        });
      })
      .catch((e) => {
        console.log(e)
        dispatch(setLoaderVisibility(false))
      })
  }

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none" style={{ zIndex: 1100 }}>
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <Typography variant="h5">
                {label}
              </Typography>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="relative p-2 flex-auto">
                <div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
                  <div className="flex gap-2">
                    <Input label="Name" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={name} onChange={(e) => setName(e.target.value)} required />
                    <Input label="Email" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="flex gap-2">
                    <Textarea label="Description" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={description} onChange={(e) => setDescription(e.target.value)} required />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={callback}
                >
                  {languageCode == 1033 ? "Close" : "Tutup"}
                </button>
                <Button
                  className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="submit"
                >
                  {languageCode == 1033 ? "Submit" : "Kirim"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}