import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { StickyNavbar, FooterWithSocialLinks } from "../../components/index.js";
import { Spinner } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const MainPage = () => {
	const isLoaderVisible = useSelector((state) => state?.combineReducer?.loader);
	const { pathname } = useLocation();

	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		if(isLoaderVisible){
			showSpinner()
		} else{
			hideSpinner();
		}
	}, [isLoaderVisible]);

	function showSpinner() {
		document.getElementById('spinner-overlay').classList.remove('hidden');
	}

	function hideSpinner() {
		document.getElementById('spinner-overlay').classList.add('hidden');
	}

	return (
		<div className="h-full w-full">
			<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" id="spinner-overlay" style={{ zIndex: 2500 }}>
				<Spinner size="large" color="blue" />
			</div>
			<StickyNavbar />
			<Outlet />
			<FooterWithSocialLinks />
		</div>
	);
};

export default MainPage;
