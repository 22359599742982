import React from "react";
import { NavbarCMS, TableWithStripedRows } from "../../components";
import { useSelector } from "react-redux";

const GoalCMS = () => {
    const setting = useSelector((state) => state?.combineReducer?.settings);
    return (
        <div className="h-full w-full">
            <NavbarCMS module={setting?.about_goals?.navbar} />
            <TableWithStripedRows />
        </div>
    )
}

export default GoalCMS;