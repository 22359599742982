import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Input,
    Button,
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getClientFooterData, setAuthentication } from "../../app/actions/action-creators";
import swal from 'sweetalert';
import { API_BASE_URL } from "../../data/static-config.js";
import {
    ArrowLeftIcon
} from "@heroicons/react/24/solid";

const LoginAdmin = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const logo = useSelector((state) => state?.combineReducer?.clientFooter?.logo);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!logo){
            dispatch(getClientFooterData({
                languageCode: 1033
              }))
        }
    }, [logo])

    const handleOnSubmit = (e) => {
        e.preventDefault();
        let req = {
            email: email, password: password
        };
        loginAdmin(req);
    };

    const loginAdmin = (params) => {
        axios
            .post(`${API_BASE_URL}/api/user/login`, params)
            .then((res) => {
                let data = {
                    token: res.data.token,
                    auth: res.data.success,
                    email: res.data.email
                }
                dispatch(setAuthentication(data));
                navigate("/cms/dashboard");
            })
            .catch((e) => {
                let data = {
                    msg: e.response.data.message,
                    auth: e.response.data.success
                }
                dispatch(setAuthentication(data));
                swal("Oppss...", data.msg[0], "error");
            })
    }

    return (
        <div className="bg-gradient-to-b from-green-900 to-blue-gray-800 h-screen">
            <div className="pl-8 pt-8">
                <Link to="/" className="ml-1 h-1/5 flex gap-2">
                    <ArrowLeftIcon values="solid" color="white" className="w-4" />
                    <Typography
                        variant="small"
                        color="white"
                        className="font-bold"
                    >
                        Back to Home
                    </Typography>
                </Link>
            </div>
            <div className="flex h-4/5 justify-center items-center">
                <Card className="w-96">
                    <form onSubmit={(event) => handleOnSubmit(event)}>
                        <CardBody className="flex flex-col gap-4">
                            {
                                logo != null ? <img
                                    className="h-20 p-3 object-contain object-center"
                                    src={logo[0].logoUrl}
                                    alt="Logo Ifm"
                                /> : null
                            }
                            <Input label="Email" size="lg" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            <Input label="Password" size="lg" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button variant="gradient" fullWidth type="submit">
                                Sign In
                            </Button>
                            <Typography variant="small" className="mt-6 flex justify-center">
                                Forgot Password?
                                <Link to="/reset" className="mr-1">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="ml-1 font-bold"
                                    >
                                        Reset Password
                                    </Typography>
                                </Link>
                            </Typography>
                        </CardFooter>
                    </form>
                </Card>
            </div>
        </div>
    );
};

export default LoginAdmin;
