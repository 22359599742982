import React from "react";
import { TableAboutGeneral } from "../../components/admin/Table/TableAboutGeneral";
import { NavbarCMS, TableSocialLinks } from "../../components";
import { useSelector } from "react-redux";

const AboutCMS = () => {
    const setting = useSelector((state) => state?.combineReducer?.settings);
    return (
        <div className="h-full w-full">
            <NavbarCMS module={setting?.about_general?.navbar} />
            <TableAboutGeneral />
            <TableSocialLinks />
        </div>
    )
}

export default AboutCMS;