import React from "react";
import { Carousel, Typography, Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import {
  CloudArrowDownIcon
} from "@heroicons/react/24/solid";

export function SingleBanner({ data }) {
  //let data = [{ hashtag: "CALL PROPOSAL NOT AVAILABLE", imgUrl: "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80", title: "Monitor the forest, support together, save our natural resources.", description: "IFM Fund invites individuals who conduct independent monitoring, civil society organizations, local/ indigenous community groups, research institutions to be involved in monitoring the Timber Legality Assurance System (SVLK) implementation and improving forest governance in Indonesia.", buttonText: "Support Independent Monitors" }];

  return (
    data?.map((item) => (
      <div className="relative h-full w-full">
        <img
          src={item.imgBanner}
          alt="image 1"
          className="w-screen h-screen object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-left md:w-2/4">
            {item ? <div className="mb-4 mt-4 text-center text-bold text-ifmpallet3 md:text-left" dangerouslySetInnerHTML={{ __html: item.hashtag }}></div> : null}
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-center md:text-left text-3xl md:text-4xl lg:text-5xl transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
            >
              {item.title}
            </Typography>
            <Typography
              variant="medium"
              color="white"
              className="mb-4 text-center md:text-left md:mb-12 opacity-80 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
            >
              {item.description ? parse(item.description) : null}
            </Typography>
            <div className="flex justify-start md:gap-2">
              <Link to="/donate">
                <Button size="lg" className="bg-ifmpallet2">
                  {item.buttonText}
                </Button>
              </Link>
              {/* <a href={item.fileDownloadUrl} target="_blank">
                <Button size="lg" color="gray" className="bg-ifmpallet3 text-ifmpallet1 flex justify-center items-center hover:cursor-pointer">
                  <CloudArrowDownIcon className="h-4 mr-2 text-ifmpallet1" />Cerita Sukses IFM
                </Button>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    ))
  );
}