import React, { useEffect, useState } from "react";
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
    Switch,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NavbarCMS } from "../NavbarCMS";

export function FormProgramList() {
    const { progId } = useParams();
    const [lable, setLabel] = useState({});
    const [title, setTitle] = useState();
    const [language, setLanguage] = useState(1033);
    const [subtitle, setSubtitle] = useState();
    const [image, setImage] = useState();
    const [banner, setBanner] = useState();
    const [isGrants, setIsGrants] = useState();
    const [stateCode, setStateCode] = useState();
    const [project, setProject] = useState();
    const [objective, setObjective] = useState();
    const [activity, setActivity] = useState();
    const [output, setOutput] = useState();
    const [guidelines, setGuidelines] = useState();
    const [proposalFormat, setProposalFormat] = useState();
    const [sourceFile, setSoureFile] = useState();
    const [description, setDescription] = useState();
    const [topic, setTopic] = useState();
    const [budget, setBudget] = useState();
    const [requirements, setRequirements] = useState();
    const [classShowHide, setClassShowHide] = useState({
        classNoGrants: "flex flex-col gap-4 block",
        classGrants: "flex flex-col gap-4 hidden"
    });
    const [publishDate, setPublishDate] = useState();

    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (progId) {
            dispatch(setLoaderVisibility(true));
            setLabel({
                title: "Update Program List",
                subtitle: "Enter your data details for update data",
                buttonLabel: "Update Data"
            })
            axios.get(`${API_BASE_URL}/api/program/${progId}`, { headers: { access_token: token } }).then(({ data }) => {
                dispatch(setLoaderVisibility(false));
                console.log(data);
                setTitle(data.data.title);
                setSubtitle(data.data.subtitle);
                setDescription(data.data.description);
                setLanguage(data.data.languageCode);
                setIsGrants(data.data?.isgrants);
                data.data?.stateCode == 0 ? setStateCode(false) : setStateCode(true);
                setDescription(data.data?.description);
                setTopic(data.data?.topic);
                setBudget(data.data?.budget);
                setRequirements(data.data?.requirements);
                setProject(data.data?.project);
                setObjective(data.data?.objective);
                setActivity(data.data?.activity);
                setOutput(data.data?.output);
                data.data.isgrants ? setClassShowHide({
                    classNoGrants: "flex flex-col gap-4 hidden",
                    classGrants: "flex flex-col gap-4 block"
                }) : setClassShowHide({
                    classNoGrants: "flex flex-col gap-4 block",
                    classGrants: "flex flex-col gap-4 hidden"
                });
                const currentDate = new Date(data?.data?.publishDate);
                currentDate.setHours(currentDate.getHours() + 7);
                setPublishDate(currentDate.toISOString().substring(0, 10));
            }).catch((e) => {
                console.log(e);
                dispatch(setLoaderVisibility(false))
            })
        } else {
            setLabel({
                title: "Add Program List",
                subtitle: "Enter your data details for add data",
                buttonLabel: "Add Data"
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true));
        const reqParams = new FormData();
        reqParams.append("publishDate", publishDate);
        reqParams.append("title", title);
        reqParams.append("subtitle", subtitle);
        reqParams.append("languageCode", language);
        reqParams.append("isgrants", isGrants);
        reqParams.append("description", description);
        reqParams.append("topic", topic);
        reqParams.append("budget", budget);
        reqParams.append("requirements", requirements);
        reqParams.append("project", project);
        reqParams.append("objective", objective);
        reqParams.append("activity", activity);
        reqParams.append("output", output);
        reqParams.append("stateCode", stateCode ? 1 : 0);
        reqParams.append("image", image);
        reqParams.append("banner", banner);
        reqParams.append("guidelines", guidelines);
        reqParams.append("proposalFormat", proposalFormat);
        reqParams.append("sourceFile", sourceFile);

        if (progId) {
            axios
                .put(`${API_BASE_URL}/api/program/${progId}`, reqParams, {
                    headers: {
                        access_token: token
                    }
                })
                .then(({ data }) => {
                    dispatch(setLoaderVisibility(false));
                    swal("Data updated", "", "success").then((_) => {
                        navigate("/cms/program");
                    });
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setLoaderVisibility(false));
                })
        } else {
            axios
                .post(`${API_BASE_URL}/api/program`, reqParams, {
                    headers: {
                        access_token: token
                    }
                })
                .then(({ data }) => {
                    dispatch(setLoaderVisibility(false));
                    swal("Data created", "", "success").then((_) => {
                        navigate("/cms/program");
                    });
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setLoaderVisibility(false));
                })
        }
    };

    function handleChangeFile(e, fieldName) {
        if (fieldName == "image") {
            setImage(e.target.files[0])
        } else if (fieldName == "banner") {
            setBanner(e.target.files[0])
        } else if (fieldName == "guidelines") {
            setGuidelines(e.target.files[0])
        } else if (fieldName == "proposalFormat") {
            setProposalFormat(e.target.files[0])
        } else if (fieldName == "source") {
            setSoureFile(e.target.files[0])
        }
    }

    function handleSwitchChange(e, fieldCode) {
        var checkedValue = e.target.checked;
        if (fieldCode == 1) {
            setIsGrants(checkedValue);
            if (e.target.checked) {
                setClassShowHide({
                    classNoGrants: "flex flex-col gap-4 hidden",
                    classGrants: "flex flex-col gap-4 block"
                })
            } else {
                setClassShowHide({
                    classNoGrants: "flex flex-col gap-4 block",
                    classGrants: "flex flex-col gap-4 hidden"
                })
            }
        } else if (fieldCode == 2) {
            setStateCode(checkedValue);
        }
    }

    return (
        <>
            <NavbarCMS module={lable?.title} />
            <div className="w-full">
                <Card color="transparent" shadow={false} className="h-full w-full flex justify-center items-center p-4">
                    <Typography variant="h4" color="blue-gray">
                        {lable?.title}
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        {lable.subtitle}
                    </Typography>
                    <form className="mt-8 mb-2 w-2/3" onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-1 flex flex-col gap-6">
                            <div className="flex gap-2">
                                <Input
                                    label="Title"
                                    size="lg"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <div className="w-full">
                                    <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language} label="Language">
                                        <Option value={1033} aria-label="English">English</Option>
                                        <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <div className="w-full">
                                    <Input
                                        size="lg"
                                        label="Subtitle"
                                        className="pr-20"
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                        value={subtitle}
                                        onChange={(e) => setSubtitle(e.target.value)}
                                    />
                                </div>
                                {/* <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="file" label="Source File"
                                        className="pr-20" onChange={(e) => handleChangeFile(e, "source")}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                    />
                                </div> */}
                                <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="date" label="Publish Date"
                                        className="pr-20" onChange={(e) => setPublishDate(e.target.value)}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                        value={publishDate}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="file" label="Image"
                                        className="pr-20" onChange={(e) => handleChangeFile(e, "image")}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                    />
                                </div>
                                <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="file" label="Banner"
                                        className="pr-20" onChange={(e) => handleChangeFile(e, "banner")}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-2">
                                <div className="w-1/3">
                                    <Switch color="green" label="Call Proposal" onChange={(e) => handleSwitchChange(e, 1)} value={isGrants} defaultChecked={isGrants} />
                                </div>
                                <div className="w-1/3">
                                    <Switch color="green" label="Status" onChange={(e) => setStateCode(e.target.checked)} value={stateCode} defaultChecked={stateCode} />
                                </div>
                            </div>
                            <div id="NoGrants" className={classShowHide?.classNoGrants}>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Project
                                    </Typography>
                                    <ReactQuill theme="snow" value={project} onChange={setProject} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Objective
                                    </Typography>
                                    <ReactQuill theme="snow" value={objective} onChange={setObjective} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Activity
                                    </Typography>
                                    <ReactQuill theme="snow" value={activity} onChange={setActivity} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Output
                                    </Typography>
                                    <ReactQuill theme="snow" value={output} onChange={setOutput} />
                                </div>
                            </div>
                            <div id="Grants" className={classShowHide?.classGrants}>
                                <div className="flex gap-2">
                                    <div className="relative flex w-full max-w-[28rem]">
                                        <Input size="lg" placeholder="Content" type="file" label="Guidelines"
                                            className="pr-20" onChange={(e) => handleChangeFile(e, "guidelines")}
                                            containerProps={{
                                                className: "min-w-0",
                                            }}
                                        />
                                    </div>
                                    <div className="relative flex w-full max-w-[28rem]">
                                        <Input size="lg" placeholder="Content" type="file" label="Proposal Format"
                                            className="pr-20" onChange={(e) => handleChangeFile(e, "proposalFormat")}
                                            containerProps={{
                                                className: "min-w-0",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Description
                                    </Typography>
                                    <ReactQuill theme="snow" value={description} onChange={setDescription} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Topic
                                    </Typography>
                                    <ReactQuill theme="snow" value={topic} onChange={setTopic} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Budget
                                    </Typography>
                                    <ReactQuill theme="snow" value={budget} onChange={setBudget} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Requirements
                                    </Typography>
                                    <ReactQuill theme="snow" value={requirements} onChange={setRequirements} />
                                </div>
                            </div>
                        </div>
                        <Button className="mt-6" fullWidth type="submit">
                            {lable?.buttonLabel}
                        </Button>
                    </form>
                </Card>
            </div>
        </>
    );
}