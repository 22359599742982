import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Select, Option, Checkbox
} from "@material-tailwind/react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../../data/static-config";
import { setLoaderVisibility } from "../../../app/actions/action-creators";

export function ModalFormPublication({ callback, id, categories }) {
  const [label, setLabel] = useState("");
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [category, setCategory] = useState(0);
  const [language, setLanguage] = useState(1033);
  const [file, setFile] = useState();
  const [imgCover, setImgCover] = useState();
  const [publishDate, setPublishDate] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(setLoaderVisibility(true))
      setLabel("Update Publication Document");
      setIsUpdateForm(true)
      try {
        axios
          .get(`${API_BASE_URL}/api/publication/${id}`, {
            headers: {
              access_token: token
            }
          })
          .then(({ data }) => {
            dispatch(setLoaderVisibility(false))
            setTitle(data?.data?.title);
            setDescription(data?.data?.description);
            setCategory(data?.data?.categoryCode);
            setLanguage(data?.data?.languageCode);
            const date = new Date(data?.data?.publishDate);
            date.setHours(date.getHours() + 7);
            setPublishDate(date.toISOString().substring(0,10));
            setFileUrl(data?.data?.fileUrl);
          })
          .catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
      } catch (error) {
        console.log(error);
        dispatch(setLoaderVisibility(false))
      }
    } else {
      setLabel("Add Publication");
      setIsUpdateForm(false);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoaderVisibility(true));
    const reqParams = new FormData()
    reqParams.append("title", title);
    reqParams.append("description", description);
    reqParams.append("categoryCode", category);
    reqParams.append("languageCode", language);
    reqParams.append("publishDate", publishDate);
    reqParams.append('file', file);
    reqParams.append('image', imgCover);

    try {
      if (id) {
        axios
          .put(`${API_BASE_URL}/api/publication/${id}`, reqParams, {
            headers: {
              access_token: token
            }
          })
          .then(({ data }) => {
            dispatch(setLoaderVisibility(false))
            swal("Data Updated", "", "success").then((_) => {
              callback();
            });
          })
          .catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
      } else {
        axios
          .post(`${API_BASE_URL}/api/publication/upload`, reqParams, {
            headers: {
              access_token: token
            }
          })
          .then(({ data }) => {
            dispatch(setLoaderVisibility(false))
            swal("Data created", "", "success").then((_) => {
              callback();
            });
          })
          .catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
      }
    } catch (error) {
      dispatch(setLoaderVisibility(false))
      console.log(error);
    }
  }

  function handleChangeFile(e) {
    if(e.target.files)
    setFile(e.target.files[0]);
  }

  function handleChangeCover(e) {
    if(e.target.files)
    setImgCover(e.target.files[0]);
  }

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <Typography variant="h5">
                {label}
              </Typography>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="relative p-2 flex-auto">
                <div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
                  <div className="flex gap-2">
                    <Input label="Title" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    <Input label="Description" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={description} onChange={(e) => setDescription(e.target.value)} />
                  </div>
                  <div className="flex gap-2 block">
                    <Input label="Publish date" type="date" className="shadow appearance-none border rounded w-full py-2 px-1 text-black disabled" value={publishDate} onChange={(e) => setPublishDate(e.target.value)} />
                  </div>
                  <div className={isUpdateForm ? "flex gap-2 block" : "flex gap-2 hidden"}>
                    <Input label="File Url" className="shadow appearance-none border rounded w-full py-2 px-1 text-black disabled" value={fileUrl} onChange={(e) => setFileUrl(e.target.value)} />
                  </div>
                  <div className="flex gap-2">
                    <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language} label="Language">
                      <Option value={1033} aria-label="English">English</Option>
                      <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                    </Select>
                  </div>
                  <div className="flex gap-2">
                    <Select value={category} onChange={(e) => setCategory(e)} defaultValue={category} label="Category">
                      {
                        categories?.map(({ name, value }, index) => (
                          <Option value={value} aria-label={name}>{name}</Option>
                        ))
                      }
                    </Select>
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="sm">
                      Image Cover :
                    </Typography>
                    <Input size="lg" placeholder="Content" type="file"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      onChange={handleChangeCover} />
                  </div>
                  <div className="flex flex-col">
                    <Typography variant="sm">
                      File :
                    </Typography>
                    <Input size="lg" placeholder="Content" type="file"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      onChange={handleChangeFile} />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={callback}
                >
                  Close
                </button>
                <Button
                  className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div >
      </div >
    </>
  )
}