import React, { useEffect, useState } from "react";
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Textarea,
    Select,
    Option,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAdminMissions, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';
import { NavbarCMS } from "../NavbarCMS";
import ReactQuill from "react-quill";

export function FormTeamMember() {
    const { teamId } = useParams();
    const [lable, setLabel] = useState({});
    const [imgPreview, setImgPreview] = useState();
    const [language, setLanguage] = useState(1033);
    const [file, setFile] = useState();
    const [name, setName] = useState();
    const [fullname, setFullname] = useState();
    const [jobTitle, setJobTitle] = useState();
    const [address, setAdrress] = useState();
    const [twitLink, setTwitLink] = useState();
    const [fbLink, setFBLink] = useState();
    const [igLink, setIGLink] = useState();
    const [ytLink, setYTLink] = useState();
    const [linkedin, setLinkedin] = useState();
    const [jobTitleId, setJobTitleId] = useState();
    const [profileDescriptionEn, setProfileDescriptionEn] = useState();
    const [profileDescriptionId, setProfileDescriptionId] = useState();

    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (teamId) {
            dispatch(setLoaderVisibility(true))
            setLabel({
                title: "Update Team Member",
                subtitle: "Enter your data details for update the team member",
                buttonLabel: "Update Data"
            })
            axios.get(`${API_BASE_URL}/api/team/${teamId}`, { headers: { access_token: token } }).then(({ data }) => {
                dispatch(setLoaderVisibility(false))
                setName(data.data.name);
                setFullname(data.data.fullname);
                setAdrress(data.data.address);
                setJobTitle(data.data.jobTitle);
                setImgPreview(data.data.imgUrl);
                setLanguage(data.data.languageCode);
                setFBLink(data.data.facebook);
                setTwitLink(data.data.twitLink);
                setIGLink(data.data.instagram);
                setYTLink(data.data.youtube);
                setLinkedin(data.data.linkedin);
                setJobTitleId(data.data.jobTitleId);
                setProfileDescriptionEn(data.data.profileDescriptionEn);
                setProfileDescriptionId(data.data.profileDescriptionId);
            }).catch((e) => {
                console.log(e);
                dispatch(setLoaderVisibility(false))
            })
        } else {
            setLabel({
                title: "Add Team Member",
                subtitle: "Enter your data details for add the team member",
                buttonLabel: "Add Data"
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true))
        const reqParams = new FormData()
        reqParams.append("name", name);
        reqParams.append("fullname", fullname);
        reqParams.append("address", address);
        reqParams.append("jobTitle", jobTitle);
        reqParams.append("languageCode", language);
        reqParams.append("linkedin", linkedin);
        reqParams.append("twitter", twitLink);
        reqParams.append("facebook", fbLink);
        reqParams.append("instagram", igLink);
        reqParams.append("youtube", ytLink);
        reqParams.append("jobTitleId", jobTitleId);
        reqParams.append("profileDescriptionEn", profileDescriptionEn);
        reqParams.append("profileDescriptionId", profileDescriptionId);
        reqParams.append('file', file);

        try {
            if (teamId) {
                axios
                    .put(`${API_BASE_URL}/api/team/${teamId}`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        const res = {
                            lst: data.data.data,
                            link: `/cms/about/mission`
                        }
                        dispatch(setAdminMissions(res));
                        swal("Data updated", "", "success").then((_) => {
                            navigate("/cms/about/team");
                        });
                    })
                    .catch(({ response }) => {
                        swal("Failed", "", "error");
                    })
            } else {
                axios
                    .post(`${API_BASE_URL}/api/team`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        const res = {
                            lst: data.data,
                            link: `/cms/about/team`
                        }
                        dispatch(setAdminMissions(res));
                        swal("Data created", "", "success").then((_) => {
                            navigate("/cms/about/team");
                        });
                    })
                    .catch(({ response }) => {
                        swal("Failed", "", "error");
                    })
            }
        } catch (error) {
            console.log(error)
            dispatch(setLoaderVisibility(false))
        }
    };

    function handleChangeFile(e) {
        setImgPreview(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    return (
        <>
            <NavbarCMS module={lable?.title} />
            <div className="w-full">
                <Card color="transparent" shadow={false} className="h-full w-full flex p-8">
                    <div className="flex flex-col justify-center items-center">
                        <Typography variant="h4" color="blue-gray">
                            {lable?.title}
                        </Typography>
                        <Typography color="gray" className="mt-1 font-normal">
                            {lable.subtitle}
                        </Typography>
                    </div>
                    <form className="mt-8 mb-2" onSubmit={(e) => handleSubmit(e)}>
                        <div className="grid grid-cols-2 gap-8">
                            <div className="w-full">
                                <Typography variant="h5" color="blue-gray" className="mb-1">
                                    Personal Info
                                </Typography>
                                <hr className="w-1/2 mb-7" />
                                <div className="flex justify-center items-center mb-3 gap-4">
                                    <div className="flex flex-col w-1/3 justify-center items-center gap-2">
                                        <img src={imgPreview} className="w-36 rounded" />
                                        <Input size="sm" placeholder="Content" type="file"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            onChange={handleChangeFile} />
                                    </div>
                                    <div className="flex flex-col gap-2 mr-2 mb-3 w-2/3">
                                        <div className="flex flex-col gap-2 mb-3 mr-2">
                                            <Typography variant="p" color="blue-gray">
                                                Name
                                            </Typography>
                                            <Input
                                                size="lg"
                                                placeholder="name"
                                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                labelProps={{
                                                    className: "before:content-none after:content-none",
                                                }}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-2 mr-2 mb-3">
                                            <Typography variant="p" color="blue-gray" className="">
                                                Address
                                            </Typography>
                                            <Input
                                                size="lg"
                                                placeholder="Bogor"
                                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                                labelProps={{
                                                    className: "before:content-none after:content-none",
                                                }}
                                                value={address}
                                                onChange={(e) => setAdrress(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="flex flex-col gap-2 mr-2 mb-3">
                                        <Typography variant="p" color="blue-gray" className="">
                                            Job Title (English)
                                        </Typography>
                                        <Input
                                            size="lg"
                                            placeholder="Job Title"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            value={jobTitle}
                                            onChange={(e) => setJobTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 mr-2 mb-3">
                                        <Typography variant="p" color="blue-gray" className="">
                                            Jabatan (Bahasa Indonesia)
                                        </Typography>
                                        <Input
                                            size="lg"
                                            placeholder="Jabatan"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            value={jobTitleId}
                                            onChange={(e) => setJobTitleId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <Typography variant="h5" color="blue-gray" className="mb-1">
                                    Social Links
                                </Typography>
                                <hr className="w-1/2 mb-7" />
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="flex flex-col gap-2 mb-3 mr-2">
                                        <Typography variant="p" color="blue-gray">
                                            Facebook
                                        </Typography>
                                        <Input
                                            size="lg"
                                            placeholder="https://www.facebook.com/your_account"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            value={fbLink}
                                            onChange={(e) => setFBLink(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 mr-2 mb-3">
                                        <Typography variant="p" color="blue-gray" className="">
                                            Instagram
                                        </Typography>
                                        <Input
                                            size="lg"
                                            placeholder="https://www.instagram.com/your_account"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            value={igLink}
                                            onChange={(e) => setIGLink(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="flex flex-col gap-2 mr-2 mb-3">
                                        <Typography variant="p" color="blue-gray" className="">
                                            Twitter
                                        </Typography>
                                        <Input
                                            size="lg"
                                            placeholder="https://www.twitter.com/your_account"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            value={twitLink}
                                            onChange={(e) => setTwitLink(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 mr-2 mb-3">
                                        <Typography variant="p" color="blue-gray" className="">
                                            Linkedin
                                        </Typography>
                                        <Input
                                            size="lg"
                                            placeholder="https://www.linkedin.com/your_account"
                                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                            value={linkedin}
                                            onChange={(e) => setLinkedin(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* <div className="flex flex-col gap-2 mr-2">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Language
                                    </Typography>
                                    <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language}>
                                        <Option value={1033} aria-label="English">English</Option>
                                        <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                                    </Select>
                                </div> */}
                            </div>
                        </div>
                        <div>
                        <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Description (English)
                                    </Typography>
                                    <ReactQuill theme="snow" value={profileDescriptionEn} onChange={setProfileDescriptionEn} />
                                </div>
                                <div className="">
                                    <Typography variant="h6" color="blue-gray" className="mb-1">
                                        Deskripsi (Bahasa Indonesia)
                                    </Typography>
                                    <ReactQuill theme="snow" value={profileDescriptionId} onChange={setProfileDescriptionId} />
                                </div>
                        </div>
                        <Button className="mt-6" fullWidth type="submit">
                            {lable?.buttonLabel}
                        </Button>
                    </form>
                </Card>
            </div>
        </>
    );
}