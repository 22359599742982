import React, { useEffect, useState } from "react";
import { Select, Option } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageAdmin, setLanguageClient } from "../../app/actions/action-creators";
import { LABEL_ENG, LABEL_IDN } from "../../data/static-config";

const options = [
  {
    label: "Bahasa Indonesia",
    value: 1045,
    className: "fi fi-id"
  },
  {
    label: "English",
    value: 1033,
    className: "fi fi-us"
  },
];

export function SelectLanguage({ label }) {
  const [langSelected, setLangSelected] = useState();
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!langSelected) {
      setLangSelected(languageCode);
    }
  }, []);

  useEffect(() => {
    if (languageCode === 1033) {
      dispatch(setLanguageAdmin(LABEL_ENG));
    } else {
      dispatch(setLanguageAdmin(LABEL_IDN));
    }
  }, [languageCode]);

  const handleOnChange = (e) => {
    setLangSelected(e);
    dispatch(setLanguageClient(e));
    if (e === 1033) {
      dispatch(setLanguageAdmin(LABEL_ENG));
    } else {
      dispatch(setLanguageAdmin(LABEL_IDN));
    }
  }

  return (
    <div className="flex w-22 flex-col gap-4">
      <Select label={label} color="blue-gray" animate={{
        mount: { y: 0 },
        unmount: { y: 25 },
      }} className="text-white-100" value={langSelected} defaultValue={1033} onChange={handleOnChange}>
        {
          options.map(({label, value, className}) => (
            <Option value={value}><span className={className}></span> {label}</Option>
          ))
        }
      </Select>
    </div>
  );
}