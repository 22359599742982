import React, { useEffect, useState } from "react";
import { Button, Option, Select, Typography } from "@material-tailwind/react";
import { BarChart, ChoroplethMap, ExportToExcel, ModalFormRequestData } from "../../components";
import { Pie } from 'react-chartjs-2';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, listProvince } from "../../data/static-config";
import { Chart, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { setLoaderVisibility } from "../../app/actions/action-creators";

Chart.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TimberTraceability = () => {
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const [selectedProvince, setSelectedProvince] = useState(null);
	const [data, setData] = useState([]);
	const [category, setCategory] = useState(6000);
	const [categories, setCategories] = useState();
	const [dataPie, setDataPie] = useState();
	const [dataBar, setDataBar] = useState();
	const [showModal, setShowModal] = useState(false);
	const [year, setYear] = useState(2022);

	const years = [2022, 2023, 2024];

	const dispatch = useDispatch();

	const fileName = `Export Data Peredaran Kayu - ${categories?.filter((item) => {
		return item.metercubicno == category;
	})[0].metercubicno === 6000 ? "Lebih dari 6000m3" : "Kurang dari 6000m3"}`;

	useEffect(() => {
		axios.post(`${API_BASE_URL}/api/client/module/timbertraces`, { "selectedProvince": selectedProvince, "categoryCode": category, "languageCode": languageCode, "year": year }).then(({ data }) => {
			setDataBar(data?.data?.barData);
			setDataPie(data?.data?.pieData);
			// fetchData()
		}).catch(console.log)
	}, [selectedProvince, category, year]);

	useEffect(() => {
		axios.get(`${API_BASE_URL}/api/client/module/timbertracecategory`).then(({ data }) => {
			setCategories(data?.data);
		}).catch(console.log);
	}, [languageCode]);

	// const fetchData = () => {
	// 	axios.post(`${API_BASE_URL}/api/timbertrace/exportdata`, { "categoryCode": category }).then(({ data }) => {
	// 		const customHeadings = data?.data?.map((item, idx) => ({
	// 			"No": idx + 1,
	// 			"Provinsi": item.province,
	// 			"Nama Perusahaan": item.companyName,
	// 			"Nomor SK": item.SKNo,
	// 			"Nama Pemilik": item.ownerName,
	// 			"Realisasi Bahan Baku": item.realizationNo,
	// 			"Asal-usul Bahan Baku": item.originMaterial,
	// 			"Keterangan Asal Usul Bahan Baku": item.originDescription,
	// 			"Provinsi Asal Bahan Baku": item.originProvince
	// 		}))
	// 		setData(customHeadings)
	// 	})
	// };

	useEffect(() => {
		if (dataPie === null || dataBar === null || categories === null) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [dataBar, dataPie, categories]);

	const handleCallback = (e) => {
		setSelectedProvince(e);
	};

	const handleModal = () => {
		setShowModal(false);
	}

	return (
		<>
			{showModal ? <ModalFormRequestData callback={handleModal} /> : null}
			<div className='grid grid-cols-1 lg:grid-cols-2 w-full h-full'>
				<div className='h-auto flex justify-center item-center'>
					<div className='hidden h-screen md:pt-20 md:block md:w-full md:h-auto'>
						<ChoroplethMap cb={handleCallback} />
					</div>
				</div>
				<div className='pt-20 h-auto'>
					<div className='flex justify-center'>
						<Typography variant="h4" className="py-4">{languageCode == 1033 ? "INFOGRAPHIC" : "INFOGRAFIS"}</Typography>
					</div>
					<div className='md:flex md:justify-center hidden md:block'>
						{selectedProvince && <Typography variant="lead" className="">{selectedProvince}</Typography>}
					</div>
					<div className='flex justify-center md:hidden p-4 gap-4'>
						{
							listProvince ?
								<Select label="Select Province" value={selectedProvince} onChange={(e) => handleCallback(e)}>
									{
										listProvince?.map(({ provinsi }, index) => (
											<Option key={index} value={provinsi}>{provinsi}</Option>
										))
									}
								</Select> : null
						}
					</div>
					<div className='flex flex-col md:flex-row justify-around p-4 gap-4'>
						<div className="">
							{
								categories ?
									<Select label="Pilih Kategori" value={category} onChange={(e) => setCategory(e)}>
										{
											categories?.map(({ id, fullname, metercubicno }) => (
												<Option key={id} value={metercubicno}>{fullname}</Option>
											))
										}
									</Select> : null
							}
						</div>
						<div className="">
							<Select label="Pilih Tahun" value={year} onChange={(e) => setYear(e)}>
								{
									years?.map((item) => (
										<Option key={item} value={item}>{item.toString()}</Option>
									))
								}
							</Select>
						</div>
						{/* <div className="">
							{
								data && <ExportToExcel apiData={data} fileName={fileName} />
							}
						</div> */}
						<div className="">
							<Button className="" onClick={() => setShowModal(true)}>{languageCode == 1033 ? "Request Raw Data" : "Ajukan Permohonan Data"}</Button>
						</div>
					</div>
					<div className="w-full p-4 flex flex-col md:flex-row gap-2">
						<div className="w-full md:w-1/2">
							<div className='flex justify-center mb-5'>
								<Typography>Daerah Asal</Typography>
							</div>
							{
								dataPie ? <Pie data={dataPie} options={{
									plugins: {
										legend: {
											position: 'bottom'
										}
									}
								}} /> : null
							}
						</div>
						<div className="w-full md:w-1/2">
							<div className='flex justify-center mb-5'>
								<Typography>Realisasi Bahan Baku</Typography>
							</div>
							{
								dataBar ? <BarChart chartData={dataBar} options={{
									plugins: {
										legend: {
											position: 'right'
										}
									}
								}} /> : null
							}
						</div>
					</div>
					<div className='flex flex-col justify-center pt-8 px-8'>
						<Typography variant="small" className="text-ifmpallet1 font-italic">{languageCode == 1033 ? "Source : " : "Sumber : "}</Typography>
						<Typography variant="small" className="text-ifmpallet1">RPBBI (Rencana Pemenuhan Bahan Baku Industri) - Kementrian Lingkungan Hidup dan Kehutanan</Typography>
					</div>
				</div>
			</div>
		</>
	);
};

export default TimberTraceability;
