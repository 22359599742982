export const SET_CLIENT_LANGUANGE = 'SET_CLIENT_LANGUANGE'
export const SET_CLIENT_HOME = 'SET_CLIENT_HOME'
export const SET_CLIENT_NAVBAR = 'SET_CLIENT_NAVBAR'
export const SET_CLIENT_PROGRAM = 'SET_CLIENT_PROGRAM'
export const SET_DETAIL_PROGRAM = 'SET_DETAIL_PROGRAM'
export const SET_CLIENT_PARTNER = 'SET_CLIENT_PARTNER'
export const SET_CLIENT_ABOUT = 'SET_CLIENT_ABOUT'
export const SET_CLIENT_NEWS = 'SET_CLIENT_NEWS'
export const SET_DETAIL_NEWS = 'SET_DETAIL_NEWS'
export const SET_CLIENT_VIDEOS = 'SET_CLIENT_VIDEOS'
export const SET_CLIENT_REPORT = 'SET_CLIENT_REPORT'
export const SET_CLIENT_BOOKS = 'SET_CLIENT_BOOKS'
export const SET_CLIENT_FACTSHEET = 'SET_CLIENT_FACTSHEET'
export const SET_CLIENT_PRESSRELEASE = 'SET_CLIENT_PRESSRELEASE'
export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN'
export const SET_AUTHENTICATION_ADMIN = 'SET_AUTHENTICATION_ADMIN'
export const SET_ADMIN_GOALS = 'SET_ADMIN_GOALS'
export const SET_ADMIN_GOAL_DETAIL = 'SET_ADMIN_GOAL_DETAIL'
export const SET_ADMIN_MISIONS = 'SET_ADMIN_MISIONS'
export const SET_ADMIN_TEAMS = 'SET_ADMIN_TEAMS'
export const SET_ADMIN_GENERALS = 'SET_ADMIN_GENERALS'
export const SET_CLIENT_FOOTER = 'SET_CLIENT_FOOTER'
export const SET_ADMIN_PARTNERS = 'SET_ADMIN_PARTNERS';
export const SET_FINDING_STATUSES = 'SET_FINDING_STATUSES'
export const SET_ISNEED_REFRESH = 'SET_ISNEED_REFRESH'
export const SET_ADMIN_FINDING_PARTNERS = 'SET_ADMIN_FINDING_PARTNERS';
export const SET_ADMIN_NEWS_GENERAL = 'SET_ADMIN_NEWS_GENERAL';
export const SET_ADMIN_NEWS_LIST = 'SET_ADMIN_NEWS_LIST';
export const SET_ADMIN_CATEGORIES = 'SET_ADMIN_CATEGORIES';
export const SET_ADMIN_PUBLICATIONS = 'SET_ADMIN_PUBLICATIONS';
export const SET_ADMIN_PUBLICATION_DOC_GENERAL = 'SET_ADMIN_PUBLICATION_DOC_GENERAL';
export const SET_ADMIN_VIDEOS = 'SET_ADMIN_VIDEOS';
export const SET_ADMIN_PROGRAM_GENERAL = 'SET_ADMIN_PROGRAM_GENERAL';
export const SET_ADMIN_PROGRAM_LIST = 'SET_ADMIN_PROGRAM_LIST';
export const SET_ADMIN_HOME_GENERAL = 'SET_ADMIN_HOME_GENERAL';
export const SET_ADMIN_TIMBER_TRACING = 'SET_ADMIN_TIMBER_TRACING';
export const SET_SOCIAL_MEDIA_LINK = 'SET_SOCIAL_MEDIA_LINK';
export const SET_ADMIN_DONATE_GENERAL = 'SET_ADMIN_DONATE_GENERAL';
export const SET_CLIENT_DONATE = 'SET_CLIENT_DONATE';
export const SET_ADMIN_LANGUAGE = 'SET_ADMIN_LANGUAGE';
export const SET_ADMIN_CHARTS = 'SET_ADMIN_CHARTS';
export const SET_LOADER_VISIBILITY = 'SET_LOADER_VISIBILITY';
export const SET_CLIENT_PUBLICATION = 'SET_CLIENT_PUBLICATION';
export const SET_ADMIN_STORY_LIST = 'SET_ADMIN_STORY_LIST';
export const SET_CLIENT_STORY = 'SET_CLIENT_STORY';
export const SET_DETAIL_STORY = 'SET_DETAIL_STORY';
export const SET_ADMIN_PARTNER_LIST = 'SET_ADMIN_PARTNER_LIST';
export const SET_GENERAL_SETUP = 'SET_GENERAL_SETUP';
export const SET_ADMIN_PROPOSAL = 'SET_ADMIN_PROPOSAL';