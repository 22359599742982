import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Input,
    Button,
    Typography,
    Spinner
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from 'sweetalert';
import { API_BASE_URL } from "../../data/static-config.js";
import { useSelector, useDispatch } from "react-redux";
import { setLoaderVisibility } from "../../app/actions/action-creators.js";

const ResetPassword = () => {
    const [email, setEmail] = useState();
    const navigate = useNavigate();
    const logo = useSelector((state) => state?.combineReducer?.clientFooter?.logo);
    const dispatch = useDispatch();
    const [isLoaderVisible, setIsLoaderVisible] = useState(false);

    useEffect(() => {
        if (isLoaderVisible) {
            showSpinner()
        } else {
            hideSpinner();
        }
    }, [isLoaderVisible]);

    function showSpinner() {
        document.getElementById('spinner-overlay').classList.remove('hidden');
    }

    function hideSpinner() {
        document.getElementById('spinner-overlay').classList.add('hidden');
    }

    const handleOnSubmit = (e) => {
        setIsLoaderVisible(true);
        e.preventDefault();
        let request = {
            email: email
        };
        generateToken(request);
    };

    const generateToken = (params) => {
        axios
            .post(`${API_BASE_URL}/api/user/generate-token`, params)
            .then((res) => {
                setIsLoaderVisible(false);
                let data = {
                    msg: res.data.message,
                    success: res.data.success
                }
                swal("Sent", data.msg, "success");
                navigate("/login");
            })
            .catch((e) => {
                console.log(e)
                setIsLoaderVisible(false);
                let data = {
                    msg: e.response.data,
                    auth: e.response.data.success
                }
                swal("Oppss...", data.msg, "error");
            })
    }

    return (
        <div className="bg-gray-100 h-screen w-full flex justify-center items-center bg-green-900">
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" id="spinner-overlay" style={{ zIndex: 2500 }}>
                <Spinner size="large" color="blue" />
            </div>
            <Card className="w-96">
                <form onSubmit={(event) => handleOnSubmit(event)}>
                    <div className="mb-2 p-4">
                        {
                            logo != null ? <img
                                className="h-28 w-full p-4 rounded-lg object-contain object-center"
                                src={logo[0].logoUrl}
                                alt="nature image"
                            /> : null
                        }
                    </div>
                    <CardBody className="flex flex-col gap-4">
                        <Input label="Email" size="lg" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="gradient" fullWidth type="submit">
                            Reset Password
                        </Button>
                        <Link to="/login">
                            <div className="w-full px-4 pt-6 flex justify-center">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="ml-1 font-bold"
                                >
                                    Cancel
                                </Typography>
                            </div>
                        </Link>
                    </CardFooter>
                </form>
            </Card>
        </div>
    );
};

export default ResetPassword;
