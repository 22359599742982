import React from "react";
import { CardProgram } from "../client/CardProgram";

export function ProgramsList({progList}) {
    return (
        <div className="px-2 md:px-4">
            {
                progList?.map((item) => (
                    <CardProgram item={item} />
                ))
            }
        </div>

    );
}