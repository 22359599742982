import React, { useState } from "react";
import { FormFindingStatus, NavbarCMS, TableFindingStatus, TablePartnerGeneral } from "../../components";
import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const PartnerGeneral = () => {
    const [showModal, setShowModal] = useState(false);
    const [idFindingStatus, setIdFindingStatus] = useState(null);
    const setting = useSelector((state) => state?.combineReducer?.settings);

    const handleCallback = (params, id) => {
        setShowModal(params);
        setIdFindingStatus(id);
    }
    return (
        <div className="h-full">
            <NavbarCMS module={setting?.partner_general?.navbar} />
            <div className="grid grid-cols-1 gap-3">
                <div className="flex flex-col p-2">
                    <Typography variant="p" className="font-bold">
                        General Info
                    </Typography>
                    <hr className="my-4" />
                    <TablePartnerGeneral />
                </div>
                <div className="flex flex-col p-2">
                    <Typography variant="p" className="font-bold">
                        Finding Status
                    </Typography>
                    <hr className="my-4" />
                    {showModal ? (
                        <FormFindingStatus callback={(params) => handleCallback(params)} idFindingStatus={idFindingStatus} />
                    ) : null}
                    <TableFindingStatus callback={(params, id) => handleCallback(params, id)} />
                </div>
            </div>
        </div>
    )
}

export default PartnerGeneral;