import React, { useEffect, useState } from "react";
import { Button, Card, Typography } from "@material-tailwind/react";
import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminFindingStatuses, getAdminGeneral, setIsNeedRefresh, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';

export function TableFindingStatus({ callback }) {
  const TABLE_HEAD = ["Name", "Value", ""];
  const TABLE_ROWS = useSelector((state) => state?.combineReducer?.adminOrganizations?.findingStatuses);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const isNeedRefresh = useSelector((state) => state?.combineReducer?.adminOrganizations?.isNeedRefresh);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (TABLE_ROWS?.length === 0) {
      dispatch(setLoaderVisibility(true))
    } else {
      dispatch(setLoaderVisibility(false))
    }
  }, [TABLE_ROWS]);

  useEffect(() => {
    dispatch(getAdminFindingStatuses({ token }));
  }, [isNeedRefresh]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true))
          axios.delete(`${API_BASE_URL}/api/findingstatus/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false))
            dispatch(setIsNeedRefresh(!isNeedRefresh))
          }).catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
        }
      });
  };

  const handleUpdate = (id) => {
    callback(true, id);
  };

  return (
    <div className="w-full">
      <div className="flex justify-end w-full pb-4 pr-5">
        <Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-1/5 md:w-36" color="black" onClick={() => callback(true)}>
          <PlusCircleIcon className="w-4" />
          {
            languageCode == 1033 ? "Add Data" : "Tambah Data"
          }
        </Button>
      </div>
      <Card className="w-full rounded-none">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-2">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(({ id, name, value, languageCode }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {value}
                  </Typography>
                </td>
                {/* <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {languageCode === 1033 ? "English" : "Bahasa Indonesia"}
                  </Typography>
                </td> */}
                <td className="p-4 h-full flex items-center justify-center">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-1 m-1" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-3" color="white" />
                    {
                      languageCode == 1033 ? "Update" : "Ubah"
                    }
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-1 m-1" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-3" />
                    {
                      languageCode == 1033 ? "Delete" : "Hapus"
                    }
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
}