import React from "react";
import { NavbarCMS, TableNewsGeneral, TablePublicationGeneral } from "../../components";
import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const PublicationGeneral = () => {
    const setting = useSelector((state) => state?.combineReducer?.settings);
    const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
    return (
        <div className="h-full">
            <NavbarCMS module={setting?.publication_general?.navbar} />
            <div className="grid grid-cols-1 gap-3 mt-5">
                <div className="flex flex-col p-2">
                    <Typography variant="p" className="font-bold">
                       { languageCode == 1033 ? "News" : "Berita" } - Banner
                    </Typography>
                    <hr className="my-4" />
                    <TableNewsGeneral />
                </div>
                <div className="flex flex-col p-2">
                    <Typography variant="p" className="font-bold">
                    { languageCode == 1033 ? "Publication Document" : "Dokumen Publikasi" } - Banner
                    </Typography>
                    <hr className="my-4" />
                    <TablePublicationGeneral />
                </div>
            </div>
        </div>
    )
}

export default PublicationGeneral;