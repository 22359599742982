import { Button, CardFooter, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsList, setLoaderVisibility } from "../../app/actions/action-creators.js";
import { NavbarCMS, TableNewsListAdmin } from "../../components";

const NewsCMS = () => {
    const dispatch = useDispatch();
	const [pageNum, setPageNum] = useState(1);
	const [searchKey, setSearchKey] = useState("");
	const [isNeedRefresh, setIsNeedRefresh] = useState(false);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
	const setting = useSelector((state) => state?.combineReducer?.settings);
	const pages = useSelector((state) => state?.combineReducer?.adminOrganizations?.newsList?.pages);
	const newsState = useSelector((state) => state?.combineReducer?.adminOrganizations?.newsList);
	const pageTotal = pages?.pageTotal;
	const data = newsState?.data;

	useEffect(() => {
		if (newsState?.length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [newsState]);

	const getSearchKey = (string) => {
		setSearchKey(string);
	};

	useEffect(() => {
		let req = {
			languageCode: languageCode,
			page: pageNum,
			searchKey: searchKey,
			limit: 10,
			token: token
		};
		dispatch(getNewsList(req));
	}, [languageCode, pageNum, searchKey, isNeedRefresh]);

	const handlePageButton = (operator) => {
		setPageNum(pageNum + operator);
	};

	const handleRefresh = () => {
		setIsNeedRefresh(!isNeedRefresh);
	}
    return (
        <div className="h-full">
            <NavbarCMS module={setting?.publication_news?.navbar} />
            <div className="grid grid-cols-1 gap-3">
                <div className="flex flex-col p-2">
                    <Typography variant="p" className="font-bold">
                        News List
                    </Typography>
                    <hr className="my-4" />
                    <TableNewsListAdmin LABEL={languageCode == 1033 ? "Add News List" : "Tambah Berita"} keyDown={getSearchKey} REFRESH_TABLE={() => handleRefresh()} />
					<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
					<Typography variant="small" color="blue-gray" className="font-normal">
						{pages?.title}
					</Typography>
					<div className="flex gap-2">
						<Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
							{pages?.prev}
						</Button>
						<Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
							{pages?.next}
						</Button>
					</div>
				</CardFooter>
                </div>
            </div>
        </div>
    )
}

export default NewsCMS;