import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import parse from "html-react-parser";
import { useSelector } from "react-redux";

export function CardStory({ item }) {
    const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
    return (
        <Card className="w-full h-auto bg-gradient-to-l bg-ifmpallet2 w-full flex-row mt-2 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none">
            <CardHeader
                shadow={false}
                floated={false}
                className="m-0 w-2/5 flex justify-center shrink-0 rounded-r-none"
            >
                {
                    item?.imgBanner ? <img
                        src={item?.imgBanner}
                        alt="card-image"
                        className="w-full h-full object-fit flex items-center"
                    /> : <img
                        className="object-cover w-full p-4"
                        src={logo}
                        alt="card-image"
                    />
                }
            </CardHeader>
            <CardBody>
                <Typography variant="h5" color="yellow" className="mb-2 line-clamp-2 text-ifmpallet3">
                    {item?.title}
                </Typography>
                <Typography color="white" variant="small" className="mb-2 text-xs line-clamp-1 text-justify">
                    {item?.author}
                </Typography>
                <Typography color="white" variant="small" className="mb-2 font-normal line-clamp-3 text-justify">
                    {item?.description ? parse(item?.description) : ""}
                </Typography>
                {
                    <Link to={`/story/${item?.id}/${item?.title?.replace(/ /g,"-")}`} className="inline-block w-full flex justify-end">
                        <Button variant="text" className="flex items-center gap-1 text-ifmpallet3" color="orange">
                            {languageCode == 1033 ? "Read More" : "Selengkapnya"}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                                className="h-4 w-4"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                            </svg>
                        </Button>
                    </Link>
                }
            </CardBody>
        </Card>
    )
}