import React from "react";
import { NavbarCMS, TableHomeGeneral } from "../../components";
import { useSelector } from "react-redux";

const HomeSetupPage = () => {
	const setting = useSelector((state) => state?.combineReducer?.settings);
	return (
		<div className="h-full w-full">
			<NavbarCMS module={setting?.dashboard_home_setup?.navbar} />
			<TableHomeGeneral />
		</div>
	);
};

export default HomeSetupPage;
