import {
  Navbar,
  Typography,
  Button,
  Input,
} from "@material-tailwind/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { SelectLanguage } from "../client/SelectLanguange";
import { useEffect } from "react";

export function NavbarCMS({ module, onKeyDown }) {
  const [searchKey, setSearchKey] = useState("");
  const userEmail = useSelector((state) => state?.combineReducer?.adminToken?.email);
  const [label, setLabel] = useState();
  const clientLang = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const labelID = useSelector((state) => state?.combineReducer?.clientNavbar?.id);
  const labelEN = useSelector((state) => state?.combineReducer?.clientNavbar?.en);
  
  useEffect(() => {
    clientLang == 1045 ? setLabel(labelID) : setLabel(labelEN);
  }, [clientLang]);

  return (
    <Navbar
      variant="solid"
      color="gray"
      className="fixed top-0 mx-auto max-w-screen-3xl border-y border-gray-400 w-full sticky bg-gray-50 px-3" style={{ borderRadius: 0, zIndex: 10 }}
    >
      <div className="flex flex-wrap items-center justify-between gap-y-4 text-gray-800">
        <Typography
          as="a"
          href="#"
          variant="h5"
          className="mr-4 ml-2 cursor-pointer py-1.5"
        >
          {module}
        </Typography>
        <div className="relative flex w-full md:w-max">
          <UserCircleIcon type="sm" className="w-6" />
          <Typography
            as="p"
            variant="sm"
            className="mr-4 ml-2 cursor-pointer py-1.5 mr-4"
          >
            {userEmail}
          </Typography>
          <SelectLanguage label={label?.lang} />
        </div>
      </div>
    </Navbar>
  );
}