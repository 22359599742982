import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { CardNews } from "../client/CardNews";

export function NewsList({latestNews}) {
    return (
        <>
            <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4 mb-0">
                {
                    latestNews?.map((item) => (
                        <CardNews item={item} />
                    ))
                }
            </div>
        </>
    )
}