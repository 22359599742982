import {
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { UserPlusIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  Alert,
  Button,
  CardBody,
  CardHeader,
  Chip,
  Input,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { API_BASE_URL } from "../../data/static-config.js";
import { setLoaderVisibility } from "../../app/actions/action-creators.js";

export function SortableTable({ TABS, TABLE_HEAD, TABLE_ROWS, LABEL, keyDown, FINDING_STATUS, REFRESH_TABLE }) {
  const [showModal, setShowModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [label, setLabel] = useState({ title: "Add Finding", alert: "Data Sent" });
  const [findingInput, setFindingInput] = useState();
  const [orgInput, setOrgInput] = useState();
  const [emailInput, setEmailInput] = useState();
  const [locationInput, setLocationInput] = useState();
  const [file, setFile] = useState();
  const [showToolTip, setShowToolTip] = useState(true);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    languageCode === 1033 ? setLabel({ title: "Add Finding", alert: "Data Sent" }) : setLabel({ title: "Tambah Temuan", alert: "Data Terkirim" });
  }, [languageCode]);

  useEffect(() => {
    setTimeout(() => {
      setShowToolTip(false);
    }, 4000)
  }, [])

  const search = (event) => {
    setSearchKey(event.target.value);
  };

  const handleKeyDown = () => {
    keyDown(searchKey);
  };

  const handleUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoaderVisibility(true));

    const reqParams = new FormData()
    reqParams.append("finding", findingInput);
    reqParams.append("organization", orgInput);
    reqParams.append("located", locationInput);
    reqParams.append("languageCode", languageCode);
    reqParams.append("email", emailInput);
    reqParams.append("file", file);

    try {
      axios
        .post(`${API_BASE_URL}/api/client/module/partner/create`, reqParams)
        .then(({ data }) => {
          dispatch(setLoaderVisibility(false));
          swal(label.alert, "", "success").then((_) => {
            setShowModal(false);
            setFindingInput("");
            setOrgInput("");
            setEmailInput("");
            setLocationInput("");
          });
        })
        .catch(console.log)
    } catch (error) {
      dispatch(setLoaderVisibility(false));
      swal(error, "", "error").then((_) => {
        setShowModal(false);
        setFindingInput("");
        setOrgInput("");
        setEmailInput("");
        setLocationInput("");
      });
      console.log(error);
    }
  }

  const generateStatucCode = (params) => {
    let comp = null;
    FINDING_STATUS?.map(({ name, value }) => {
      if (params === value) comp = <Chip
        variant="ghost"
        size="sm"
        value={name}
        color={"orange"}
      />
    })
    return comp;
  };

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="lg:mb-8 flex items-center justify-between lg:gap-8">
          <div className="flex flex-col lg:gap-2 sm:flex-row">
            {showModal ? (
              <>
                <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none" style={{ zIndex: 1002 }}>
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                        <Typography variant="h5">
                          {label.title}
                        </Typography>
                      </div>
                      <form onSubmit={(e) => { handleSubmit(e) }}>
                        <div className="relative p-2 flex-auto">
                          <div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
                            <Input label="Finding" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={findingInput} onChange={(e) => setFindingInput(e.target.value)} required />
                            <div className="md:flex-row grid grid-col-1 gap-3">
                              <Input label="Organization/Individual" className="shadow appearance-none border rounded w-full py-2 px-1 text-black " value={orgInput} onChange={(e) => setOrgInput(e.target.value)} required />
                              <Input label="Email Organization/Individual" type="email" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} required />
                              <Input label="location" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={locationInput} onChange={(e) => setLocationInput(e.target.value)} required />
                            </div>
                            <Input label="Upload" type="file" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" onChange={handleUpload} />
                          </div>
                        </div>
                        <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                          <button
                            className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                            type="button"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </button>
                          <Button
                            className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <div className="w-1/2 flex flex-col gap-2">
            {/* <Tooltip content={LABEL?.message} placement="top-start" className="w-1/2 max-w-24" open={true}> */}
            {/* <Alert variant="outlined">
              <span>{LABEL?.message}</span>
            </Alert> */}
            <Button className="flex justify-center items-center gap-3 w-full md:w-1/2" size="sm" onClick={() => setShowModal(true)}>
              <PlusCircleIcon strokeWidth={2} className="h-4 w-4" /> {LABEL?.form?.labelButton}
            </Button>
            {/* </Tooltip> */}
          </div>
          <div className="w-full h-full md:w-1/3">
            <Input
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />} value={searchKey}
              onChange={(e) => search(e)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(
              ({ id, imgUrl, finding, organization, statusCode, located }, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={id}>
                    <td className={classes} style={{ maxWidth: "450px" }}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {finding}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes} style={{ maxWidth: "250px" }}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {organization}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        {generateStatucCode(statusCode)}
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {located}
                      </Typography>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </CardBody>
    </>
  );
}