import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { getClientFooterData } from "../../app/actions/action-creators";
import { FaYoutube, FaTwitter, FaLinkedin, FaInstagram, FaFacebook, FaTiktok } from "react-icons/fa";
import { useIsVisible } from "../general/useIsVisible";

const currentYear = new Date().getFullYear();

export function FooterWithSocialLinks() {
  const [LINKS, setLINKS] = useState([
    {
      title: "Resource",
      items: ["Home", "Program", "Partner", "Publication", "News", "Story"],
    },
    {
      title: "Organization",
      items: ["About", "Donate", "Login as Admin", "Go to Old Version Website"],
    },
  ]);
  const [label, setLabel] = useState();
  const clientLang = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const labelID = useSelector((state) => state?.combineReducer?.clientNavbar?.id);
  const labelEN = useSelector((state) => state?.combineReducer?.clientNavbar?.en);
  const data = useSelector((state) => state?.combineReducer?.clientFooter);
  const dispatch = useDispatch();
  const ref0 = useRef();
  const isVisible0 = useIsVisible(ref0);

  useEffect(() => {
    const req = {
      languageCode: clientLang
    }
    dispatch(getClientFooterData(req))
  }, [clientLang]);

  useEffect(() => {
    clientLang === 1045 ? setLabel(labelID) : setLabel(labelEN);
  }, [clientLang]);

  useEffect(() => {
    setLINKS([
      {
        title: label?.resource,
        items: [label?.home, label?.program, label?.partner, label?.publication, label?.news, label?.story],
      },
      {
        title: label?.org,
        items: [label?.about, label?.donate, label?.loginAdmin, label?.oldwebsite],
      },
    ])
  }, [label])

  const generateLink = (param) => {
    var result = "";
    if (param === "Login as Admin" || param === "Masuk sebagai Admin") {
      result = "/login"
    } else if (param === "Home" || param === "Beranda") {
      result = "/"
    } else if (param === "Programs" || param === "Program") {
      result = "/program";
    } else if (param === "Partners" || param === "Mitra") {
      result = "/partner";
    } else if (param === "Publication" || param === "Publikasi") {
      result = "/publication";
    } else if (param === "News" || param === "Berita") {
      result = "/news";
    } else if (param === "About" || param === "Tentang") {
      result = "/about";
    } else if (param === "Donate" || param === "Donasi") {
      result = "/donate";
    } else if(param === "Old Version Website" || param === "Website versi lama"){
      result = "https://www.old.forestfund.or.id/";
    } else if(param === "Cerita Pemantau" || param === "Story of The Monitor"){
      result = "/story";
    }

    return result;
  }
  return (
    <div ref={ref0} className={`transition-opacity ease-in duration-700 ${isVisible0 ? "opacity-100" : "opacity-0"}`}>
      <footer className="relative w-full bg-ifmpallet2">
        <div className="mx-auto w-full max-w-7xl px-8 pt-8">
          <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-3">
            <div>
              <Typography variant="h5" className="mb-6" color="white">
                {label?.contactInfo}
              </Typography>
              <div className="flex justify-start w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-16 h-16" color="white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
                <Typography variant="p" className="mb-6 ml-3" color="white">
                  {data?.address}
                </Typography>
              </div>
              <div className="flex justify-start w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6" color="white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
                <Typography variant="p" className="mb-6 ml-3" color="white">
                  {data?.phone}
                </Typography>
              </div>
              <div className="flex justify-start w-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6" color="white">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>
                <Typography variant="p" className="mb-6 ml-3" color="white">
                  {data?.email}
                </Typography>
              </div>
            </div>
            <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
            </div>
            <div className="md:grid md:grid-cols-2 md:justify-between md:gap-1 hidden md:block">
              {LINKS.map(({ title, items }, index) => (
                <ul key={title + index.toString()}>
                  <Typography
                    variant="small"
                    color="white"
                    className="md:mb-3 md:font-medium md:opacity-90"
                  >
                    {title}
                  </Typography>
                  {items.map((link, idx) => (
                    <li key={link + idx.toString()}>
                      <Link to={generateLink(link)}>
                        <Typography
                          as="a"
                          color="white"
                          className="md:py-1.5 md:font-normal md:transition-colors hover:text-blue-gray-100"
                        >
                          {link}
                        </Typography>
                      </Link>
                    </li>
                  ))}
                </ul>
              ))}
            </div>
          </div>
          <div className="mt-3 flex w-full items-center justify-between border-t border-blue-gray-50 py-4">
            <Typography
              variant="small"
              className="mb-4 text-center font-normal text-blue-gray-100 md:mb-0"
            >
              &copy; {currentYear} Independent Forest Monitoring Fund
            </Typography>
            <div className="w-full md:w-1/4 flex justify-end gap-4">
              {
                data?.socialmedia?.map(({ id, link, name, categoryCode }) => {
                  if (categoryCode == 1) {
                    return <Link to={link}>
                      <FaYoutube color="white" size={20} className="hover:cursor-pointer" />
                    </Link>
                  } else if (categoryCode == 2) {
                    return <Link to={link}>
                      <FaTwitter color="white" size={20} className="hover:cursor-pointer" />
                    </Link>
                  } else if (categoryCode == 3) {
                    return <Link to={link}>
                      <FaLinkedin color="white" size={20} className="hover:cursor-pointer" />
                    </Link>
                  } else if (categoryCode == 4) {
                    return <Link to={link}>
                      <FaInstagram color="white" size={20} className="hover:cursor-pointer" />
                    </Link>
                  } else if (categoryCode == 5) {
                    return <Link to={link}>
                      <FaFacebook color="white" size={20} className="hover:cursor-pointer" />
                    </Link>
                  } else if (categoryCode == 6) {
                    return <Link to={link}>
                      <FaTiktok color="white" size={20} className="hover:cursor-pointer" />
                    </Link>
                  }
                })
              }
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}