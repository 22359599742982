import React, { useEffect, useRef, useState } from "react";
import { BannerPage, OurMission, TabsAboutUs } from "../../components/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getClientAboutData, setLoaderVisibility } from "../../app/actions/action-creators.js";

const About = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state?.combineReducer?.clientAbout);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

	useEffect(() => {
		if (Object.keys(data).length === 0) {
		  dispatch(setLoaderVisibility(true))
		} else {
		  dispatch(setLoaderVisibility(false))
		}
	  }, [data]);

	useEffect(() => {
		const req = {
			languageCode: languageCode
		}
		dispatch(getClientAboutData(req))
	}, [languageCode]);

	return (
		<div className="bg-gray-100">
			<BannerPage dataComp={data?.data} />
			<div className="w-full px-5 md:px-20 py-10">
				<div className="md:flex md:justify-center md:aligns-center md:h-full mb-12">
					<div className="w-full md:w-1/4">
						<img src={data?.data?.mission?.imgUrl} className="hidden md:block md:h-44"/>
					</div>
					<div className="w-full md:w-3/4 item-center">
						<div className="h-full flex justify-center items-center">
						<OurMission data={data?.data?.mission} />
						</div>
					</div>
				</div>
				<TabsAboutUs dataDetail={data?.data?.dataDetail} />
			</div>
		</div>
	);
};

export default About;
