import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Typography } from '@material-tailwind/react';
import { useSelector } from 'react-redux';

export const MapPartnerComponent = ({markers}) => {
    const position = [-2.3401736284598997, 116.49205047215227];

    const customIcon = new Icon({
        iconUrl: "https://cdn-icons-png.flaticon.com/128/684/684908.png",
        iconSize: [38, 38]
    })

    return (
        <MapContainer center={position} zoom={4.5} maxZoom={15} style={{ height: '400px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup>
                {
                    markers?.map((marker) => (
                        <Marker position={marker.geocode} icon={customIcon} key={marker.id}>
                            <Popup>
                                <Typography variant='h5'>
                                    {marker.name}
                                </Typography>
                                {marker.province}
                            </Popup>
                        </Marker>
                    ))
                }
            </MarkerClusterGroup>
        </MapContainer>
    );
};