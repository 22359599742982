import React, { useEffect, useState } from "react";
import { BannerPage, MapPartnerComponent, SortableTable } from "../../components/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getClientPartnerData, setLoaderVisibility } from "../../app/actions/action-creators.js";
import {
	Card,
	Typography,
	Button,
	CardFooter,
} from "@material-tailwind/react";

const Partner = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state?.combineReducer?.clientPartner);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const [pageNum, setPageNum] = useState(1);
	const pageTotal = useSelector((state) => state?.combineReducer?.clientPartner?.data?.label?.pages?.pageTotal);
	const [searchKey, setSearchKey] = useState("");
	const markers = useSelector((state) => state?.combineReducer?.clientPartner?.partnerListName);

	const getSearchKey = (string) => {
		setSearchKey(string);
	};

	useEffect(() => {
		if (Object.keys(data).length === 0 || !data) {
		  dispatch(setLoaderVisibility(true))
		} else {
		  dispatch(setLoaderVisibility(false))
		}
	  }, [data]);

	useEffect(() => {
		let req = {
			languageCode: languageCode,
			page: pageNum,
			searchKey: searchKey,
			limit: 10
		};
		dispatch(getClientPartnerData(req));
	}, [languageCode, pageNum, searchKey]);

	const handlePageButton = (operator) => {
		setPageNum(pageNum + operator);
	};

	return (
		<div className="bg-gray-100">
			<BannerPage dataComp={data?.label} />
			<div className="w-full md:p-10">
				<MapPartnerComponent markers={markers} />
			</div>
			<div className="w-full md:px-10 pb-10">
				<Card className="h-full w-full">
					<SortableTable TABS={data?.partnerListTabs} TABLE_HEAD={data?.partnerListHeader} TABLE_ROWS={data?.partnerList} LABEL={data?.label} keyDown={getSearchKey} FINDING_STATUS={data?.partnerListTabs} />
					<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
						<Typography variant="small" color="blue-gray" className="font-normal">
							{data?.pages?.title}
						</Typography>
						<div className="flex gap-2">
							<Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={()=> handlePageButton(-1)}>
								{data?.pages?.prev}
							</Button>
							<Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={()=> handlePageButton(1)}>
								{data?.pages?.next}
							</Button>
						</div>
					</CardFooter>
				</Card>
			</div>
		</div>
	);
};

export default Partner;
