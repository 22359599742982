import React from "react";
import { NavbarCMS, TableProgramGeneral } from "../../components";
import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const ProgramGeneral = () => {
    const setting = useSelector((state) => state?.combineReducer?.settings);
    return (
        <div className="h-full">
            <NavbarCMS module={setting?.program_general?.navbar} />
            <div className="grid grid-cols-1 gap-3">
                <div className="flex flex-col p-2">
                    <TableProgramGeneral />
                </div>
            </div>
        </div>
    )
}

export default ProgramGeneral;