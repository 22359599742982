import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavbarCMS } from "../../components";
import { Button, Card, Input, Typography } from "@material-tailwind/react";
import { getGeneralSetup, setLoaderVisibility } from "../../app/actions/action-creators";
import { API_BASE_URL } from "../../data/static-config";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Setting = () => {
    const setting = useSelector((state) => state?.combineReducer?.settings);
    const data = useSelector((state) => state?.combineReducer?.adminOrganizations?.genset);
    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
    const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
    const [emailReceiver, setEmailReceiver] = useState();
    const [emailHost, setEmailHost] = useState();
    const [emailUser, setEmailUser] = useState();
    const [emailPassword, setEmailPassword] = useState();
    const [emailService, setEmailService] = useState();
    const [baseUrl, setBaseUrl] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [idExist, setIdExist] = useState();

    useEffect(() => {
        dispatch(getGeneralSetup({token: token}));
    }, []);

    useEffect(() => {
        if(data.length > 0){
            setIdExist(data[0].id)
            setEmailReceiver(data[0].emailReceiver)
            setEmailHost(data[0].emailHost)
            setEmailUser(data[0].emailUser)
            setEmailPassword(data[0].emailPassword)
            setEmailService(data[0].emailService)
            setBaseUrl(data[0].baseUrl)
        }
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true))
        const reqParams = {
            "emailReceiver" : emailReceiver,
            "emailHost": emailHost,
            "emailUser": emailUser,
            "emailPassword" : emailPassword,
            "emailService": emailService,
            "baseUrl": baseUrl
          };

        try {
            if (idExist) {
                axios
                    .put(`${API_BASE_URL}/api/generalsetup/${idExist}`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        dispatch(setLoaderVisibility(false))
                        swal("Data updated", "", "success").then((_) => {
                            dispatch(getGeneralSetup({token: token}));
                        });
                    })
                    .catch((e) => {
                        dispatch(setLoaderVisibility(false))
                        swal("Failed", e.toString(), "error")
                    });
            } else {
                axios
                    .post(`${API_BASE_URL}/api/generalsetup`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        dispatch(setLoaderVisibility(false))
                        swal("Data created", "", "success").then((_) => {
                            dispatch(getGeneralSetup({token: token}));
                        });
                    })
                    .catch((e) => {
                        dispatch(setLoaderVisibility(false))
                        swal("Failed", e.toString(), "error")
                    });
            }
        } catch (error) {
            console.log(error)
            dispatch(setLoaderVisibility(false))
        }
    };

    return (
        <div className="h-full w-full">
            <NavbarCMS module={setting?.genset?.navbar} />
            <Card color="transparent" shadow={false} className="w-full flex justify-center items-center p-4">
                <Typography variant="h4" color="blue-gray">
                    General Setup
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">

                </Typography>
                <form className="mt-8 mb-2 flex flex-col gap-4" onSubmit={(e) => handleSubmit(e)}>
                    <div className="mb-1 flex flex-col gap-6">
                        <div className="flex gap-2">
                            <Input
                                label="Base URL"
                                size="lg"
                                className="pr-20"
                                containerProps={{
                                    className: "min-w-0",
                                }}
                                value={baseUrl}
                                onChange={(e) => setBaseUrl(e.target.value)}
                            />
                            <Input
                                size="lg"
                                label="Email Receiver"
                                className="pr-20"
                                containerProps={{
                                    className: "min-w-0",
                                }}
                                value={emailReceiver}
                                onChange={(e) => setEmailReceiver(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-1 flex flex-col gap-6">
                        <div className="flex gap-2">
                            <Input
                                label="Email Service"
                                size="lg"
                                className="pr-20"
                                containerProps={{
                                    className: "min-w-0",
                                }}
                                value={emailService}
                                onChange={(e) => setEmailService(e.target.value)}
                                disabled
                            />
                            <Input
                                size="lg"
                                label="Email Host"
                                className="pr-20"
                                containerProps={{
                                    className: "min-w-0",
                                }}
                                value={emailHost}
                                onChange={(e) => setEmailHost(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="mb-1 flex flex-col gap-6">
                        <div className="flex gap-2">
                            <Input
                                label="Email User"
                                size="lg"
                                className="pr-20"
                                containerProps={{
                                    className: "min-w-0",
                                }}
                                value={emailUser}
                                onChange={(e) => setEmailUser(e.target.value)}
                                disabled
                            />
                            <Input
                                size="lg"
                                label="Email password"
                                className="pr-20"
                                containerProps={{
                                    className: "min-w-0",
                                }}
                                value={emailPassword}
                                onChange={(e) => setEmailPassword(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                    <Button className="mt-6" fullWidth type="submit">
                        {
                            languageCode == 1033 ? "Save" : "Simpan"
                        }
                    </Button>
                </form>
            </Card>
        </div>
    )
}

export default Setting;