import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { PencilIcon, TrashIcon, DocumentPlusIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminProgramList, setLoaderVisibility } from "../../app/actions/action-creators";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from '../../data/static-config';
import swal from 'sweetalert';

export function ProgramsTable() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.combineReducer?.adminOrganizations?.programlist);
  const TABLE_HEAD = data?.data?.programListHeader;
  const TABLE_ROWS = data?.data?.programList;
  const pages = data?.data?.pages;
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const navigate = useNavigate();
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const [pageNum, setPageNum] = useState(1);
  const pageTotal = pages?.pageTotal;
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
		if (data?.length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [data]);

  useEffect(() => {
    let params = {
      languageCode: languageCode,
      searchKey: searchKey,
      page: pageNum,
      token: token,
      limit: 10
    }
    dispatch(getAdminProgramList(params))
  }, [isNeedRefresh, languageCode, searchKey, pageNum]);

  const handleAddProgram = () => {
    navigate("/cms/program/add")
  };

  const handleEditProgram = (id) => {
    navigate(`/cms/program/update/${id}`)
  };

  const handlePageButton = (operator) => {
    setPageNum(pageNum + operator);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        dispatch(setLoaderVisibility(true))
        if (willDelete) {
          axios.delete(`${API_BASE_URL}/api/program/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            setIsNeedRefresh(!isNeedRefresh)
            dispatch(setLoaderVisibility(false))
          })
        } else {
          dispatch(setLoaderVisibility(false))
        }
      });
  };

  return (
    <Card className="w-full rounded-none pt-3">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <div className="w-full md:w-72">
            <Button className="flex items-center gap-3" size="sm" onClick={handleAddProgram}>
              <DocumentPlusIcon strokeWidth={2} className="h-4 w-4" /> Add Program
            </Button>
          </div>
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row p-2">
            <Input
              onChange={(e) => setSearchKey(e.target.value)}
              label="Search"
              icon={<MagnifyingGlassIcon className="h-5 w-5" />}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(
              ({ id, title, stateCode, isgrants, imgUrl }, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={title}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <Avatar src={imgUrl} alt={title} size="sm" />
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {title}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          variant="ghost"
                          size="sm"
                          value={stateCode == 0 ? "Inactive" : "Active"}
                          color={stateCode == 0 ? "blue-gray" : "green"}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          variant="ghost"
                          size="md"
                          value={isgrants ? "Available" : "Not Available"}
                          color={isgrants ? "green" : "blue-gray"}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleEditProgram(id)}>
                        <PencilIcon className="w-4" color="white" />
                        Update
                      </Button>
                    </td>
                    <td className={classes}>
                      <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                        <TrashIcon className="w-4" />
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50">
        <Typography variant="small" color="blue-gray" className="font-normal">
          {pages?.title}
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
            {pages?.prev}
          </Button>
          <Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
            {pages?.next}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}