import React, { useEffect, useState } from "react";
import { NavbarCMS, SortableTableAdmin, TableTimberTracings } from "../../components/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getAdminTimberTracings, setLoaderVisibility } from "../../app/actions/action-creators.js";
import {
	Card,
	Typography,
	Button,
	CardFooter,
} from "@material-tailwind/react";

const TimberTracingPage = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state?.combineReducer?.adminOrganizations?.timberTracings);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const [pageNum, setPageNum] = useState(1);
	const pageTotal = useSelector((state) => state?.combineReducer?.adminOrganizations?.data?.label?.pages?.pageTotal);
	const [searchKey, setSearchKey] = useState("");
	const [isNeedRefresh, setIsNeedRefresh] = useState(false);
	const [tableRows, setTableRows] = useState();
	const [categoryCode, setCategoryCode] = useState(6000);
	const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
	const setting = useSelector((state) => state?.combineReducer?.settings);

	const getSearchKey = (string) => {
		setSearchKey(string);
		setPageNum(1);
	};

	useEffect(() => {
		if (data?.length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [data]);

	useEffect(() => {
		setTableRows(data?.data?.items);
	}, [data])

	useEffect(() => {
		let req = {
			languageCode: languageCode,
			page: pageNum,
			searchKey: searchKey,
			limit: 20,
			token: token,
			metercubicno: categoryCode
		};
		dispatch(getAdminTimberTracings(req));
	}, [languageCode, pageNum, searchKey, isNeedRefresh, categoryCode]);

	const handlePageButton = (operator) => {
		setPageNum(pageNum + operator);
	};

	const handleRefresh = () => {
		setIsNeedRefresh(!isNeedRefresh);
	}

	return (
		<div className="h-full w-full">
			<NavbarCMS module={setting?.timber_tracings?.navbar} />
			<div className="w-full">
				<TableTimberTracings TABLE_ROWS={tableRows} keyDown={getSearchKey} REFRESH_TABLE={() => handleRefresh()} CallBack={(e) => setCategoryCode(e)}/>
				<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
					<Typography variant="small" color="blue-gray" className="font-normal">
						{data?.data?.pages?.title}
					</Typography>
					<div className="flex gap-2">
						<Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
							{data?.data?.pages?.prev}
						</Button>
						<Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
							{data?.data?.pages?.next}
						</Button>
					</div>
				</CardFooter>
			</div>
		</div>
	)
}

export default TimberTracingPage;