import React, { useEffect, useState } from "react";
import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
  Select,
  Option,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAdminFindingStatuses, setAdminGoals, setAdminPartnerList, setIsNeedRefresh, setLoaderVisibility } from "../../../app/actions/action-creators.js";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';

export function FormPartnerList({ callback, idPartnerList }) {
  const partListId = idPartnerList;
  const [label, setLabel] = useState({});
  const [name, setName] = useState();
  const [province, setProvince] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [value, setValue] = useState();
  const [language, setLanguage] = useState();

  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const isNeedRefresh = useSelector((state) => state?.combineReducer?.adminOrganizations?.isNeedRefresh);

  const dispatch = useDispatch();

  useEffect(() => {
    if (partListId) {
      dispatch(setLoaderVisibility(true))
      setLabel({
        title: "Update Partner Data",
        subtitle: "Enter your data details for update the partner data",
        buttonLabel: "Update Data"
      })
      axios.get(`${API_BASE_URL}/api/partnerlist/${partListId}`, { headers: { access_token: token } }).then(({ data }) => {
        dispatch(setLoaderVisibility(false))
        setName(data.data.name);
        setProvince(data.data.province);
        setLatitude(data.data.latitude);
        setLongitude(data.data.longitude)
      }).catch((e) => {
        console.log(e)
        dispatch(setLoaderVisibility(false))
      })
    } else {
      setLabel({
        title: "Add Partner List",
        subtitle: "Enter your data details for add the partner list",
        buttonLabel: "Add Data"
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoaderVisibility(true))
    let reqParams = {
      name: name,
      province: province,
      latitude: latitude,
      longitude: longitude
    };

    if (partListId) {
      axios
        .put(`${API_BASE_URL}/api/partnerlist/${partListId}`, reqParams, {
          headers: {
            access_token: token
          }
        })
        .then(({ data }) => {
          dispatch(setLoaderVisibility(false));
          swal("Data updated", "", "success").then(({ data }) => {
            dispatch(setIsNeedRefresh(!isNeedRefresh));
            callback(false);
          });
        })
        .catch((e) => {
          console.log(e);
          dispatch(setLoaderVisibility(false))
        })
    } else {
      axios
        .post(`${API_BASE_URL}/api/partnerlist`, reqParams, {
          headers: {
            access_token: token
          }
        })
        .then(({ data }) => {
          dispatch(setLoaderVisibility(false));
          swal("Data created", "", "success").then((_) => {
            dispatch(setIsNeedRefresh(!isNeedRefresh));
            callback(false);
          });
        })
        .catch((e) => {
          console.log(e);
          dispatch(setLoaderVisibility(false))
        })
    }
  };

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <Typography variant="h5">
                {label.title}
              </Typography>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="relative p-2 flex-auto">
                <div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
                  <div className="flex gap-2">
                    <Input label="Name" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={name} onChange={(e) => setName(e.target.value)} required />
                    <Input label="Province" type="text" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={province} onChange={(e) => setProvince(e.target.value)} required />
                  </div>
                  <div className="flex gap-2">
                    <Input label="Latitude" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={latitude} type="decimal" onChange={(e) => setLatitude(e.target.value)} required />
                    <Input label="Longitude" type="decimal" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={longitude} onChange={(e) => setLongitude(e.target.value)} required />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={() => callback(false)}
                >
                  Close
                </button>
                <Button
                  className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}