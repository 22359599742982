import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import parse from 'html-react-parser';
import { dateConverter } from "../../app/helper/helper";

export function PageDetail({ data }) {
  return (
    <>
      <div className="relative h-full w-full">
        <img
          src={data?.imgBanner}
          alt="image 1"
          className="w-full h-80 md:h-96 object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 pt:14 md:pt-28">
          <div className="w-full text-left md:px-24">
            <Typography
              variant="h6"
              color="orange"
              className="mb-4 px-4"
            >
              {data?.hashtag != null ? data?.hashtag : data?.publisher}
            </Typography>
            <Typography
              variant="h1"
              color="white"
              className="mb-4 px-4 text-xl md:text-3xl text-left"
            >
              {data?.title}
            </Typography>
            <Typography
              variant="small"
              color="white"
              className="mb-2 px-4 opacity-80 text-left"
            >
              {data?.subtitle != null ? parse(data?.subtitle) : data?.publishDate != null ? dateConverter(data?.publishDate) : ""}
            </Typography>
            <Typography
              variant="small"
              color="white"
              className="mb-2 px-4 opacity-80 text-left font-bold"
            >
              {data?.author}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}