import React, { useEffect, useState } from "react";
import {
    Card,
    Typography,
    List,
    ListItem,
    ListItemPrefix,
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import {
    ChartBarIcon,
    PowerIcon,
    MegaphoneIcon,
    MapIcon,
    UsersIcon,
    UserGroupIcon,
    CurrencyDollarIcon,
    DocumentDuplicateIcon,
    CameraIcon,
    Cog8ToothIcon
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication } from "../../app/actions/action-creators";

export function SidebarWithSeparator() {
    const [open, setOpen] = useState(0);
    const settingState = useSelector((state) => state?.combineReducer?.settings);
    const dataCharts = useSelector((state) => state?.combineReducer?.adminCharts);
    const [logoUrl, setLogoUrl] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setLogoUrl(dataCharts?.data?.generals[0].logoUrl);
    }, [dataCharts]);

    const handleLogout = () => {
        swal({
            title: settingState?.popup?.areyousure,
            icon: "warning",
            buttons: true,
        })
            .then((willLogout) => {
                if (willLogout) {
                    let data = {
                        token: null,
                        auth: false
                    }
                    dispatch(setAuthentication(data));
                    navigate("/")
                }
            });
    };

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <div className="overflow-y-auto">
            <Card className="h-full w-full min-h-[100vh] p-4 shadow-xl shadow-white-900/5 bg-green-900 overscroll-auto" style={{ borderRadius: 0 }}>
                <div className="mb-2 p-4 overflow-y-auto sticky">
                    {
                        logoUrl != null ? <img
                            className="h-20 w-full p-4 rounded-lg object-contain object-center shadow-xl shadow-blue-gray-900/50 bg-gray-100"
                            src={logoUrl}
                            alt="nature image"
                        /> : null
                    }

                </div>
                <List className="text-gray-50">
                    <Accordion
                        open={open === 6}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto text-gray-50 h-4 w-4 transition-transform ${open === 6 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                        <ListItem className="p-0" selected={open === 6}>
                            <AccordionHeader onClick={() => handleOpen(6)} className="border-b-0 p-3">
                                <ListItemPrefix>
                                    <ChartBarIcon className="h-5 w-5" color="white" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal text-gray-50">
                                    {settingState?.dashboard}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                            <List className="p-0 text-gray-50">
                                <ListItem onClick={() => navigate('/cms/dashboard')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.dashboard_overview?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/homesetup')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.dashboard_home_setup?.title}
                                </ListItem>
                            </List>
                        </AccordionBody>
                    </Accordion>
                    <Accordion
                        open={open === 7}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto text-gray-50 h-4 w-4 transition-transform ${open === 7 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                        <ListItem className="p-0" selected={open === 7}>
                            <AccordionHeader onClick={() => handleOpen(7)} className="border-b-0 p-3">
                                <ListItemPrefix>
                                    <MapIcon className="h-5 w-5" color="white" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal text-gray-50">
                                    {settingState?.timber_traceability}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                            <List className="p-0 text-gray-50">
                                <ListItem onClick={() => navigate('/cms/timbertracings')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.timber_tracings?.title}
                                </ListItem>
                            </List>
                        </AccordionBody>
                    </Accordion>
                    <Accordion
                        open={open === 5}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto text-gray-50 h-4 w-4 transition-transform ${open === 5 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                        <ListItem className="p-0" selected={open === 5}>
                            <AccordionHeader onClick={() => handleOpen(5)} className="border-b-0 p-3">
                                <ListItemPrefix>
                                    <DocumentDuplicateIcon className="h-5 w-5" color="white" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal text-gray-50">
                                    {settingState?.program}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                            <List className="p-0 text-gray-50">
                                <ListItem onClick={() => navigate('/cms/program/general')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.program_general?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/program')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.program_list?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/proposal')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.proposal_list?.title}
                                </ListItem>
                            </List>
                        </AccordionBody>
                    </Accordion>
                    <Accordion
                        open={open === 2}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto text-gray-50 h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                        <ListItem className="p-0" selected={open === 2}>
                            <AccordionHeader onClick={() => handleOpen(2)} className="border-b-0 p-3">
                                <ListItemPrefix>
                                    <CameraIcon className="h-5 w-5" color="white" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal text-gray-50">
                                    {settingState?.partner}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                            <List className="p-0 text-gray-50">
                                <ListItem onClick={() => navigate('/cms/partner')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.partner_general?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/partnerlist')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.partner_list?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/partner/open')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.partner_findings?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/stories')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.partner_stories?.title}
                                </ListItem>
                            </List>
                        </AccordionBody>
                    </Accordion>
                    <Accordion
                        open={open === 1}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                        <ListItem className="p-0" selected={open === 1}>
                            <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3 text-gray-50">
                                <ListItemPrefix>
                                    <MegaphoneIcon className="h-5 w-5" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal text-gray-50">
                                    {settingState?.publication}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                            <List className="p-0 text-gray-50">
                                <ListItem onClick={() => navigate('/cms/publication/general')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.publication_general?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/publication/news')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.publication_news?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/publication')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.publication_document_list?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/publication/video')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.publication_video?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/categories')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.publication_categories?.title}
                                </ListItem>
                            </List>
                        </AccordionBody>
                    </Accordion>
                    <Accordion
                        open={open === 3}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto text-gray-50 h-4 w-4 transition-transform ${open === 3 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                        <ListItem className="p-0" selected={open === 3}>
                            <AccordionHeader onClick={() => handleOpen(3)} className="border-b-0 p-3">
                                <ListItemPrefix>
                                    <UserGroupIcon className="h-5 w-5" color="white" />
                                </ListItemPrefix>
                                <Typography color="blue-gray" className="mr-auto font-normal text-gray-50">
                                    {settingState?.about}
                                </Typography>
                            </AccordionHeader>
                        </ListItem>
                        <AccordionBody className="py-1">
                            <List className="p-0 text-gray-50">
                                <ListItem onClick={() => navigate('/cms/about')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.about_general?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/about/goal')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.about_goals?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/about/mission')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.about_missions?.title}
                                </ListItem>
                                <ListItem onClick={() => navigate('/cms/about/team')}>
                                    <ListItemPrefix>
                                        <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                                    </ListItemPrefix>
                                    {settingState?.about_team?.title}
                                </ListItem>
                            </List>
                        </AccordionBody>
                    </Accordion>
                    <Accordion
                        open={open === 4}
                        icon={
                            <ChevronDownIcon
                                strokeWidth={2.5}
                                className={`mx-auto text-gray-50 h-4 w-4 transition-transform ${open === 4 ? "rotate-180" : ""}`}
                            />
                        }
                    >
                    </Accordion>
                    <hr className="my-2 border-blue-gray-50" />
                    {/* <a href={MIDTRANS_DASHBOARD_URL} target="_blank"> */}
                    <ListItem onClick={() => navigate('/cms/donate')}>
                        <ListItemPrefix>
                            <CurrencyDollarIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        {settingState?.donate?.title}
                    </ListItem>
                    <ListItem onClick={() => navigate('/cms/generalsetup')}>
                        <ListItemPrefix>
                            <Cog8ToothIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        {settingState?.genset?.title}
                    </ListItem>
                    <ListItem onClick={handleLogout} variant="gradient">
                        <ListItemPrefix>
                            <PowerIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        {settingState?.logout}
                    </ListItem>
                </List>
            </Card>
        </div>
    );
}