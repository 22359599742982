import React, { useEffect, useRef, useState } from "react";
import { PageDetail, OthersList, TabsWithIcon } from "../../components/index.js";
import { Typography } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetailProgram, setLoaderVisibility } from "../../app/actions/action-creators.js";
import "../../css/detailpage.css";

const ProgramDetail = () => {
	const [data, setData] = useState();
	const [progList, setProgList] = useState();
	const { id } = useParams();
	const dispatch = useDispatch();
	const dataStore = useSelector((state) => state?.combineReducer?.detailProgram);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

	useEffect(() => {
		if (Object.keys(dataStore).length === 0 || !dataStore) {
		  dispatch(setLoaderVisibility(true))
		} else {
		  dispatch(setLoaderVisibility(false))
		}
	  }, [dataStore]);

	useEffect(() => {
		dispatch(getDetailProgram(id, languageCode));
	}, [id]);

	useEffect(() => {
		if (dataStore.item)
			setData(dataStore?.item[0]);
		if (dataStore?.others)
			setProgList(dataStore?.others);
	}, [id, dataStore]);

	return (
		<div className="bg-gray-100">
			<PageDetail data={data} />
			<div className="md:flex md:justify-between md:py-8 md:px-12">
				<div className="w-full w-full px-4 md:w-2/3 md:px-8">
					<div className="mb-2">
						<img src={data?.imgUrl} alt="..." className="hidden w-full h-40 md:block md:shadow md:rounded md:h-96 md:align-middle md:border-none" />
					</div>
					<TabsWithIcon dataDetail={data} />
				</div>
				<div className="w-full p-4 md:w-1/3">
					<Typography
						variant="h5"
						color="black"
						className="mb-0 opacity-80 text-justify md:pl-4"
					>
						Others programs:
					</Typography>
					<OthersList module="program" otherList={progList != null ? progList : []} />
				</div>
			</div>
		</div>
	);
};

export default ProgramDetail;
