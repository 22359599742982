import React, { useEffect, useState } from "react";
import { Button, Card, Typography, CardFooter, Select, Option } from "@material-tailwind/react";
import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { getAdminCategories, getAdminPublications, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import { ModalFormPublication } from "../Form/ModalFormPublication";
import { useNavigate } from "react-router-dom";

export function TablePublications() {
  const data = useSelector((state) => state?.combineReducer?.adminOrganizations?.publications);
  const TABLE_ROWS = data?.items;
  const CATEGORIES = useSelector((state) => state?.combineReducer?.adminOrganizations?.categories);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const TABLE_HEAD = [languageCode == 1033 ? "Title" : "Judul", languageCode == 1033 ? "Description" : "Deskripsi", languageCode == 1033 ? "Category" : "Kategori", languageCode == 1033 ? "Language" : "Bahasa", <div className="flex items-end justify-center gap-2 w-full"><Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-1/2" color="black" onClick={() => setShowModal(true)}>
    <PlusCircleIcon className="w-4" />
    {languageCode == 1033 ? "Add" : "Tambah"}
  </Button></div>];
  const pageTotal = useSelector((state) => state?.combineReducer?.publications?.pages?.pageTotal);
  const [category, setCategory] = useState(1);
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.length === 0) {
      dispatch(setLoaderVisibility(true))
    } else {
      dispatch(setLoaderVisibility(false))
    }
  }, [data]);

  useEffect(() => {
    if(category != 5){
      let params = {
        languageCode: languageCode,
        searchKey: searchKey,
        page: pageNum,
        token: token,
        limit: 10,
        categoryCode: category
      }
      dispatch(getAdminPublications(params));
      dispatch(getAdminCategories({ token }));
    } else {
      navigate("/cms/publication/video");
    }
  }, [isNeedRefresh, languageCode, searchKey, pageNum, category]);

  const handlePageButton = (operator) => {
    setPageNum(pageNum + operator);
  };

  const handleCallback = () => {
    setId(null)
    setShowModal(false);
    setIsNeedRefresh(!isNeedRefresh);
  }

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true))
          axios.delete(`${API_BASE_URL}/api/publication/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false))
            setIsNeedRefresh(!isNeedRefresh)
          }).catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
        }
      });
  };

  const handleUpdate = (id) => {
    setId(id);
    setShowModal(true)
  };

  return (
    <div className="p-2">
      {showModal ? (
        <ModalFormPublication callback={handleCallback} id={id} categories={CATEGORIES} />
      ) : null}
      <Card className="w-full rounded-none">
        <div className="p-2 relative text-gray-600 flex justify-end">
          <div className="w-1/2">
            <Select value={category} onChange={(e) => setCategory(e)} defaultValue={category} label="Category">
              {
                CATEGORIES?.map(({ name, value }, index) => (
                  <Option value={value} aria-label={name}>{name}</Option>
                ))
              }
            </Select>
          </div>
          <div className="w-1/2 relative text-gray-600 flex justify-end">
            <input className="border-2 w-full md:w-1/2 border-gray-300 bg-white h-10 px-5 pr-8 md:pr-16 rounded-lg text-sm focus:outline-none"
              type="search" name="search" placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
            <button type="submit" className="absolute right-0 top-0 mr-4 h-full flex items-center">
              <svg className="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
                version="1.1" id="Capa_1" x="0px" y="0px"
                viewBox="0 0 56.966 56.966" space="preserve"
                width="512px" height="512px">
                <path
                  d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </div>
        </div>
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-4">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(({ id, title, description, categoryCode, languageCode, fileUrl }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {title}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {description}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {CATEGORIES?.filter(({ title, value }) => value == categoryCode)[0]?.name}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {languageCode === 1033 ? "English" : "Bahasa Indonesia"}
                  </Typography>
                </td>
                <td className="p-4 h-full flex items-center justify-center">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-4" color="white" />
                    {
                      languageCode == 1033 ? "Update" : "Ubah"
                    }
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-4" />
                    {
                      languageCode == 1033 ? "Delete" : "Hapus"
                    }
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50">
          <Typography variant="small" color="blue-gray" className="font-normal">
            {data?.pages?.title}
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
              {data?.pages?.prev}
            </Button>
            <Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
              {data?.pages?.next}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};