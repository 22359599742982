import { SET_CLIENT_NAVBAR } from "../actions/action-types.js";

const initState = {
    id: {
        home : "Beranda",
        program : "Program",
        partner : "Mitra",
        publication : "Publikasi",
        news : "Berita",
        story : "Cerita Pemantau",
        donate : "Donasi",
        about: "Tentang",
        lang : "Pilih bahasa",
        resource : "Sumber Daya",
        org : "Organisasi",
        loginAdmin : "Masuk sebagai Admin",
        contactInfo : "Informasi Kontak",
        timbertraceability : "Peredaran&nbspKayu",
        oldwebsite: "Website versi lama"
    },
    en : {
        home : "Home",
        program : "Programs",
        partner : "Partners",
        publication : "Publication",
        news : "News",
        story : "Story of The Monitor",
        donate : "Donate",
        about : "About",
        lang: "Select language",
        resource : "Resource",
        org : "Organization",
        loginAdmin : "Login as Admin",
        contactInfo : "Contact Info",
        timbertraceability : "Timber&nbspTraceability",
        oldwebsite: "Old Version Website"
    }
}

export default function clientNavbar(state = initState, {type, payload}) {
    switch (type) {
        case SET_CLIENT_NAVBAR:
            return (state = payload)
        default:
            return state
    }
}