import React, { useEffect, useState } from "react";
import { ModalFormUser, NavbarCMS, TableUsers } from "../../components";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Button, Input, Typography } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCharts, setLoaderVisibility } from "../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from "../../data/static-config";
import swal from "sweetalert";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
	const dataCharts = useSelector((state) => state?.combineReducer?.adminCharts);
	const [idLogo, setIdLogo] = useState();
	const dispatch = useDispatch();
	const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
	const setting = useSelector((state) => state?.combineReducer?.settings);
	const [showModal, setShowModal] = useState(false);
	const [isNeedRefresh, setNeedRefresh] = useState();
	const [logoUrl, setLogoUrl] = useState(null);
	const [file, setFile] = useState();
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

	function handleChangeFile(e) {
		setFile(e.target.files[0]);
		setLogoUrl(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
	};

	const handleCallback = () => {
		setShowModal(false);
		setNeedRefresh(!isNeedRefresh);
	};

	const handleAddUser = () => {
		setShowModal(true);
	};

	useEffect(() => {
		if (Object.keys(dataCharts).length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [dataCharts]);

	useEffect(() => {
		const req = {
			token: token,
			languageCode: languageCode
		}
		dispatch(getAdminCharts(req));
	}, [isNeedRefresh, languageCode]);

	useEffect(() => {
		setIdLogo(dataCharts?.data?.generals[0].id);
		setLogoUrl(dataCharts?.data?.generals[0].logoUrl);
	}, [dataCharts]);

	function isInt(value) {
		return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value))
	}

	const handleSubmitLogo = (e) => {
		e.preventDefault();
		dispatch(setLoaderVisibility(true));
		const reqParams = new FormData()
		reqParams.append("file", file);

		if (isInt(idLogo)) {
			axios
				.put(`${API_BASE_URL}/api/general/logo/${idLogo}`, reqParams, {
					headers: {
						access_token: token
					}
				})
				.then(({ data }) => {
					dispatch(setLoaderVisibility(false));
					swal("Logo updated", "", "success").then((_) => {
						setNeedRefresh(!isNeedRefresh);
						setFile(null);
						setLogoUrl(data?.data[0].logoUrl);
					});
				})
				.catch((err) => {
					dispatch(setLoaderVisibility(false));
					console.log(err);
				})
		} else {
			axios
				.post(`${API_BASE_URL}/api/general/logo`, reqParams, {
					headers: {
						access_token: token
					}
				})
				.then(({ data }) => {
					dispatch(setLoaderVisibility(false));
					swal("Logo added", "", "success").then((_) => {
						setNeedRefresh(!isNeedRefresh);
						setFile(null);
						setLogoUrl(data?.data[0].logoUrl);
					});
				})
				.catch((err) => {
					dispatch(setLoaderVisibility(false));
					console.log(err);
				})
		}
	};

	return (
		<div className="h-full w-full">
			<NavbarCMS module={setting?.dashboard_overview?.navbar} />
			<div className="lg:mb-8 flex items-center justify-between">
				<div className="flex flex-col lg:gap-2 sm:flex-row">
					{showModal ? (
						<ModalFormUser callback={handleCallback} />
					) : null}
				</div>
			</div>
			<div className="w-full flex justify-center gap-2">
				<div className="w-full p-4 grid grid-cols-2 gap-2">
					<div className="w-full">
						<div className='flex justify-center'>
							<Typography>{languageCode == 1033 ? "Count of Timber Tracings by Province" : "Jumlah Peredaran Kayu Berdasarkan Provinsi"}</Typography>
						</div>
						{
							Object.keys(dataCharts).length > 0 ? <Doughnut data={dataCharts?.data?.province} className="" options={{
								plugins: {
									legend: {
										position: 'right'
									}
								}
							}} /> : null
						}
					</div>
					<div className="w-full">
						<div className='flex justify-center'>
							<Typography>{languageCode == 1033 ? "Count of Partner Findings" : "Jumlah Temuan Mitra"}</Typography>
						</div>
						{Object.keys(dataCharts).length > 0 ? <Doughnut data={dataCharts?.data?.findings} className="" options={{
							plugins: {
								legend: {
									position: 'right'
								}
							}
						}} /> : null}
					</div>
					<div className="w-full">
						<div className='flex justify-center'>
							<Typography>{languageCode == 1033 ? "Count of Publications" : "Jumlah Publikasi"}</Typography>
						</div>
						{
							Object.keys(dataCharts).length > 0 ? <Doughnut data={dataCharts?.data?.publications} className="" options={{
								plugins: {
									legend: {
										position: 'right'
									}
								}
							}} /> : null
						}
					</div>
					<div className="w-full">
						<div className='flex justify-center'>
							<Typography>{languageCode == 1033 ? "Count of Programs" : "Jumlah Program"}</Typography>
						</div>
						{
							Object.keys(dataCharts).length > 0 ? <Doughnut data={dataCharts?.data?.programs} className="" options={{
								plugins: {
									legend: {
										position: 'right'
									}
								}
							}} /> : null
						}
					</div>
				</div>
				<div className="w-full p-4 flex flex-col gap-2">
					<div className="w-full">
						<div className="flex flex-row justify-center items-center gap-2">
							<div className="h-auto w-1/2">
								{
									logoUrl ? <img
										className="h-28 w-full p-4 rounded-lg object-contain object-center shadow-xl shadow-blue-gray-100/50 bg-gray-100"
										src={logoUrl}
										alt="logo ifm"
										key={logoUrl}
									/> : null
								}
							</div>
							<div className="flex flex-col w-1/2 gap-2">
								<div className="relative flex w-3/4 max-w-[28rem]">
									<Input size="lg" placeholder="Content" type="file" label="Logo IFM"
										className="pr-20" onChange={handleChangeFile}
										containerProps={{
											className: "min-w-0",
										}}
									/>
								</div>
								<div className="h-full">
									<Button className="" onClick={handleSubmitLogo}>{languageCode == 1033 ? "Change Logo" : "Ubah Logo"}</Button>
								</div>
							</div>
						</div>
					</div>
					<hr className="my-8" />
					<div className="w-full">
						<TableUsers handlePopUp={handleAddUser} callback={handleCallback} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Dashboard;