import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import logo from "../../images/logo.png";

export function OthersList({ otherList, module }) {
    const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
    return (
        <div className="px-1">
            {
                otherList?.map((item) => (
                    <Card className="w-full h-[9em] bg-green-900 w-full flex-row mt-2">
                        <CardHeader
                            shadow={false}
                            floated={false}
                            className="m-0 w-1/3 shrink-0 rounded-r-none flex justify-center align-center"
                        >
                            <img
                                src={item?.imgUrl ? item?.imgUrl : item?.imgBanner ? item?.imgBanner : logo}
                                alt="card-image"
                                className="w-full object-cover flex items-center"
                            />
                        </CardHeader>
                        <CardBody style={{ padding: "1em" }} className="w-2/3">
                            <Typography variant="h6" color="yellow" className="mb-1 line-clamp-1">
                                {item?.title}
                            </Typography>
                            <Typography color="white" variant="p" className="mb-1 font-normal line-clamp-2">
                                {
                                    module == "program" ? (item.subtitle != null ? parse(item.subtitle) : null) : item.description != null ? parse(item.description) : null
                                }
                            </Typography>
                            <Link to={item?.isgrants ? "/program/grants/" + item?.id : `/${module}/${item?.id}/${item?.title?.replace(/ /g,"-")}`} className="inline-block w-full flex justify-end">
                                <Button variant="text" className="flex items-center gap-1" color="white">
                                    {languageCode == 1033 ? "Read More" : "Selengkapnya"}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        className="h-4 w-4"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                        />
                                    </svg>
                                </Button>
                            </Link>
                        </CardBody>
                    </Card>
                ))
            }
            <Link to={"/" + module}>
                <Button fullWidth className="mt-2">{languageCode == 1033 ? "SEE MORE" : "Lihat lebih banyak"}</Button>
            </Link>
        </div>

    );
}