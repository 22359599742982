import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import {
  BookOpenIcon,
  TvIcon,
  QueueListIcon,
  TrophyIcon
} from "@heroicons/react/24/solid";
import parse from 'html-react-parser';
import "../../css/detailpage.css";
import { useSelector } from "react-redux";

export function TabsWithIcon({ dataDetail }) {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const data = [
    {
      label: languageCode == 1033 ? "Project" : "Proyek",
      value: "Project",
      icon: TvIcon,
      desc: dataDetail?.project
    },
    {
      label: languageCode == 1033 ? "Objective" : "Tujuan",
      value: "objective",
      icon: BookOpenIcon,
      desc: dataDetail?.objective,
    },
    {
      label: languageCode == 1033 ? "Activity" : "Kegiatan",
      value: "activity",
      icon: QueueListIcon,
      desc: dataDetail?.activity
    },
    {
      label: languageCode == 1033 ? "Output" : "Hasil",
      value: "output",
      icon: TrophyIcon,
      desc: dataDetail?.output,
    },
  ];
  return (
    <Tabs value="Project">
      <TabsHeader>
        {data.map(({ label, value, icon }) => (
          <Tab key={value} value={value}>
            <div className="flex items-center gap-2">
              {React.createElement(icon, { className: "w-5 h-5" })}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }, idx) => (
          <TabPanel key={idx} value={value} className="text-justify reset-list">
            {desc != null ? parse(desc) : ""}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}