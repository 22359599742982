import React, { useState } from "react";
import { FormPartnerList, NavbarCMS, TablePartnerList } from "../../components";
import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const PartnerList = () => {
    const [showModal, setShowModal] = useState(false);
    const [idPartnerList, setIdPartnerList] = useState(null);
    const setting = useSelector((state) => state?.combineReducer?.settings);

    const handleCallback = (params, id) => {
        setShowModal(params);
        setIdPartnerList(id);
    }
    return (
        <div className="h-full">
            <NavbarCMS module={setting?.partner_list?.navbar} />
            <div className="grid grid-cols-1 gap-3">
                <div className="flex flex-col p-2">
                    <Typography variant="p" className="font-bold">
                        Partner List
                    </Typography>
                    <hr className="my-4" />
                    {showModal ? (
                        <FormPartnerList callback={(params) => handleCallback(params)} idPartnerList={idPartnerList}/>
                    ) : null}
                    <TablePartnerList callback={(params, id) => handleCallback(params, id)} />
                </div>
            </div>
        </div>
    )
}

export default PartnerList;