import { SET_CLIENT_FOOTER } from "../actions/action-types.js";

const initState = {
    address: "Taman Yasmin Jalan Katelia Raya No.48, RT.01/RW.09, Cilendek Timur, Kecamatan Bogor Barat, Kota Bogor, Jawa Barat 16112",
    phone: "0251 - 8398897",
    email: "info@forestfund.or.id",
    socialmedia: []
}

export default function clientFooter(state = initState, {type, payload}) {
    switch (type) {
        case SET_CLIENT_FOOTER:
            return (state = payload)
        default:
            return state
    }
}