import React, { useState, useEffect } from "react";
import {
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { ArrowDownLeftIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { UserPlusIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import {
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";
import { ModalForm } from "./ModalForm";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from '../../../data/static-config.js';
import { setLoaderVisibility } from "../../../app/actions/action-creators.js";
import { idLabelMitra, enLabelMitra } from "../../../data/static-config.js";

export function SortableTableAdmin({ TABLE_HEAD, TABLE_ROWS, LABEL, keyDown, FINDING_STATUS, REFRESH_TABLE }) {
  const [showModal, setShowModal] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [id, setId] = useState();
  const [titleForm, setTitleForm] = useState();
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const dispatch = useDispatch();
  const [lblLanguage, setLblLanguage] = useState();

  useEffect(() => {
    languageCode == 1033 ? setLblLanguage(enLabelMitra) : setLblLanguage(idLabelMitra);
  }, [languageCode]);

  const search = (event) => {
    setSearchKey(event.target.value);
  }

  const handleKeyDown = () => {
    keyDown(searchKey);
  };

  const handleCallback = () => {
    setShowModal(false);
    setId(null);
    setTitleForm(lblLanguage?.form?.titleAdd);
    REFRESH_TABLE();
  };

  const handleUpdate = (id) => {
    setId(id);
    setTitleForm(lblLanguage?.form?.titleUpdate);
    setShowModal(true);
  }

  const generateStatucCode = (params) => {
    let comp = null;
    FINDING_STATUS?.map(({ name, value }) => {
      if (params === value) comp = <Chip
        variant="ghost"
        size="sm"
        value={name}
        color={value == 4 ? "orange" : "green"}
      />
    })
    return comp;
  };

  const handleDelete = (id) => {
    swal({
      title: languageCode == 1033 ? "Are you sure?" : "Apakah Anda yakin?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true));
          axios.delete(`${API_BASE_URL}/api/partner/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            REFRESH_TABLE();
            dispatch(setLoaderVisibility(false));
          }).catch((err) => {
            console.log(err)
            dispatch(setLoaderVisibility(false));
          })
        }
      });
  };

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="lg:mb-8 flex items-center justify-between">
          <div className="flex flex-col lg:gap-2 sm:flex-row">
            {showModal ? (
              <ModalForm callback={handleCallback} id={id} title={titleForm} />
            ) : null}
          </div>
        </div>
        <div className="flex flex-col items-center justify-between md:flex-row">
          <Button className="flex items-center gap-2" size="sm" onClick={() => setShowModal(true)}>
            <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> {lblLanguage?.btn?.add}
          </Button>
          <div className="relative flex w-full gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label={lblLanguage?.form?.search}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />} value={searchKey}
                onChange={(e) => search(e)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-2">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-gray-400 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(
              ({ id, imgUrl, finding, organization, statusCode, located }, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={id}>
                    <td className={classes} style={{ maxWidth: "400px" }}>
                      <div className="flex items-center gap-3">
                        <Tooltip content={languageCode == 1033 ? "Download Attachment" : "Unduh Lampiran"}>
                          <Typography as="a" target="_blank" href={imgUrl} className="w-8 opacity-80 transition-opacity hover:opacity-100">
                            <ArrowDownTrayIcon className="text-ifmpallet2" color="white" />
                          </Typography>
                        </Tooltip>
                      </div>
                    </td>
                    <td className={classes} style={{ maxWidth: "400px" }}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {finding}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes} style={{ maxWidth: "200px" }}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {organization}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        {generateStatucCode(statusCode)}
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {located}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Button color="blue-gray" variant="solid" size="sm" className="w-full flex items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                        <PencilIcon className="w-4" color="white" />
                        {lblLanguage?.btn?.update}
                      </Button>
                      <Button color="red" variant="gradient" size="sm" className="w-full flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                        <TrashIcon className="w-4" />
                        {lblLanguage?.btn?.delete}
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </CardBody>
    </>
  );
}