import {
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon, PlusCircleIcon, TrashIcon, ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  CardBody,
  CardHeader,
  Input,
  Option,
  Select,
  Typography,
} from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { API_BASE_URL } from '../../../data/static-config.js';
import { ModalFormTimberTrace } from "../Form/ModalFormTimberTrace.js";
import { setLoaderVisibility } from "../../../app/actions/action-creators.js";
import { ExportToExcel } from "../../client/ExportToExcel.js";

export function TableTimberTracings({ TABLE_ROWS, keyDown, REFRESH_TABLE, CallBack }) {
  const [searchKey, setSearchKey] = useState("");
  const [id, setId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [categoryCodeArr, setCategoryCodeArr] = useState();
  const [categoryCode, setCategoryCode] = useState(6000);
  const [data, setData] = useState([]);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const TABLE_HEAD = languageCode == 1033 ? ["Province", "Company Name", "Realization of Raw Materials (m3)", "Origin Province", ""] : ["Provinsi", "Nama Perusahaan", "Realisasi Bahan Baku (m3)", "Provinsi Asal", ""];
  const dispatch = useDispatch();

  const search = (event) => {
    setSearchKey(event.target.value);
  }

  const handleKeyDown = () => {
    keyDown(searchKey);
  };

  const handleUpdate = (id) => {
    setId(id);
    setShowModal(true);
  };

  const handleCallback = () => {
    setShowModal(false);
    REFRESH_TABLE();
  };

  useEffect(() => {
    fetchData()
  }, [TABLE_ROWS]);

  const fetchData = () => {
    axios.post(`${API_BASE_URL}/api/timbertrace/exportdata`, { "categoryCode": categoryCode }, {
      headers: {
        access_token: token
      }
    }).then(({ data }) => {
      const customHeadings = data?.data?.map((item, idx) => ({
        "No": idx + 1,
        "Provinsi": item.province,
        "Nama Perusahaan": item.companyName,
        "Nomor SK": item.SKNo,
        "Nama Pemilik": item.ownerName,
        "Realisasi Bahan Baku": item.realizationNo,
        "Asal-usul Bahan Baku": item.originMaterial,
        "Keterangan Asal Usul Bahan Baku": item.originDescription,
        "Provinsi Asal Bahan Baku": item.originProvince
      }))
      setData(customHeadings)
    })
  };

  const fileName = `Export Data Peredaran Kayu - ${categoryCodeArr?.filter((item) => {
    return item.metercubicno == categoryCode;
  })[0].metercubicno === 6000 ? "Lebih dari 6000m3" : "Kurang dari 6000m3"}`;

  useEffect(() => {
    axios.get(`${API_BASE_URL}/api/client/module/timbertracecategory`).then(({ data }) => {
      setCategoryCodeArr(data?.data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    CallBack(categoryCode);
  }, [categoryCode]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        dispatch(setLoaderVisibility(true));
        if (willDelete) {
          axios.delete(`${API_BASE_URL}/api/timbertrace/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false));
            REFRESH_TABLE();
          }).catch((err) => {
            console.log(err)
          })
        } else {
          dispatch(setLoaderVisibility(false));
        }
      });
  };

  const handleAdd = () => {
    setShowModal(true);
    setId(null)
  }

  return (
    <>
      {
        showModal ? <ModalFormTimberTrace id={id} callback={handleCallback} categoryCodeArr={categoryCodeArr} /> : null
      }
      <CardBody className="overflow-scroll px-2">
        <div className="flex flex-col items-center justify-between md:flex-row py-2">
          <div className="flex justify-between gap-2">
            <Button className="flex items-center gap-2" size="sm" onClick={handleAdd}>
              <PlusCircleIcon strokeWidth={2} className="h-4 w-4" /> {
                languageCode == 1033 ? "Add Data" : "Tambah Data"
              }
            </Button>
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />} value={searchKey}
                onChange={(e) => search(e)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="relative flex w-full gap-2 md:w-max pt-2">
            <div className="w-full md:w-72">
              {
                categoryCodeArr ?
                  <Select label="Select Category" value={categoryCode} onChange={(e) => setCategoryCode(e)}>
                    {
                      categoryCodeArr?.map(({ id, fullname, metercubicno }) => (
                        <Option key={id} value={metercubicno}>{fullname}</Option>
                      ))
                    }
                  </Select> : null
              }
            </div>
            {
              data && <ExportToExcel apiData={data} fileName={fileName} />
            }
            {/*             
            <Button className="flex items-center gap-2" size="sm" onClick={() => { }}>
              <ArrowRightCircleIcon strokeWidth={2} className="h-4 w-4" /> <ExportToExcel />
            </Button> */}
          </div>
        </div>
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-gray-400 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS != null ? TABLE_ROWS?.map(
              ({ id, province, companyName, realizationNo, originProvince }, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={id}>
                    <td className={classes} style={{ maxWidth: "400px" }}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {province}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes} style={{ maxWidth: "200px" }}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {companyName}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {realizationNo}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {originProvince}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex">
                        <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                          <PencilIcon className="w-4" color="white" />
                        </Button>
                        <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                          <TrashIcon className="w-4" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              },
            ) : null}
          </tbody>
        </table>
      </CardBody>
    </>
  );
}