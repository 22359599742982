import React, { useEffect, useState } from "react";
import { NavbarCMS, SortableTableAdmin, TableProposalList } from "../../components/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getAdminPartnerData, getAdminProposals, setLoaderVisibility } from "../../app/actions/action-creators.js";
import {
	Card,
	Typography,
	Button,
	CardFooter,
} from "@material-tailwind/react";

const ProposalCMS = () => {
	const dispatch = useDispatch();
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const [pageNum, setPageNum] = useState(1);
	const data = useSelector((state) => state?.combineReducer?.adminOrganizations?.proposals?.data?.proposalList);
	const pageTotal = useSelector((state) => state?.combineReducer?.adminOrganizations?.proposals?.data?.pages?.pageTotal);
	const [searchKey, setSearchKey] = useState("");
	const [isNeedRefresh, setIsNeedRefresh] = useState(false);
	const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
	const setting = useSelector((state) => state?.combineReducer?.settings);

	useEffect(() => {
		if (data?.length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [data]);

	const getSearchKey = (string) => {
		setSearchKey(string);
	};

	useEffect(() => {
		let req = {
			languageCode: languageCode,
			page: pageNum,
			searchKey: searchKey,
			limit: 10,
			token: token
		};
		dispatch(getAdminProposals(req));
	}, [languageCode, pageNum, searchKey, isNeedRefresh]);

	const handlePageButton = (operator) => {
		setPageNum(pageNum + operator);
	};

	const handleRefresh = () => {
		setIsNeedRefresh(!isNeedRefresh);
	}

	return (
		<div className="h-full w-full">
			<NavbarCMS module={setting?.proposal_list?.navbar} />
			<Card className="w-full">
				<TableProposalList  TABLE_HEAD={data?.data?.proposalListHeader} TABLE_ROWS={data?.data?.proposalList} LABEL={data?.data?.label} keyDown={getSearchKey} REFRESH_TABLE={() => handleRefresh()} />
				<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
					<Typography variant="small" color="blue-gray" className="font-normal">
						{data?.data?.pages?.title}
					</Typography>
					<div className="flex gap-2">
						<Button variant="outlined" size="sm" disabled={pageNum === 1 ? true : false} onClick={() => handlePageButton(-1)}>
							{data?.data?.pages?.prev}
						</Button>
						<Button variant="outlined" size="sm" disabled={pageNum === pageTotal ? true : false} onClick={() => handlePageButton(1)}>
							{data?.data?.pages?.next}
						</Button>
					</div>
				</CardFooter>
			</Card>
		</div>
	)
}

export default ProposalCMS;