import axios from 'axios';
import { API_BASE_URL } from '../../data/static-config.js';
import { SET_ADMIN_CATEGORIES, SET_ADMIN_CHARTS, SET_ADMIN_DONATE_GENERAL, SET_ADMIN_FINDING_PARTNERS, SET_ADMIN_GENERALS, SET_ADMIN_GOALS, SET_ADMIN_HOME_GENERAL, SET_ADMIN_LANGUAGE, SET_ADMIN_MISIONS, SET_ADMIN_NEWS_GENERAL, SET_ADMIN_NEWS_LIST, SET_ADMIN_PARTNERS, SET_ADMIN_PROGRAM_GENERAL, SET_ADMIN_PROGRAM_LIST, SET_ADMIN_PUBLICATIONS, SET_ADMIN_PUBLICATION_DOC_GENERAL, SET_ADMIN_TEAMS, SET_ADMIN_TIMBER_TRACING, SET_ADMIN_TOKEN, SET_ADMIN_VIDEOS, SET_AUTHENTICATION_ADMIN, SET_CLIENT_ABOUT, SET_CLIENT_BOOKS, SET_CLIENT_DONATE, SET_CLIENT_FACTSHEET, SET_CLIENT_FOOTER, SET_CLIENT_HOME, SET_CLIENT_LANGUANGE, SET_CLIENT_NEWS, SET_CLIENT_PARTNER, SET_CLIENT_PRESSRELEASE, SET_CLIENT_PROGRAM, SET_CLIENT_PUBLICATION, SET_CLIENT_REPORT, SET_CLIENT_VIDEOS, SET_DETAIL_NEWS, SET_DETAIL_PROGRAM, SET_FINDING_STATUSES, SET_ISNEED_REFRESH, SET_LOADER_VISIBILITY, SET_SOCIAL_MEDIA_LINK, SET_ADMIN_STORY_LIST, SET_CLIENT_STORY, SET_DETAIL_STORY, SET_ADMIN_PARTNER_LIST, SET_GENERAL_SETUP, SET_ADMIN_PROPOSAL } from './action-types.js';

const baseUrl = API_BASE_URL;

export const setLoaderVisibility = (payload) => ({
    type: SET_LOADER_VISIBILITY,
    payload
});

export const setLanguageClient = (payload) => ({
    type: SET_CLIENT_LANGUANGE,
    payload
});

export const setLanguageAdmin = (payload) => ({
    type: SET_ADMIN_LANGUAGE,
    payload
});

export const setHomeClient = (payload) => ({
    type: SET_CLIENT_HOME,
    payload
});

export const setProgramClient = (payload) => ({
    type: SET_CLIENT_PROGRAM,
    payload
});

export const setDonateClient = (payload) => ({
    type: SET_CLIENT_DONATE,
    payload
});

export const setProgramDetail = (payload) => ({
    type: SET_DETAIL_PROGRAM,
    payload
});

export const setNewsDetail = (payload) => ({
    type: SET_DETAIL_NEWS,
    payload
});

export const setStoryDetail = (payload) => ({
    type: SET_DETAIL_STORY,
    payload
});

export const setPartnerClient = (payload) => ({
    type: SET_CLIENT_PARTNER,
    payload
});

export const setVideosCliet = (payload) => ({
    type: SET_CLIENT_VIDEOS,
    payload
});

export const setAboutClient = (payload) => ({
    type: SET_CLIENT_ABOUT,
    payload
});

export const setFooterClient = (payload) => ({
    type: SET_CLIENT_FOOTER,
    payload
})

export const setNewsClient = (payload) => ({
    type: SET_CLIENT_NEWS,
    payload
});

export const setStoryClient = (payload) => ({
    type: SET_CLIENT_STORY,
    payload
});

export const setReportClient = (payload) => ({
    type: SET_CLIENT_REPORT,
    payload
});

export const setPublicationClient = (payload) => ({
    type: SET_CLIENT_PUBLICATION,
    payload
});

export const setBooksClient = (payload) => ({
    type: SET_CLIENT_BOOKS,
    payload
});

export const setFactSheetClient = (payload) => ({
    type: SET_CLIENT_FACTSHEET,
    payload
});

export const setPressReleaseClient = (payload) => ({
    type: SET_CLIENT_PRESSRELEASE,
    payload
});

export const setAdminToken = (payload) => ({
    type: SET_ADMIN_TOKEN,
    payload
});

export const setAuthentication = (payload) => ({
    type: SET_AUTHENTICATION_ADMIN,
    payload
});

export const setAdminGoals = (payload) => ({
    type: SET_ADMIN_GOALS,
    payload
});
export const setAdminMissions = (payload) => ({
    type: SET_ADMIN_MISIONS,
    payload
});

export const setAdminTeams = (payload) => ({
    type: SET_ADMIN_TEAMS,
    payload
})

export const setAdminGenerals = (payload) => ({
    type: SET_ADMIN_GENERALS,
    payload
})

export const setAdminSocialMediaLinks = (payload) => ({
    type: SET_SOCIAL_MEDIA_LINK,
    payload
})

export const setAdminFindingStatuses = (payload) => ({
    type: SET_FINDING_STATUSES,
    payload
});

export const setAdminPartnerList = (payload) => ({
    type: SET_ADMIN_PARTNER_LIST,
    payload
});

export const setPartnerGenerals = (payload) => ({
    type: SET_ADMIN_PARTNERS,
    payload
});

export const setHomeGenerals = (payload) => ({
    type: SET_ADMIN_HOME_GENERAL,
    payload
});

export const setProgramGenerals = (payload) => ({
    type: SET_ADMIN_PROGRAM_GENERAL,
    payload
});

export const setDonateGenerals = (payload) => ({
    type: SET_ADMIN_DONATE_GENERAL,
    payload
});

export const setNewsGenerals = (payload) => ({
    type: SET_ADMIN_NEWS_GENERAL,
    payload
});

export const setNewsList = (payload) => ({
    type: SET_ADMIN_NEWS_LIST,
    payload
});

export const setStoryList = (payload) => ({
    type: SET_ADMIN_STORY_LIST,
    payload
});

export const setAdminFindingPartner = (payload) => ({
    type: SET_ADMIN_FINDING_PARTNERS,
    payload
});

export const setAdminProposals = (payload) => ({
    type: SET_ADMIN_PROPOSAL,
    payload
});

export const setAdminGeneralSetup = (payload) => ({
    type: SET_GENERAL_SETUP,
    payload
});

export const setAdminTimberTracing = (payload) => ({
    type: SET_ADMIN_TIMBER_TRACING,
    payload
});

export const setAdminCategories = (payload) => ({
    type: SET_ADMIN_CATEGORIES,
    payload
});

export const setAdminPublications = (payload) => ({
    type: SET_ADMIN_PUBLICATIONS,
    payload
});

export const setAdminPublicationGeneral = (payload) => ({
    type: SET_ADMIN_PUBLICATION_DOC_GENERAL,
    payload
});

export const setAdminVideos = (payload) => ({
    type: SET_ADMIN_VIDEOS,
    payload
});

export const setIsNeedRefresh = (payload) => ({
    type: SET_ISNEED_REFRESH,
    payload
});

export const setAdminProgramList = (payload) => ({
    type: SET_ADMIN_PROGRAM_LIST,
    payload
});

export const setAdminCharts = (payload) => ({
    type: SET_ADMIN_CHARTS,
    payload
});

export const getClientHomeData = (payload) => {
    const { languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/home?languageCode=${languageCode}`)
            .then(({ data }) => {
                dispatch(setHomeClient(data));
            })
            .catch(console.log)
    }
}

export const getClientProgramData = (payload) => {
    const { searchKey, page, limit, languageCode } = payload;
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/program?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, payload)
            .then(({ data }) => {
                let res = {
                    label: data?.data?.label,
                    pages: data?.data?.pages,
                    lst: data?.data?.lst,
                    banner: data?.data?.banner
                }
                dispatch(setProgramClient(res));
            })
            .catch(console.log)
    }
}

export const getClientDonate = (payload) => {
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/donate`, payload)
            .then(({ data }) => {
                let res = {
                    lst: data?.data,
                }
                dispatch(setDonateClient(res));
            })
            .catch(console.log)
    }
}

export const getClientNews = (payload) => {
    const { searchKey, page, limit, languageCode } = payload;
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/news?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, payload)
            .then(({ data }) => {
                let res = {
                    banner: data?.data?.banner,
                    pages: data?.data?.pages,
                    newslist: data?.data?.newsList
                }
                dispatch(setNewsClient(res));
            })
            .catch(console.log)
    }
}

export const getClientStory = (payload) => {
    const { searchKey, page, limit, languageCode } = payload;
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/story?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, payload)
            .then(({ data }) => {
                let res = {
                    banner: data?.data?.banner,
                    pages: data?.data?.pages,
                    storylist: data?.data?.storyList
                }
                dispatch(setStoryClient(res));
            })
            .catch(console.log)
    }
}

export const getDetailProgram = (id, languageCode) => {
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/program/${id}?languageCode=${languageCode}`)
            .then(({ data }) => {
                dispatch(setProgramDetail(data));
            })
            .catch(console.log)
    }
}

export const getDetailNews = (id, languageCode) => {
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/news/${id}?languageCode=${languageCode}`)
            .then(({ data }) => {
                let result = {
                    item: data?.item,
                    others: data?.others
                };
                dispatch(setNewsDetail(result));
            })
            .catch(console.log)
    }
}

export const getDetailStory = (id, languageCode) => {
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/story/${id}?languageCode=${languageCode}`)
            .then(({ data }) => {
                let result = {
                    item: data?.item,
                    others: data?.others
                };
                dispatch(setStoryDetail(result));
            })
            .catch(console.log)
    }
}

export const getClientPartnerData = (payload) => {
    const { searchKey, page, limit, languageCode } = payload;
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/partner?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, payload)
            .then(({ data }) => {
                let res = {
                    label: data?.data?.label,
                    pages: data?.data?.pages,
                    partnerList: data?.data?.partnerList,
                    partnerListHeader: data?.data?.partnerListHeader,
                    partnerListTabs: data?.data?.partnerListTabs,
                    partnerListName: data?.data?.partnerListName
                }
                dispatch(setPartnerClient(res));
            })
            .catch(console.log)
    }
}

export const getGeneralSetup = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/generalsetup`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminGeneralSetup(data?.data));
            })
            .catch(console.log)
    }
}

export const getAdminPartnerData = (payload) => {
    const { searchKey, page, limit, token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/partner?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminFindingPartner(data));
            })
            .catch(console.log)
    }
}

export const getAdminProposals = (payload) => {
    const { searchKey, page, limit, token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/proposal?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminProposals(data));
            })
            .catch(console.log)
    }
}

export const getAdminTimberTracings = (payload) => {
    const { searchKey, page, limit, token, metercubicno } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/timbertrace?search_key=${searchKey}&page=${page}&limit=${limit}&metercubicno=${metercubicno}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminTimberTracing(data));
            })
            .catch(console.log)
    }
}

export const getAdminProgramList = (payload) => {
    const { searchKey, page, limit, token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/program?search_key=${searchKey}&page=${page}&limit=${limit}&languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminProgramList(data));
            })
            .catch(console.log)
    }
}

export const getAdminCategories = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/category`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminCategories(data?.data));
            })
            .catch(console.log)
    }
}

export const getAdminPublications = (payload) => {
    const { token, searchKey, page, limit, categoryCode, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/publication?search_key=${searchKey}&page=${page}&limit=${limit}&categorycode=${categoryCode}&languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminPublications(data?.data));
            })
            .catch(console.log)
    }
}

export const getAdminPublicationDocGeneral = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/publicationgeneral?languagecode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminPublicationGeneral(data?.data));
            })
            .catch(console.log)
    }
}

export const getAdminVideos = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/video`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminVideos(data?.data));
            })
            .catch(console.log)
    }
}

export const getClientAboutData = (payload) => {
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/about`, payload)
            .then(({ data }) => {
                dispatch(setAboutClient(data.result));
            })
            .catch(console.log)
    }
}

export const getClientFooterData = (payload) => {
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/client/module/footer`, payload)
            .then(({ data }) => {
                dispatch(setFooterClient(data?.data));
            })
            .catch(console.log)
    }
}

export const getClientVideos = (payload) => {
    const { token, categoryCode, searchKey, languageCode, page } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/videos?category_code=${categoryCode}&search_key=${searchKey}&languageCode=${languageCode}&page=${page}`)
            .then(({ data }) => {
                dispatch(setVideosCliet(data?.data))
            })
            .catch(console.log)
    }
}

export const getClientReport = (payload) => {
    const { categoryCode, searchKey, languageCode, page } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/publications?category_code=${categoryCode}&search_key=${searchKey}&languageCode=${languageCode}&page=${page}`)
            .then(({ data }) => {
                dispatch(setReportClient(data?.data))
            })
            .catch(console.log)
    }
}

export const getClientPublication = (payload) => {
    const { categoryCode, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/publications?category_code=${categoryCode}&languageCode=${languageCode}`)
            .then(({ data }) => {
                dispatch(setPublicationClient(data?.data))
            })
            .catch(console.log)
    }
}

export const getClientBooks = (payload) => {
    const { token, categoryCode, searchKey, languageCode, page } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/client/module/publications?category_code=${categoryCode}&search_key=${searchKey}&languageCode=${languageCode}&page=${page}`)
            .then(({ data }) => {
                dispatch(setBooksClient(data?.data))
            })
            .catch(console.log)
    }
}

export const getClientFactSheet = (payload) => {
    const { token, categoryCode, searchKey, languageCode, page } = payload;
    return (dispatch) => {
        axios.get(`${baseUrl}/api/client/module/publications?category_code=${categoryCode}&search_key=${searchKey}&languageCode=${languageCode}&page=${page}`)
            .then(({ data }) => {
                dispatch(setFactSheetClient(data?.data))
            })
            .catch(console.log)
    }
}

export const getClientPressRelease = (payload) => {
    const { token, categoryCode, searchKey, languageCode, page } = payload;
    return (dispatch) => {
        axios.get(`${baseUrl}/api/client/module/publications?category_code=${categoryCode}&search_key=${searchKey}&languageCode=${languageCode}&page=${page}`)
            .then(({ data }) => {
                dispatch(setPressReleaseClient(data?.data))
            })
            .catch(console.log)
    }
}

export const sendResetPassword = (url, payload) => {
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/user/password-reset/${url}`, payload)
            .then((data) => {
                dispatch(setLoaderVisibility(false))
                return data;
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false))
            })
    }
}

export const getAdminGoals = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/goal?languageCode=${languageCode}`, { headers: { access_token: token } })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about/goal`
                }
                dispatch(setAdminGoals(res));
            })
            .catch(console.log)
    }
}

export const getAdminMission = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/mission?languageCode=${languageCode}`, { headers: { access_token: token } })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about/mission`
                }
                dispatch(setAdminMissions(res));
            })
            .catch(console.log)
    }
}

export const getAdminTeams = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/team`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about/team`
                }
                dispatch(setAdminTeams(res));
            })
            .catch(console.log)
    }
}

export const getAdminGeneral = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/about?languageCode=${languageCode}`, { headers: { access_token: token } })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about`
                }
                dispatch(setAdminGenerals(res));
            })
            .catch(console.log)
    }
}

export const getAdminSocialMediaLink = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/socialmedialink`, { headers: { access_token: token } })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about`
                }
                dispatch(setAdminSocialMediaLinks(res));
            })
            .catch(console.log)
    }
}

export const getAdminFindingStatuses = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/findingstatus`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about`
                }
                dispatch(setAdminFindingStatuses(res));
            })
            .catch(console.log)
    }
}

export const getAdminPartnertList = (payload) => {
    const { token, searchKey, page, limit, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/partnerlist?search_key=${searchKey}&page=${page}&limit=${limit}&languagecode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about`,
                    pages: data.pages
                }
                dispatch(setAdminPartnerList(res));
            })
            .catch(console.log)
    }
}

export const getPartnerGeneral = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/partnergeneral?languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/partner`
                }
                dispatch(setPartnerGenerals(res));
            })
            .catch(console.log)
    }
}

export const getHomeGeneral = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/homegeneral?languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/dashboard`
                }
                dispatch(setHomeGenerals(res));
            })
            .catch(console.log)
    }
}

export const getProgramGeneral = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/programgeneral?languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/programgeneral`
                }
                dispatch(setProgramGenerals(res));
            })
            .catch(console.log)
    }
}

export const getDonateGeneral = (payload) => {
    const { token } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/donategeneral`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/donate`
                }
                dispatch(setDonateGenerals(res));
            })
            .catch(console.log)
    }
}

export const getNewsGeneral = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/newsgeneral?languagecode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/publication/news`
                }
                dispatch(setNewsGenerals(res));
            })
            .catch(console.log)
    }
}

export const getNewsList = (payload) => {
    const { token, limit, searchKey, page, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/news?page=${page}&languageCode=${languageCode}&limit${limit}&search_key=${searchKey}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setNewsList(data));
            })
            .catch(console.log)
    }
}

export const getStoryList = (payload) => {
    const { token, limit, searchKey, page, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/stories?page=${page}&languageCode=${languageCode}&limit${limit}&search_key=${searchKey}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setStoryList(data));
            })
            .catch(console.log)
    }
}

export const getAdminCharts = (payload) => {
    const { token, languageCode } = payload;
    return (dispatch) => {
        axios
            .get(`${baseUrl}/api/general/dashboard?languageCode=${languageCode}`, {
                headers: {
                    access_token: token
                }
            })
            .then(({ data }) => {
                dispatch(setAdminCharts(data));
            })
            .catch(console.log)
    }
}

export const createAdminGoal = (payload, token) => {
    return (dispatch) => {
        axios
            .post(`${baseUrl}/api/goal`, payload, {
                headers: {
                    access_token: token
                }
            })
            .then((data) => {
                const res = {
                    lst: data.data,
                    link: `${API_BASE_URL}/cms/about/goal`
                }
                dispatch(setAdminGoals(res));
            })
            .catch(console.log)
    }
}