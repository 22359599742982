import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import YouTube, { YouTubeProps } from "react-youtube";
import { dateConverter } from "../../app/helper/helper";

export function CardVideo({ data }) {
  return (
    <Card className="w-full">
      <CardHeader floated={false} className="flex justify-center items-center">
        <div>
          <YouTube videoId={data?.videoId} className="w-full" />
        </div>
      </CardHeader>
      <CardBody className="text-center">
        <Typography color="blue-gray" className="mb-2 text-small md:text-xl">
          {data?.title}
        </Typography>
        <Typography color="blue-gray" className="font-small md:font-medium" textGradient>
          {data.date ? dateConverter(data.date) : null}
        </Typography>
      </CardBody>
    </Card>
  );
}