import React, { useEffect, useState } from "react";
import { PageDetail, OthersList, GrantsTabsWithIcon } from "../../components/index.js";
import { Typography, Button, Input } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetailProgram, setLoaderVisibility } from "../../app/actions/action-creators.js";
import {
	CloudArrowDownIcon,
	BookOpenIcon,
	CloudArrowUpIcon
} from "@heroicons/react/24/solid";
import axios from "axios";
import { API_BASE_URL } from "../../data/static-config.js";
import swal from "sweetalert";

const GrantsProgramDetail = () => {
	const [data, setData] = useState();
	const [progList, setProgList] = useState();
	const { id } = useParams();
	const dispatch = useDispatch();
	const dataStore = useSelector((state) => state?.combineReducer?.detailProgram);
	const [showModal, setShowModal] = useState(false);
	const [orgName, setOrgName] = useState();
	const [orgEmail, setOrgEmail] = useState();
	const [file, setFile] = useState();
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

	useEffect(() => {
		dispatch(getDetailProgram(id));
	}, [id]);

	const handleUpload = (e) => {
		setFile(e.target.files[0]);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(setLoaderVisibility(true));

		const reqParams = new FormData()
		reqParams.append("orgName", orgName);
		reqParams.append("orgEmail", orgEmail);
		reqParams.append("programId", id);
		reqParams.append("programName", data?.title);
		reqParams.append("languageCode", languageCode);
		reqParams.append("file", file);

		try {
			axios
				.post(`${API_BASE_URL}/api/client/module/proposal`, reqParams)
				.then(({ data }) => {
					dispatch(setLoaderVisibility(false));
					swal("Data Sent", "", "success").then((_) => {
						setShowModal(false);
						setOrgName("");
						setOrgEmail("");
					});
				})
				.catch(console.log)
		} catch (error) {
			dispatch(setLoaderVisibility(false));
			swal(error, "", "error").then((_) => {
				setShowModal(false);
				setOrgName("");
				setOrgEmail("");
			});
			console.log(error);
		}
	}

	useEffect(() => {
		if (Object.keys(dataStore).length === 0 || !dataStore) {
			dispatch(setLoaderVisibility(true))
			setTimeout(() => {
				dispatch(setLoaderVisibility(false))
			}, 5000)
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [dataStore]);

	useEffect(() => {
		setData(dataStore?.item != null ? dataStore?.item[0] : {});
		setProgList(dataStore?.others);
	}, [id, dataStore]);

	return (
		<div className="bg-gray-100">
			<div className="flex flex-col lg:gap-2 sm:flex-row">
				{showModal ? (
					<>
						<div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none" style={{ zIndex: 1002 }}>
							<div className="relative w-auto my-6 mx-auto max-w-3xl">
								<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
									<div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
										<Typography variant="h5">
											Submit Proposal
										</Typography>
									</div>
									<form onSubmit={(e) => { handleSubmit(e) }}>
										<div className="relative p-2 flex-auto">
											<div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
												<Input label="Organization/Individual Name" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={orgName} onChange={(e) => { setOrgName(e.target.value) }} required />
												<Input label="Organization/Individual Email" className="shadow appearance-none border rounded w-full py-2 px-1 text-black " value={orgEmail} onChange={(e) => { setOrgEmail(e.target.value) }} required />
												<Input label="Upload" type="file" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" onChange={handleUpload} />
											</div>
										</div>
										<div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
											<button
												className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
												type="button"
												onClick={() => setShowModal(false)}
											>
												Close
											</button>
											<Button
												className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
												type="submit"
											>
												Submit
											</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</>
				) : null}
			</div>
			<PageDetail data={data} />
			<div className="md:flex md:justify-between md:py-8 md:px-12">
				<div className="w-full w-full px-4 md:w-2/3 md:px-8">
					<div className="mb-2">
						<img src={data?.imgUrl} alt="..." className="hidden w-full h-40 md:block md:shadow md:rounded md:h-96 md:align-middle md:border-none" />
					</div>
					<div className="grid grid-cols-3 gap-4 py-8">
						<a href={data?.guidelinesUrl} target="_blank">
							<Button size="sm" variant="text" className="flex justify-center items-center h-full w-full" color="black"><CloudArrowDownIcon color="green" className="h-8 mr-2" />Guidelines</Button></a>
						<a href={data?.proposalFormatUrl} target="_blank">
							<Button size="sm" variant="text" className="flex justify-center items-center h-full w-full" color="black"><BookOpenIcon color="green" className="h-8 mr-2" />Format Proposal</Button></a>
						<Button size="sm" variant="text" className="flex justify-center items-center h-full w-full" color="black" onClick={() => setShowModal(true)}><CloudArrowUpIcon color="green" className="h-8 mr-2" />Submit Proposal</Button>
					</div>
					<GrantsTabsWithIcon dataDetail={data} />
				</div>
				<div className="w-full p-4 md:w-1/3">
					<Typography
						variant="h5"
						color="black"
						className="mb-0 opacity-80 text-justify md:pl-4"
					>
						Others programs:
					</Typography>
					<OthersList module="program" otherList={progList != null ? progList : []} />
				</div>
			</div>
		</div>
	);
};

export default GrantsProgramDetail;
