import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Select, Option, Checkbox
} from "@material-tailwind/react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../../data/static-config";
import { setLoaderVisibility } from "../../../app/actions/action-creators";

export function ModalFormCategory({ callback, id }) {
  const [label, setLabel] = useState("");
  const [name, setName] = useState();
  const [value, setValue] = useState();
  const [language, setLanguage] = useState(1033);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if(id){
      setLabel("Update Category");
      dispatch(setLoaderVisibility(true))
      try {
        axios
          .get(`${API_BASE_URL}/api/category/${id}`, { headers: {
            access_token: token
          }})
          .then(({ data }) => {
            dispatch(setLoaderVisibility(false))
            setName(data?.data?.name);
            setValue(data?.data?.value);
            setLanguage(data?.data?.languageCode);
          })
          .catch((e) => {
            console.log(e)
            dispatch(setLoaderVisibility(false))
          })
      } catch (error) {
        console.log(error);
        dispatch(setLoaderVisibility(false))
      }
    } else {
      setLabel("Add Category")
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoaderVisibility(true))
    const reqParams = {
      "name" : name,
      "value": value,
      "languageCode": language
    };

    try {
      if(id){
        axios
        .put(`${API_BASE_URL}/api/category/${id}`, reqParams, {
          headers: {
            access_token: token
          }
        })
        .then(({ data }) => {
          dispatch(setLoaderVisibility(false))
          swal("Data Updated", "", "success").then((_) => {
            callback();
          });
        })
        .catch((e) => {
          console.log(e)
          dispatch(setLoaderVisibility(false))
        })
      } else {
        axios
        .post(`${API_BASE_URL}/api/category`, reqParams, {
          headers: {
            access_token: token
          }
        })
        .then(({ data }) => {
          dispatch(setLoaderVisibility(false))
          swal("Data created", "", "success").then((_) => {
            callback();
          });
        })
        .catch((e) => {
          console.log(e)
          dispatch(setLoaderVisibility(false))
        })
      }
    } catch (error) {
      dispatch(setLoaderVisibility(false))
      console.log(error);
    }
  }

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <Typography variant="h5">
                { label }
              </Typography>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="relative p-2 flex-auto">
                <div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
                  <div className="flex gap-2">
                    <Input label="Category name" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={name} onChange={(e) => setName(e.target.value)} required />
                    <Input label="Value" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={value} onChange={(e) => setValue(e.target.value)} required />
                  </div>
                  {/* <div className="flex gap-2">
                    <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language} label="Language">
                      <Option value={1033} aria-label="English">English</Option>
                      <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                    </Select>
                  </div> */}
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={callback}
                >
                  Close
                </button>
                <Button
                  className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}