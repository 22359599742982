import { PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Button, Card, Typography } from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import { getAdminGoals, setLoaderVisibility } from "../../../app/actions/action-creators";
import { API_BASE_URL } from '../../../data/static-config.js';

export function TableWithStripedRows() {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const TABLE_HEAD = languageCode == 1033 ? ["Title", "Description", "Language"] : ["Judul", "Deskripsi", "Bahasa"];
  const TABLE_ROWS = useSelector((state) => state?.combineReducer?.adminOrganizations?.goals);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (TABLE_ROWS?.length === 0) {
      dispatch(setLoaderVisibility(true))
    } else {
      dispatch(setLoaderVisibility(false))
    }
  }, [TABLE_ROWS]);

  useEffect(() => {
    dispatch(getAdminGoals({ token, languageCode }));
  }, [isNeedRefresh, languageCode])

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true))
          axios.delete(`${API_BASE_URL}/api/goal/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false))
            setIsNeedRefresh(!isNeedRefresh)
          }).catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
        }
      });
  };

  const handleUpdate = (id) => {
    navigate(`/cms/about/goal/update/${id}`);
  };

  return (
    <div className="p-2">
      <Card className="w-full rounded-none">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-4">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
              <th className="border-y border-gray-400 bg-blue-gray-50/50 p-4">
                <ButtonAdd />
              </th>
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(({ id, title, description, languageCode }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {title}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {description}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {languageCode === 1033 ? "English" : "Bahasa Indonesia"}
                  </Typography>
                </td>
                <td className="p-4 flex items-center justify-center gap-4">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-4" color="white" />
                    {
                      languageCode == 1033 ? "Update" : "Ubah"
                    }
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-4" />
                    {
                      languageCode == 1033 ? "Delete" : "Hapus"
                    }
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
}

function ButtonAdd() {
  const navigate = useNavigate()
  const handleAdd = () => {
    navigate("/cms/about/goal/add");
  }
  return (
    <Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-full" color="black" onClick={handleAdd}>
      <PlusCircleIcon className="w-4" />
      Add
    </Button>
  )
}