import React, { useEffect, useState } from "react";
import { Button, Card, Typography } from "@material-tailwind/react";
import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { getAdminCategories, getAdminSocialMediaLink } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import { ModalFormSocialMediaLink } from "../Form/ModalFormSocialMediaLink.js";
import { categoryCodeEnum } from "../../../data/enum.js";

export function TableSocialLinks() {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const TABLE_HEAD = ["Name", "Link", "Category"];
  const TABLE_ROWS = useSelector((state) => state?.combineReducer?.adminOrganizations?.socialmedialinks?.lst);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminSocialMediaLink({ token }));
  }, [isNeedRefresh]);

  const handleCallback = () => {
    setId(null)
    setShowModal(false);
    setIsNeedRefresh(!isNeedRefresh);
  }

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete(`${API_BASE_URL}/api/socialmedialink/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            setIsNeedRefresh(!isNeedRefresh)
          })
        }
      });
  };

  const handleUpdate = (id) => {
    setId(id);
    setShowModal(true)
  };

  return (
    <div className="p-2 mt-3">
      {showModal ? (
        <ModalFormSocialMediaLink callback={handleCallback} id={id} />
      ) : null}
      <Card className="w-full rounded-none">
        <Typography variant="h5">Social Media Link</Typography>
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-4">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
              <th className="border-y border-gray-400 bg-blue-gray-50/50 p-4">
                <div className="flex items-end justify-center gap-2 w-full"><Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-1/2" color="black" onClick={() => setShowModal(true)}>
                  <PlusCircleIcon className="w-4" />
                  {
                    languageCode == 1033 ? "Add" : "Tambah"
                  }
                </Button></div></th>
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(({ id, name, categoryCode, link, }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {name}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {categoryCodeEnum.filter(({ name, code }) => categoryCode == code)[0].name}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {link}
                  </Typography>
                </td>
                <td className="p-4 h-full flex items-center justify-center">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-4" color="white" />
                    Update
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-4" />
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};