import React, { useEffect, useRef, useState } from "react";
import { BannerPage } from "../../components/index.js";
import {
	Card,
	Input,
	Checkbox,
	Button,
	Typography,
} from "@material-tailwind/react";
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import { API_BASE_URL, MIDTRANS_SNAP_URL, MIDTRANS_CLIENT_KEY } from "../../data/static-config.js"
import { useDispatch, useSelector } from "react-redux";
import { getClientDonate, setLoaderVisibility } from "../../app/actions/action-creators.js";
import swal from 'sweetalert';

const Donate = () => {
	const data = useSelector((state) => state?.combineReducer?.clientDonate?.lst);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const dispatch = useDispatch();
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [nominal, setNominal] = useState(0);
	const [token, setToken] = useState("");

	useEffect(() => {
		if (!data) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [data]);

	useEffect(() => {
		dispatch(getClientDonate({ languageCode: languageCode }))
	}, [languageCode]);

	const midtransUrl = MIDTRANS_SNAP_URL;
	const baseApiUrl = API_BASE_URL;

	const processpayment = async () => {
		if(nominal > 100){
			const data = {
				name: name ? name : "No Name",
				email: email ? email : "no_email@mail.com",
				phone: phone ? phone : "080008000800",
				order_id: new Date().valueOf(),
				gross_amount: nominal
			}
	
			const config = {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*"
				}
			}
			axios.post(baseApiUrl + "/api/payment/process-transaction", data, config).then((response) => {
				setToken(response.data.token)
			}).catch((error) => {
				console.log(error);
			});
		} else {
			swal("Nominal Invalid", "", "error");
		}
	};

	useEffect(() => {
		if (token) {
			window.snap.pay(token, {
				onSuccess: (result) => {
					localStorage.setItem("payment", JSON.stringify(result))
					setToken("")
				},
				onPending: (result) => {
					localStorage.setItem("payment", JSON.stringify(result))
					setToken("")
				},
				onError: (error) => {
					console.log(error)
					setToken("")
				},
				onClose: () => {
					console.log("Belum Menyelesaikan pembayaran");
					setToken("")
				}
			})

			setName("");
			setEmail("");
			setPhone("");
			setNominal(0);
		}
	}, [token]);

	useEffect(() => {
		let scriptTag = document.createElement("script");
		scriptTag.src = midtransUrl;

		const midtransClientKey = MIDTRANS_CLIENT_KEY;

		scriptTag.setAttribute("data-client-key", midtransClientKey);

		document.body.appendChild(scriptTag);

		return () => {
			document.body.removeChild(scriptTag);
		}
	}, []);

	return (
		<div className="bg-gray-100">
			<BannerPage dataComp={data ? data[0] : null} />
			<div className="w-full px-5 md:px-20 py-10 grid grid-cols-1 gap-4 md:grid-cols-2">
				<div className="mb-1 flex flex-col gap-3 md:gap-6">
				{data ? <div className="text-justify md:pr-8 text-black" dangerouslySetInnerHTML={{__html: data[0].narrative}}></div> : null}
				</div>
				<div className="mb-1 flex flex-col gap-6 h-full">
					<Typography variant="h6" color="blue-gray" className="-mb-3">
						Nama
					</Typography>
					<Input
						size="lg"
						placeholder="name"
						className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
						labelProps={{
							className: "before:content-none after:content-none",
						}}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<Typography variant="h6" color="blue-gray" className="-mb-3">
						Email
					</Typography>
					<Input
						size="lg"
						type="email"
						placeholder="name@mail.com"
						className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
						labelProps={{
							className: "before:content-none after:content-none",
						}}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<Typography variant="h6" color="blue-gray" className="-mb-3">
						No. Handphone
					</Typography>
					<Input
						type="text"
						size="lg"
						placeholder="0800-0000-0000"
						className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
						labelProps={{
							className: "before:content-none after:content-none",
						}}
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
					<Typography variant="h6" color="blue-gray" className="-mb-3">
						Masukan Jumlah Donasi Anda:
					</Typography>
					<CurrencyInput
						id="input-nominal"
						name="input-name"
						placeholder="Please enter a number"
						defaultValue={nominal}
						decimalsLimit={2}
						onValueChange={(value, name) => setNominal(value)}
						value={nominal}
						size="xl"
						className="p-2 bg-gray-50 border-gray-900 focus:border-t-gray-900"
						prefix="Rp."
					/>
					<div className="grid grid-cols-2 md:grid-cols-3 gap-2">
						<Card className="p-6 hover:cursor-pointer bg-gradient-to-l from-green-900 to-green-600" onClick={() => setNominal(10000)}><Typography className="font-bold" color="white">Rp.10,000</Typography></Card>
						<Card className="p-6 hover:cursor-pointer bg-gradient-to-l from-green-900 to-green-600" color="green" onClick={() => setNominal(50000)}><Typography className="font-bold" color="white">Rp.50,000</Typography></Card>
						<Card className="p-6 hover:cursor-pointer bg-gradient-to-l from-green-900 to-green-600" color="green" onClick={() => setNominal(100000)}><Typography className="font-bold" color="white">Rp.100,000</Typography></Card>
					</div>
					<div className="flex justify-end items-end">
						<Button onClick={processpayment}>Lanjut</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Donate;
