import React, { useEffect, useState } from "react";
import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { getAdminPartnertList, setIsNeedRefresh, setLoaderVisibility } from "../../../app/actions/action-creators.js";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';

export function TablePartnerList({ callback }) {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const TABLE_HEAD = languageCode == 1033 ? ["Name", "Province", "Latitude", "Longitude", ""] : ["Nama", "Provinsi", "Latitude", "Longitude", ""];
  const TABLE_ROWS = useSelector((state) => state?.combineReducer?.adminOrganizations?.partnerlist?.lst);
  const pages = useSelector((state) => state?.combineReducer?.adminOrganizations?.partnerlist?.pages);
  const [pageNum, setPageNum] = useState(1);
  const pageTotal = pages?.pageTotal;
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const isNeedRefresh = useSelector((state) => state?.combineReducer?.adminOrganizations?.isNeedRefresh);
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    if (TABLE_ROWS?.length === 0) {
      dispatch(setLoaderVisibility(true))
    } else {
      dispatch(setLoaderVisibility(false))
    }
  }, [TABLE_ROWS]);

  useEffect(() => {
    let params = {
      searchKey: searchKey,
      page: pageNum,
      token: token,
      limit: 10,
      languageCode: languageCode
    }
    dispatch(getAdminPartnertList(params));
  }, [isNeedRefresh, pageNum, searchKey, languageCode]);

  const handlePageButton = (operator) => {
    setPageNum(pageNum + operator);
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true))
          axios.delete(`${API_BASE_URL}/api/partnerlist/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false))
            dispatch(setIsNeedRefresh(!isNeedRefresh))
          }).catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
        }
      });
  };

  const handleUpdate = (id) => {
    callback(true, id);
  };

  return (
    <div className="w-full">
      <div className="flex justify-end w-full pb-4 pr-5 gap-6">
        <div className="w-1/2 relative text-gray-600 flex justify-end">
          <input className="border-2 w-full md:w-1/2 border-gray-300 bg-white h-10 px-5 pr-8 md:pr-16 rounded-lg text-sm focus:outline-none"
            type="search" name="search" placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
          <button type="submit" className="absolute right-0 top-0 mr-4 h-full flex items-center">
            <svg className="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
              version="1.1" id="Capa_1" x="0px" y="0px"
              viewBox="0 0 56.966 56.966" space="preserve"
              width="512px" height="512px">
              <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </svg>
          </button>
        </div>
        <Button variant="gradient" size="sm" className="h-full flex items-center justify-center gap-2 w-1/5 md:w-36" color="black" onClick={() => callback(true)}>
          <PlusCircleIcon className="w-4" />
          {
            languageCode == 1033 ? "Add Data" : "Tambah Data"
          }
        </Button>
      </div>
      <Card className="w-full rounded-none">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-2">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(({ id, name, province, latitude, longitude }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {province}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {latitude}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {longitude}
                  </Typography>
                </td>
                <td className="p-4 h-full flex items-center justify-center">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-1 m-1" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-3" color="white" />
                    {
                      languageCode == 1033 ? "Update" : "Ubah"
                    }
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-1 m-1" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-3" />
                    {
                      languageCode == 1033 ? "Delete" : "Hapus"
                    }
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50">
          <Typography variant="small" color="blue-gray" className="font-normal">
            {pages?.title}
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
              {pages?.prev}
            </Button>
            <Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
              {pages?.next}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}