import { SET_ADMIN_TOKEN, SET_AUTHENTICATION_ADMIN } from "../../actions/action-types.js";

let initState = {
    auth: false,
    token: null,
    msg: null,
    email: ""
};

export default function adminToken(state = initState, { type, payload }) {
    switch (type) {
        case SET_ADMIN_TOKEN:
            return (state = { ...state, token: payload });
        case SET_AUTHENTICATION_ADMIN:
            return (state = { ...state, token: payload.token, auth: payload.auth, msg: payload.msg, email: payload.email});
        default:
            return state
    }
}