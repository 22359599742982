import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  Select, Option
} from "@material-tailwind/react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, listProvince } from "../../../data/static-config";
import { setLoaderVisibility } from "../../../app/actions/action-creators";

export function ModalFormTimberTrace({ callback, id, categoryCodeArr }) {
  const [label, setLabel] = useState("");
  const [name, setName] = useState();
  const [compName, setCompName] = useState();
  const [skNo, setSKNo] = useState();
  const [ownerName, setOwnerName] = useState();
  const [realizationNo, setRealizationNo] = useState();
  const [originMaterial, setoriginMaterial] = useState();
  const [originDescription, setoriginDescription] = useState();
  const [originProvinsi, setoriginProvince] = useState();
  const [categoryCode, setCategoryCode] = useState();
  const [selectedProvince, setSelectedProvince] = useState("");
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setLabel("Ubah Data Peredaran Kayu");
      try {
        axios
          .get(`${API_BASE_URL}/api/timbertrace/${id}`, {
            headers: {
              access_token: token
            }
          })
          .then(({ data }) => {
            setSKNo(data?.data?.SKNo);
            setCompName(data?.data?.companyName);
            setCategoryCode(data?.data?.metercubicno);
            setoriginDescription(data?.data?.originDescription);
            setoriginMaterial(data?.data?.originMaterial);
            setoriginProvince(data?.data?.originProvince);
            setOwnerName(data?.data?.ownerName);
            setSelectedProvince(data?.data?.province);
            setRealizationNo(data?.data?.realizationNo);
          })
          .catch(console.log)
      } catch (error) {
        console.log(error);
      }
    } else {
      setLabel("Tambah Data Peredaran Kayu")
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoaderVisibility(true));
    const reqParams = {
      "province" : selectedProvince, 
      "companyName" : compName,
      "SKNo" : skNo,
      "ownerName" : ownerName,
      "realizationNo" : realizationNo,
      "originMaterial" : originMaterial,
      "originDescription" : originDescription,
      "originProvince" : originProvinsi,
      "metercubicno" : categoryCode
    };

    try {
      if (id) {
        axios
          .put(`${API_BASE_URL}/api/timbertrace/${id}`, reqParams, {
            headers: {
              access_token: token
            }
          })
          .then(({ data }) => {
            dispatch(setLoaderVisibility(false));
            swal("Data Updated", "", "success").then((_) => {
              callback();
            });
          })
          .catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false));
          })
      } else {
        axios
          .post(`${API_BASE_URL}/api/timbertrace`, reqParams, {
            headers: {
              access_token: token
            }
          })
          .then(({ data }) => {
            dispatch(setLoaderVisibility(false));
            swal("Data created", "", "success").then((_) => {
              callback();
            });
          })
          .catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false));
          })
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoaderVisibility(false));
    }
  }

  return (
    <>
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                <Typography variant="h5">
                  {label}
                </Typography>
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="relative p-2 flex-auto">
                  <div className="shadow-md rounded px-8 pt-6 pb-8 w-full flex flex-col gap-4">
                    <div className="flex gap-2">
                      {
                        listProvince ?
                          <Select label="Select Province" value={selectedProvince} onChange={(e) => setSelectedProvince(e)}>
                            {
                              listProvince?.map(({ provinsi }, index) => (
                                <Option key={index} value={provinsi}>{provinsi}</Option>
                              ))
                            }
                          </Select> : null
                      }
                      <Input label="Nama Perusahaan" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={compName} onChange={(e) => setCompName(e.target.value)} />
                    </div>
                    <div className="flex gap-2">
                      <Input label="Nama Pemilik" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={ownerName} onChange={(e) => setOwnerName(e.target.value)} />
                      <Input label="Nomor SK" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={skNo} onChange={(e) => setSKNo(e.target.value)} />
                    </div>
                    <div className="flex gap-2">
                      <Input label="Realisasi Bahan Baku" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={realizationNo} onChange={(e) => setRealizationNo(e.target.value)} />
                      <Input label="Asal-usul Bahan Baku" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={originMaterial} onChange={(e) => setoriginMaterial(e.target.value)} />
                    </div>
                    <div className="flex gap-2">
                      <Input label="Keterangan Asal Usul Bahan Baku" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={originDescription} onChange={(e) => setoriginDescription(e.target.value)} />
                    </div>
                    <div className="flex gap-2">
                      <Input label="Provinsi Asal Bahan Baku" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" value={originProvinsi} onChange={(e) => setoriginProvince(e.target.value)} />
                      {
                        categoryCodeArr ?
                          <Select label="Select Category" value={categoryCode} onChange={(e) => setCategoryCode(e)}>
                            {
                              categoryCodeArr?.map(({ id, fullname, metercubicno }) => (
                                <Option key={id} value={metercubicno}>{fullname}</Option>
                              ))
                            }
                          </Select> : null
                      }
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={callback}
                  >
                    Close
                  </button>
                  <Button
                    className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </>
  )
}