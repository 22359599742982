import React, { useEffect, useState } from "react";
import { PageDetail, OthersList } from "../../../components/index.js";
import { Typography } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetailNews, getDetailStory, setLoaderVisibility } from "../../../app/actions/action-creators.js";

const StoryDetail = () => {
	const [data, setData] = useState();
	const [newsList, setNewsList] = useState();
	const { id } = useParams();
	const dispatch = useDispatch();
	const dataStore = useSelector((state) => state?.combineReducer?.detailStory);
	const clientLang = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

	useEffect(() => {
		if (Object.keys(dataStore).length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [dataStore]);

	useEffect(() => {
		dispatch(getDetailStory(id, clientLang));
	}, [id]);

	useEffect(() => {
		setData(dataStore?.item != null ? dataStore?.item[0] : []);
		setNewsList(dataStore?.others);
	}, [id, dataStore]);

	return (
		<div className="bg-gray-100">
			<PageDetail data={data} />
			<div className="py-4 md:flex md:justify-between md:py-8 md:px-12">
				<div className="w-full w-full px-4 md:w-2/3 md:px-8">
					<div
						color="black"
						className="mb-0 opacity-80 text-justify "
					>
						{data?.description != null ? <div dangerouslySetInnerHTML={{__html: data?.description}}></div> : ""}
					</div>
				</div>
				<div className="w-full p-4 md:w-1/3">
					<Typography
						variant="h5"
						color="black"
						className="mb-0 opacity-80 text-justify md:pl-4"
					>
						Others Stories:
					</Typography>
					<OthersList module="story" otherList={newsList} />
				</div>
			</div>
		</div>
	);
};

export default StoryDetail;
