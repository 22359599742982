import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { CardProgram } from "../client/CardProgram";
import { CardGrantsProgram } from "../client/CardGrantsProgram";

export function MainProgramsList({ progList }) {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 p-2">
            {
                progList?.map((item) => (
                    item.isgrants ? <CardGrantsProgram item={item} /> : <CardProgram item={item} />
                ))
            }
        </div>
    );
}