import React, { useEffect, useState } from "react";
import { Typography, Button } from "@material-tailwind/react";
import parse from "html-react-parser";

export function BannerPage({ dataComp }) {
  return (
    <>
      <div className="relative h-full w-full">
        <img
          src={dataComp?.imgBanner}
          alt="image 1"
          className="w-full h-80 md:h-96 object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75 pt-28">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="small"
              className="mb-4 font-bold text-ifmpallet3"
            >
              {dataComp?.hashtag}
            </Typography>
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-2xl md:text-4xl lg:text-5xl text-center"
            >
              {dataComp?.title}
            </Typography>
            <Typography
              variant="small"
              color="white"
              className="mb-2 opacity-80 text-center line-clamp-4"
            >
              {dataComp?.subtitle ? parse(dataComp.subtitle) : ""}
            </Typography>
          </div>
          <Typography
            variant="small"
            color="white"
            className="mb-12 opacity-80 text-center md:p-8 hidden md:block"
          >
            {dataComp?.description ? parse(dataComp.description) : ""}
          </Typography>
        </div>
      </div>
    </>
  );
}