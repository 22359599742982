import React, { useState } from "react";
import {
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { FolderPlusIcon } from "@heroicons/react/24/solid";
import {
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
} from "@material-tailwind/react";
import axios from "axios";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { API_BASE_URL } from '../../../data/static-config.js';
import { useNavigate } from "react-router-dom";
import { dateConverter } from "../../../app/helper/helper.js";

export function TableStoryListAdmin({ LABEL, keyDown, REFRESH_TABLE }) {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const storyState = useSelector((state) => state?.combineReducer?.adminOrganizations?.storiesList);
  const TABLE_ROWS = storyState?.data;
  const [searchKey, setSearchKey] = useState("");
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const navigate = useNavigate();
  const TABLE_HEAD = languageCode == 1033 ? ["Title", "Publish Date", "Publisher", "Author", ""] : ["Judul", "Tanggal Terbit", "Penerbit", "Penulis", ""];

  const search = (event) => {
    setSearchKey(event.target.value);
  }

  const handleKeyDown = () => {
    keyDown(searchKey);
  };

  const handleUpdate = (id) => {
    navigate(`/cms/stories/update/${id}`);
  }

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          axios.delete(`${API_BASE_URL}/api/stories/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            REFRESH_TABLE();
          })
        }
      });
  };

  const handleAdd = () => {
    navigate("/cms/stories/add");
  }

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col items-center justify-between md:flex-row">
          <Button className="flex items-center gap-2" size="sm" onClick={handleAdd}>
            <FolderPlusIcon strokeWidth={2} className="h-4 w-4" /> {LABEL}
          </Button>
          <div className="relative flex w-full gap-2 md:w-max pt-2">
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />} value={searchKey}
                onChange={(e) => search(e)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-2">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-gray-400 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(
              ({ id, imgBanner, title, publishDate, publisher, author }, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={id}>
                    <td className={classes} style={{ maxWidth: "400px" }}>
                      <div className="flex items-center gap-3">
                        <img src={imgBanner} alt={title} size="sm" className="w-20 rounded" />
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {title}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes} style={{ maxWidth: "200px" }}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {publishDate ? dateConverter(publishDate) : null}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {publisher}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {author}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Button color="blue-gray" variant="solid" size="sm" className="flex w-full justify-center items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                        <PencilIcon className="w-4" color="white" />
                        {
                          languageCode == 1033 ? "Update" : "Ubah"
                        }
                      </Button>
                      <Button color="red" variant="gradient" size="sm" className="flex w-full justify-center items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                        <TrashIcon className="w-4" />
                      {
                        languageCode == 1033 ? "Delete" : "Hapus"
                      }
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </CardBody>
    </>
  );
}