import React, { useEffect, useState } from "react";
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCategories, setAdminGenerals, setLoaderVisibility, setPartnerGenerals } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NavbarCMS } from "../NavbarCMS";

export function FormPublicationGeneral() {
    const { pubGenId } = useParams();
    const [lable, setLabel] = useState({});
    const [title, setTitle] = useState();
    const [subtitle, setSubtitle] = useState();
    const [hashtag, setHashtag] = useState();
    const [description, setDescription] = useState();
    const [language, setLanguage] = useState(1033);
    const [file, setFile] = useState();
    const [imgPreview, setImgPreview] = useState();
    const [generateImg, setGenImg] = useState();
    const [categoryCode, setCategoryCode] = useState();

    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
    const categories = useSelector((state) => state?.combineReducer?.adminOrganizations?.categories);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAdminCategories({ token }))
    }, []);

    useEffect(() => {
        if (pubGenId) {
            dispatch(setLoaderVisibility(true));
            setLabel({
                title: "Update Publication Page Data",
                subtitle: "Enter your data details for update data",
                buttonLabel: "Update Data"
            })
            axios.get(`${API_BASE_URL}/api/publicationgeneral/${pubGenId}`, { headers: { access_token: token } }).then(({ data }) => {
                dispatch(setLoaderVisibility(false));
                setHashtag(data.data.hashtag);
                setTitle(data.data.title);
                setSubtitle(data.data.subtitle);
                setDescription(data.data.description);
                setImgPreview(data.data.imgBanner);
                setLanguage(data.data.languageCode);
                setCategoryCode(data.data.categoryCode);
                generateImgPreview();
            }).catch(console.error)
        } else {
            setLabel({
                title: "Add Publication Page Data",
                subtitle: "Enter your data details for add data",
                buttonLabel: "Add Data"
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true));
        const reqParams = new FormData()
        reqParams.append("title", title);
        reqParams.append("subtitle", subtitle);
        reqParams.append("hashtag", hashtag);
        reqParams.append("description", description);
        reqParams.append("languageCode", language);
        reqParams.append("categoryCode", categoryCode);
        reqParams.append("file", file);

        if (pubGenId) {
            axios
                .put(`${API_BASE_URL}/api/publicationgeneral/${pubGenId}`, reqParams, {
                    headers: {
                        access_token: token
                    }
                })
                .then(({ data }) => {
                    dispatch(setLoaderVisibility(false));
                    swal("Data updated", "", "success").then((_) => {
                        navigate("/cms/publication/general");
                    });
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setLoaderVisibility(false));
                })
        } else {
            axios
                .post(`${API_BASE_URL}/api/publicationgeneral`, reqParams, {
                    headers: {
                        access_token: token
                    }
                })
                .then(({ data }) => {
                    dispatch(setLoaderVisibility(false));
                    swal("Data created", "", "success").then((_) => {
                        navigate("/cms/publication/general");
                    });
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setLoaderVisibility(false));
                })
        }
    };

    function handleChangeFile(e) {
        setFile(e.target.files[0]);
        setImgPreview(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
        generateImgPreview();
    }

    const generateImgPreview = () => {
        imgPreview ? setGenImg("w-full h-80 rounded") : setGenImg("");
    }

    return (
        <>
            <NavbarCMS module={lable?.title} />
            <div className="w-full">
                <Card color="transparent" shadow={false} className="h-full w-full flex justify-center items-center p-4">
                    <Typography variant="h4" color="blue-gray">
                        {lable?.title}
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        {lable.subtitle}
                    </Typography>
                    <form className="mt-8 mb-2" onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-1 flex flex-col gap-6">
                            <div className="flex gap-2">
                                <Input
                                    label="Title"
                                    size="lg"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="flex gap-2">
                                <Select value={categoryCode} onChange={(e) => setCategoryCode(e)} defaultValue={categoryCode} label="Category">
                                    {
                                        categories?.map(({ id, name, value }, index) => (
                                            <Option value={value} key={id} aria-label={name}>{name}</Option>
                                        ))
                                    }
                                </Select>
                                <Input
                                    size="lg"
                                    label="#hashtag"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={hashtag}
                                    onChange={(e) => setHashtag(e.target.value)}
                                />
                            </div>
                            <div className="flex gap-2">
                                <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language} label="Language">
                                    <Option value={1033} aria-label="English">English</Option>
                                    <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                                </Select>
                                <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="file" label="Image"
                                        className="pr-20" onChange={handleChangeFile}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full justify-center items-center">
                                <img src={imgPreview} className={generateImg} />
                            </div>
                            <div className="">
                                <Typography variant="h6" color="blue-gray" className="mb-1">
                                    Subtitle
                                </Typography>
                                <ReactQuill theme="snow" value={subtitle} onChange={setSubtitle} />
                            </div>
                            <div className="">
                                <Typography variant="h6" color="blue-gray" className="mb-1">
                                    Description
                                </Typography>
                                <ReactQuill theme="snow" value={description} onChange={setDescription} />
                            </div>
                        </div>
                        <Button className="mt-6" fullWidth type="submit">
                            {lable?.buttonLabel}
                        </Button>
                    </form>
                </Card>
            </div>
        </>
    );
}