import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  BookOpenIcon,
  ClipboardDocumentIcon,
  GlobeAmericasIcon,
  NewspaperIcon,
  RectangleGroupIcon,
  VideoCameraIcon,
  CheckBadgeIcon
} from "@heroicons/react/24/solid";
import {
  Button,
  Collapse,
  IconButton,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SelectLanguage } from "./SelectLanguange";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'

export function StickyNavbar() {
  const [openNav, setOpenNav] = useState(false);
  const [label, setLabel] = useState();
  const logo = useSelector((state) => state?.combineReducer?.clientFooter?.logo);
  const clientLang = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const labelID = useSelector((state) => state?.combineReducer?.clientNavbar?.id);
  const labelEN = useSelector((state) => state?.combineReducer?.clientNavbar?.en);
  const [offset, setOffset] = useState(0);
  const [classCustom, setClassCustome] = useState("fixed top-0 max-w-full rounded-none bg-ifmpallet2 transition ease-in-out delay-150");
  const [heightCus, setHeightCus] = useState("h-20 transition-all transition-width delay-150 duration-500 w-46 rounded-br-[100rem] pr-8");
  const location = useLocation();

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (location?.pathname !== "/timbertraceability") {
      if (offset > 50) {
        setClassCustome("fixed top-0 max-w-full rounded-none bg-ifmpallet2 transition ease-linear delay-400");
        setHeightCus("h-20 transition-all duration-400 ease-linear w-46 rounded-br-[100rem] pr-8");
      } else {
        setClassCustome("fixed top-0 max-w-full rounded-none bg-ifmpallet2 md:bg-transparent transition ease-linear delay-400");
        setHeightCus("h-20 md:h-24 transition-all duration-400 ease-linear w-46 rounded-br-[100rem] pr-12");
      }
    } else {
      setClassCustome("fixed top-0 max-w-full rounded-none bg-ifmpallet2 transition ease-linear delay-400");
      setHeightCus("h-20 transition-all duration-400 ease-linear w-46 rounded-br-[100rem] pr-8");
    }
  }, [offset, location]);

  useEffect(() => {
    clientLang == 1045 ? setLabel(labelID) : setLabel(labelEN);
  }, [clientLang]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="flex flex-col px-4 mb-2 mt-2 gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        className="p-1 w-full font-normal rounded-lg hover:opacity-70 px-4"
      >
        <Link to="/" className="flex items-center">{label?.home}</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-1 font-normal rounded-lg hover:opacity-70 px-4"
      >
        <Link to="timbertraceability" className="flex items-center">{label?.timbertraceability != null ? parse(label?.timbertraceability) : ""}</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-1 font-normal rounded-lg hover:opacity-70 px-4"
      >
        <Link to="program" className="flex items-center">{label?.program}</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-1 font-normal rounded-lg hover:opacity-70 px-4"
      >
        <Link to="partner" className="flex items-center">{label?.partner}</Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        className="p-1 font-normal rounded-lg hover:opacity-70 px-4"
      >
        <Link to="about" className="flex items-center">{label?.about}</Link>
      </Typography>
      <NavListMenu title={label?.publication} lang={clientLang} />
      <SelectLanguage label={label?.lang} />
      <Link to="/donate">
        <Button size="sm" fullWidth className="flex gap-2 px-4 hover:opacity-70 bg-ifmpallet3 text-ifmpallet1">
        <FontAwesomeIcon icon={faHandHoldingDollar} bounce /><span>{label?.donate}</span>
        </Button></Link>
    </ul>
  );

  return (
    <Navbar className={classCustom} blurred={false} style={{ borderStyle: "none", paddingTop: "0", paddingLeft: "0", paddingBottom: "0", zIndex: 1001 }}>
      <div className="flex items-center justify-between text-white-900">
        <div className={`object-center bg-gray-100 px-2 py-1 flex items-center justify-center ${heightCus}`}>
          {
            logo != null ? <img src={logo[0].logoUrl} alt="Logo" className="h-2/3 object-cover pl-1" /> : null
          }
        </div>
        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">{navList}</div>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openNav} className="w-full h-full">
        {navList}
      </Collapse>
    </Navbar>
  );
}

const navListMenuItems = [
  {
    title: "News",
    titleId: "Berita",
    link: "news",
    description: "Find the news from IFM.",
    descriptionId: "Temukan berita terbaru dari IFM",
    icon: NewspaperIcon,
  },
  {
    title: "Story",
    titleId: "Cerita Pemantau",
    link: "story",
    description: "Find the independent monitor story",
    descriptionId: "Temukan cerita dari para pemantau disini",
    icon: NewspaperIcon,
  },
  {
    title: "Books",
    titleId: "Buku",
    link: "books",
    description: "Find the books from IFM.",
    descriptionId: "Temukan buku-buku dari IFM", 
    icon: BookOpenIcon,
  }, {
    title: "Fact Sheet",
    titleId: "Lembar Fakta",
    link: "factsheet",
    description: "Find the fact sheet of IFM.",
    descriptionId: "Temukan lembar fakta IFM",
    icon: ClipboardDocumentIcon,
  }, {
    title: "Press Release",
    titleId: "Media Rilis",
    link: "pressrelease",
    description: "Find the press release of IFM.",
    descriptionId: "Temukan media rilis dari IFM",
    icon: GlobeAmericasIcon,
  },
  {
    title: "Standard Operating Procedure (SOP)",
    titleId: "Standar Operasional Prosedur (SOP)",
    link: "sop",
    description: "Find SOP of IFM.",
    descriptionId: "Temukan SOP dari IFM",
    icon: CheckBadgeIcon,
  },
  {
    title: "Reports",
    titleId: "Laporan",
    link: "reports",
    description: "Find all of Reports",
    descriptionId: "Temukan semua laporan IFM",
    icon: RectangleGroupIcon,
  },
  {
    title: "Videos",
    titleId: "Video",
    link: "videos",
    description: "Find the videos of IFM",
    descriptionId: "Temukan semua video dari IFM",
    icon: VideoCameraIcon,
  }
];

function NavListMenu({ title, lang }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const renderItems = navListMenuItems.map(
    ({ icon, title, titleId, descriptionId, description, link }, key) => (
      <Link to={link} key={key}>
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2">
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 w-6 text-ifmpallet1",
              color: "black",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              className="flex items-center text-sm font-bold"
            >
              {lang == 1033 ? title : titleId}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-xs !font-medium"
            >
              {lang == 1033 ? description : descriptionId}
            </Typography>
          </div>
        </MenuItem>
      </Link>
    ),
  );

  return (
    <div>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              <Typography
                as="li"
                variant="small"
                className="flex items-center gap-2 py-2 pr-4 font-medium"
              >
                <Link to="/" className="flex items-center">{title}</Link>
              </Typography>
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                  }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                  }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </div>
  );
}