import { SET_ADMIN_GOALS, SET_ADMIN_GOAL_DETAIL, SET_ADMIN_MISIONS, SET_ADMIN_TEAMS, SET_ADMIN_GENERALS, SET_ADMIN_PARTNERS, SET_FINDING_STATUSES, SET_ISNEED_REFRESH, SET_ADMIN_FINDING_PARTNERS, SET_ADMIN_NEWS_GENERAL, SET_ADMIN_NEWS_LIST, SET_ADMIN_CATEGORIES, SET_ADMIN_PUBLICATIONS, SET_ADMIN_PUBLICATION_DOC_GENERAL, SET_ADMIN_VIDEOS, SET_ADMIN_PROGRAM_GENERAL, SET_ADMIN_PROGRAM_LIST, SET_ADMIN_HOME_GENERAL, SET_ADMIN_TIMBER_TRACING, SET_SOCIAL_MEDIA_LINK, SET_ADMIN_DONATE_GENERAL, SET_ADMIN_STORY_LIST, SET_ADMIN_PARTNER_LIST, SET_GENERAL_SETUP, SET_ADMIN_PROPOSAL } from "../../actions/action-types.js";

let initState = {
    goals: [],
    goal: null,
    redirect: null,
    missions: [],
    mission: null,
    teams: [],
    team: null,
    generals: [],
    partners: [],
    findingStatuses: [],
    isNeedRefresh: true,
    findingPartners: {},
    news: [],
    newsList: [],
    storiesList: null,
    categories: [],
    publications: [],
    publicationdocgeneral: [],
    videoList: [],
    programGeneral: [],
    programlist: [],
    homeGeneral: [],
    timberTracings: [],
    socialmedialinks: [],
    donateGeneral: [],
    partnerlist: [],
    genset: [],
    proposals: []
};

export default function adminOrganizations(state = initState, { type, payload }) {
    switch (type) {
        case SET_ADMIN_GOALS:
            return (state = { ...state, goals: payload.lst, redirect: payload.link });
        case SET_ADMIN_GOAL_DETAIL:
            return (state = { ...state, goal: payload });
        case SET_ADMIN_MISIONS:
            return (state = { ...state, missions: payload.lst });
        case SET_ADMIN_TEAMS:
            return (state = { ...state, teams: payload.lst });
        case SET_ADMIN_GENERALS:
            return (state = { ...state, generals: payload.lst });
        case SET_ADMIN_PARTNERS:
            return (state = { ...state, partners: payload.lst });
        case SET_FINDING_STATUSES:
            return (state = { ...state, findingStatuses: payload.lst });
        case SET_ISNEED_REFRESH:
            return (state = { ...state, isNeedRefresh: payload });
        case SET_ADMIN_FINDING_PARTNERS:
            return (state = { ...state, findingPartners: payload });
        case SET_ADMIN_NEWS_GENERAL:
            return (state = { ...state, news: payload });
        case SET_ADMIN_NEWS_LIST:
            return (state = { ...state, newsList: payload });
        case SET_ADMIN_CATEGORIES:
            return (state = { ...state, categories: payload });
        case SET_ADMIN_PUBLICATIONS:
            return (state = { ...state, publications: payload });
        case SET_ADMIN_PUBLICATION_DOC_GENERAL:
            return (state = { ...state, publicationdocgeneral: payload });
        case SET_ADMIN_VIDEOS:
            return (state = { ...state, videoList: payload });
        case SET_ADMIN_PROGRAM_GENERAL:
            return (state = { ...state, programGeneral: payload.lst });
        case SET_ADMIN_PROGRAM_LIST:
            return (state = { ...state, programlist: payload });
        case SET_ADMIN_HOME_GENERAL:
            return (state = { ...state, homeGeneral: payload });
        case SET_ADMIN_TIMBER_TRACING:
            return (state = { ...state, timberTracings: payload });
        case SET_SOCIAL_MEDIA_LINK:
            return (state = { ...state, socialmedialinks: payload });
        case SET_ADMIN_DONATE_GENERAL:
            return (state = { ...state, donateGeneral: payload });
        case SET_ADMIN_STORY_LIST:
            return (state = { ...state, storiesList: payload });
        case SET_ADMIN_PARTNER_LIST:
            return (state = { ...state, partnerlist: payload });
        case SET_GENERAL_SETUP:
            return (state = { ...state, genset: payload })
        case SET_ADMIN_PROPOSAL:
            return (state = { ...state, proposals: payload})
        default:
            return state
    }
}