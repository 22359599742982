import React, { useEffect, useState } from "react";
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Textarea,
    Select,
    Option,
} from "@material-tailwind/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAdminGoals, setAdminMissions, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';
import { NavbarCMS } from "../NavbarCMS";

export function FormMission() {
    const { missionId } = useParams();
    const [lable, setLabel] = useState({});
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [language, setLanguage] = useState();
    const [file, setFile] = useState();
    const [imgPreview, setImgPreview] = useState();

    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (missionId) {
            dispatch(setLoaderVisibility(true))
            setLabel({
                title: "Update Mission",
                subtitle: "Enter your data details for update the mission",
                buttonLabel: "Update Data"
            })
            axios.get(`${API_BASE_URL}/api/mission/${missionId}`, { headers: { access_token: token }}).then(({ data }) => {
                dispatch(setLoaderVisibility(false))
                setTitle(data.data.title);
                setContent(data.data.content);
                setImgPreview(data.data.imgUrl);
                setLanguage(data.data.languageCode);
            }).catch((e) => {
                console.log(e)
                dispatch(setLoaderVisibility(false))
            })
        } else {
            setLabel({
                title: "Add Mission",
                subtitle: "Enter your data details for add the mission",
                buttonLabel: "Add Data"
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true))

        try {
            const reqParams = new FormData()
            reqParams.append("title", title);
            reqParams.append("content", content);
            reqParams.append("languageCode", language);
            reqParams.append('file', file);
            
            if (missionId) {
                axios
                    .put(`${API_BASE_URL}/api/mission/${missionId}`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        const res = {
                            lst: data.data.data,
                            link: `/cms/about/mission`
                        }
                        //dispatch(setAdminMissions(res));
                        swal("Data updated", "", "success").then((_) => {
                            navigate("/cms/about/mission");
                        });
                    })
                    .catch(console.log)
            } else {
                axios
                    .post(`${API_BASE_URL}/api/mission`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        const res = {
                            lst: data.data,
                            link: `/cms/about/mission`
                        }
                        //dispatch(setAdminMissions(res));
                        swal("Data created", "", "success").then((_) => {
                            navigate("/cms/about/mission");
                        });
                    })
                    .catch(console.log)
            }
        } catch (error) {
            console.log(error);
            dispatch(setLoaderVisibility(false))
        }
    };

    function handleChangeFile(e) {
        setFile(e.target.files[0]);
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <div className="h-full w-full">
            <NavbarCMS module={lable?.title} />
            <Card color="transparent" shadow={false} className="w-full flex justify-center items-center p-4">
                <Typography variant="h4" color="blue-gray">
                    {lable?.title}
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                    {lable.subtitle}
                </Typography>
                <form className="mt-8 mb-2" onSubmit={(e) => handleSubmit(e)}>
                    <div className="mb-1 flex flex-col gap-6">
                        <div className="flex">
                            <div className="flex flex-col gap-2 mr-2">
                                <Typography variant="h6" color="blue-gray" className="mb-1">
                                    Title
                                </Typography>
                                <Input
                                    size="lg"
                                    placeholder="title"
                                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col gap-2 mr-2 ">
                                <Typography variant="h6" color="blue-gray" className="mb-1">
                                    Image
                                </Typography>
                                <Input size="lg" placeholder="Content" type="file"
                                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                    onChange={handleChangeFile} />
                            </div>
                            <div className="flex flex-col gap-2 mr-2">
                                <Typography variant="h6" color="blue-gray" className="mb-1">
                                    Language
                                </Typography>
                                <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language}>
                                    <Option value={1033} aria-label="English">English</Option>
                                    <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                                </Select>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <Typography variant="h6" color="blue-gray" className="mb-1">
                                Content
                            </Typography>
                            <Textarea size="lg" placeholder="Content"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                value={content}
                                onChange={(e) => setContent(e.target.value)} />
                        </div>
                        <div className="flex flex-col w-full justify-center items-center">
                            <img src={imgPreview} className="w-80" />
                        </div>
                    </div>
                    <Button className="mt-6" fullWidth type="submit">
                        {lable?.buttonLabel}
                    </Button>
                </form>
            </Card>
        </div>

    );
}