import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

export function CardGrantsProgram({ item }) {
    return (
        <Card className="w-full h-[15em] bg-gradient-to-l from-green-900 to-green-500 w-full flex-row mt-2 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none">
            <CardHeader
                shadow={false}
                floated={false}
                className="m-0 w-2/5 flex justify-center shrink-0 rounded-r-none"
            >
                <img
                    src={item?.imgUrl}
                    alt="card-image"
                    className="w-full object-fit flex items-center"
                />
            </CardHeader>
            <CardBody>
                <Typography variant="h5" color="yellow" className="mb-2 line-clamp-2">
                    {item?.title}
                </Typography>
                <Typography color="white" variant="small" className="mb-2 font-normal line-clamp-4">
                    {item?.subtitle}
                </Typography>
                <Link to={`grants/${item?.id}/${item?.title?.replace(/ /g,"-")}`} className="inline-block w-full flex justify-end">
                    <Button variant="text" className="flex items-center gap-1" color="white">
                        Read More
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                        </svg>
                    </Button>
                </Link>

            </CardBody>
        </Card>
    )
}