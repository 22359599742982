import { LABEL_ENG } from "../../../data/static-config";
import { SET_ADMIN_LANGUAGE } from "../../actions/action-types";

const initState = LABEL_ENG;

export default function settings(state = initState, {type, payload}) {
    switch (type) {
        case SET_ADMIN_LANGUAGE:
            return (state = payload)
        default:
            return state
    }
}