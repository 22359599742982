import React, { useEffect, useState } from "react";
import { Button, Card, Typography } from "@material-tailwind/react";
import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { getAdminVideos, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import { dateConverter } from "../../../app/helper/helper";
import { ModalFormVideo } from "../Form/ModalFormVideo";
import { ModalPreviewVideo } from "../Form/ModalPreviewVideo";

export function TableVideos() {
  const TABLE_HEAD = ["Title", "Date", "Source Link", <div className="flex items-end justify-center gap-2 w-full"><Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-1/2" color="black" onClick={() => setShowModal(true)}>
    <PlusCircleIcon className="w-4" />
    Add
  </Button></div>];
  const TABLE_ROWS = useSelector((state) => state?.combineReducer?.adminOrganizations?.videoList);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [id, setId] = useState();
  const [videoId, setVideoId] = useState();
  const [title, setTitle] = useState();
  const dispatch = useDispatch();
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

  useEffect(() => {
		if (TABLE_ROWS?.length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [TABLE_ROWS]);

  useEffect(() => {
    dispatch(getAdminVideos({ token }));
  }, [isNeedRefresh]);

  const handleCallback = () => {
    setId(null)
    setShowModal(false);
    setVideoId(null);
    setShowPreview(false);
    setTitle(null);
    setIsNeedRefresh(!isNeedRefresh);
  }

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true))
          axios.delete(`${API_BASE_URL}/api/video/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false))
            setIsNeedRefresh(!isNeedRefresh)
          }).catch((e) => {
            console.log(e);
            dispatch(setLoaderVisibility(false))
          })
        }
      });
  };

  const handleUpdate = (id) => {
    setId(id);
    setShowModal(true)
  };

  const handlePreview = (paramId, title) => {
    setVideoId(paramId);
    setShowPreview(true);
    setTitle(title);
  };

  return (
    <div className="p-2">
      {showModal ? (
        <ModalFormVideo callback={handleCallback} id={id} />
      ) : null}
      {
        showPreview ? <ModalPreviewVideo callback={handleCallback} videoId={videoId} title={title}/>: null
      }
      <Card className="w-full rounded-none">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-4">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS?.map(({ id, videoId, title, date, source, languageCode, }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4 flex items-center gap-3" onClick={() => handlePreview(videoId, title)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {title}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handlePreview(videoId, title)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {date ? dateConverter(date) : null}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handlePreview(videoId, title)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {source}
                  </Typography>
                </td>
                {/* <td className="p-4" onClick={() => handlePreview(videoId, title)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {languageCode === 1033 ? "English" : "Bahasa Indonesia"}
                  </Typography>
                </td> */}
                <td className="p-4 h-full flex items-center justify-center">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-4" color="white" />
                    {
                      languageCode == 1033 ? "Update" : "Ubah"
                    }
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-4" />
                    {
                      languageCode == 1045 ? "Delete" : "Hapus"
                    }
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};