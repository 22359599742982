import { SET_CLIENT_LANGUANGE } from "../actions/action-types.js";

const initState = {
    data: 1045,
}

export default function clientLanguage(state = initState, {type, payload}) {
    switch (type) {
        case SET_CLIENT_LANGUANGE:
            return (state = {...state, data: payload});
        default:
            return state
    }
}