import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SidebarWithSeparator } from "../../components/index.js";
import { Spinner } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const AdminPage = () => {
	const isLoaderVisible = useSelector((state) => state?.combineReducer?.loader);

	useEffect(() => {
		if(isLoaderVisible){
			showSpinner()
		} else{
			hideSpinner();
		}
	}, [isLoaderVisible]);

	function showSpinner() {
		document.getElementById('spinner-overlay').classList.remove('hidden');
	}

	function hideSpinner() {
		document.getElementById('spinner-overlay').classList.add('hidden');
	}
	return (
		<div>
			<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" id="spinner-overlay" style={{ zIndex: 2500 }}>
				<Spinner size="large" color="blue" />
			</div>
			<div className="grid grid-cols-5">
				<aside className="self-start sticky top-0 col-span-1">
					<SidebarWithSeparator />
				</aside>
				<main className="col-span-4">
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default AdminPage;
