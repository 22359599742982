import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import {
  BookOpenIcon,
  TvIcon,
  QueueListIcon,
  TrophyIcon,
  CurrencyDollarIcon
} from "@heroicons/react/24/solid";
import parse from 'html-react-parser';

export function GrantsTabsWithIcon({ dataDetail }) {
  const data = [
    {
      label: "Deskripsi",
      value: "overview",
      icon: TvIcon,
      desc: dataDetail?.description
    },
    {
      label: "Topik dan Kegiatan",
      value: "topic",
      icon: BookOpenIcon,
      desc: dataDetail?.topic,
    },
    {
      label: "Anggaran",
      value: "budget",
      icon: CurrencyDollarIcon,
      desc: dataDetail?.budget
    },
    {
      label: "Kriteria Proposal",
      value: "requirements",
      icon: TrophyIcon,
      desc: dataDetail?.requirements,
    },
  ];
  return (
    <Tabs value="overview">
      <TabsHeader>
        {data.map(({ label, value, icon }) => (
          <Tab key={value} value={value}>
            <div className="flex items-center gap-2">
              {React.createElement(icon, { className: "w-5 h-5" })}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }) => (
          <TabPanel key={value} value={value}>
            {desc != null ? parse(desc) : ""}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}