import { SET_CLIENT_PARTNER } from "../actions/action-types.js";

let initState = {
    label: {},
    pages: {},
    partnerList : [],
    partnerListHeader : [],
    partnerListTabs: []
};

export default function clientPartner(state = initState, {type, payload}) {
    switch (type) {
        case SET_CLIENT_PARTNER:
            const { label, pages, partnerList, partnerListHeader, partnerListTabs, partnerListName} = payload;
            return (state = {...state, label: label, pages: pages, partnerList: partnerList, partnerListHeader: partnerListHeader, partnerListTabs: partnerListTabs, partnerListName: partnerListName})
        default:
            return state
    }
}