import React, { useState } from "react";
import {
  Typography
} from "@material-tailwind/react";
import YouTube from "react-youtube";

export function ModalPreviewVideo({ callback, title, videoId }) {
  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center w-full justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <Typography variant="h5">
                {title}
              </Typography>
            </div>
            <div className="flex justify-center items-center h-full w-full p-4">
              <YouTube videoId={videoId} className="w-auto" />
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={callback}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}