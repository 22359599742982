import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
  Button,
} from "@material-tailwind/react";
import {
  GlobeAmericasIcon,
  DocumentCheckIcon,
  CloudArrowDownIcon,
  VideoCameraIcon,
  BookOpenIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";
import React, { useState } from "react";

export function ItemCard({ item }) {
  const [category, setCategory] = useState("Books");

  function IconHeader(category) {
    switch (category) {
      case 4:
        return <><ClipboardDocumentIcon className="h-5 w-5 " />Fact Sheet</>
      case 3:
        return <><GlobeAmericasIcon className="h-5 w-5 " />Press Release</>
      case 1:
        return <><BookOpenIcon className="h-5 w-5 " />Books</>
      case 5:
        return <><VideoCameraIcon className="h-5 w-5 " />Videos</>
      case 2:
        return <><DocumentCheckIcon className="h-5 w-5 " />Report</>
      default:
        break
    }
  }
  return (
    <Card className="md:w-52 h-auto flex flex-col justify-center ">
      <img src={item?.imgUrl} alt="profile-picture" className="w-full object-cover rounded-xl h-[18em] md:h-72 md:w-52" />
      <CardBody className="absolute h-[18em] transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none opacity-0 hover:opacity-100 md:h-72 w-full md:w-52 bg-black/50 rounded-xl text-white">
        <Typography variant="small" color="white" className="mb-2 flex gap-2 items-center">
          {IconHeader(item.categoryCode)}
        </Typography>
        <Typography variant="h6" color="yellow" className="mb-2 line-clamp-3 text-ifmpallet3">
          {item?.title}
        </Typography>
        <Typography className="line-clamp-4 md:line-clamp-2" variant="small" color="white">
          {item?.description}
        </Typography>
        <a href={item?.fileUrl} target="_blank" className="w-full">
          <Button size="sm" variant="text" className="flex justify-center items-center mt-2 hover:cursor-pointer" color="white"><CloudArrowDownIcon color="white" className="h-4 mr-2" />Download</Button>
        </a>
      </CardBody>
    </Card>
  );
}