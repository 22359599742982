import React from "react";
import { NavbarCMS, TableDonateGeneral } from "../../components";
import { Link } from "react-router-dom";
import { MIDTRANS_DASHBOARD_URL } from "../../data/static-config";
import { Button } from "@material-tailwind/react";
import { useSelector } from "react-redux";

const DonateCMS = () => {
    const setting = useSelector((state) => state?.combineReducer?.settings);
    return (
        <div className="h-full w-full">
            <NavbarCMS module={setting?.donate?.navbar} />
            <TableDonateGeneral />
            <div className="py-10 w-full flex justify-center">
                <Link to={MIDTRANS_DASHBOARD_URL} target="_blank">
                    <Button>Open Midtrans Dashboard</Button>
                </Link>
            </div>
        </div>
    )
}

export default DonateCMS;