import React, { useEffect, useState } from "react";
import { PageDetail, OthersList } from "../../../components/index.js";
import { Typography } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDetailNews, setLoaderVisibility } from "../../../app/actions/action-creators.js";
import parse from "html-react-parser";

const NewsDetail = () => {
	const [data, setData] = useState();
	const [newsList, setNewsList] = useState();
	const { id } = useParams();
	const dispatch = useDispatch();
	const dataStore = useSelector((state) => state?.combineReducer?.detailNews);
	const clientLang = useSelector((state) => state?.combineReducer?.clientLanguage?.data);

	useEffect(() => {
		if (Object.keys(dataStore).length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [dataStore]);

	useEffect(() => {
		dispatch(getDetailNews(id, clientLang));
	}, [id]);

	useEffect(() => {
		setData(dataStore?.item != null ? dataStore?.item[0] : []);
		setNewsList(dataStore?.others);
	}, [id, dataStore]);

	let generateContent = (contentStr) => {
		if(contentStr.includes("<blockquote>")){
			let result = [];
			let arrStr = contentStr.split("<blockquote>");
			for(let i = 0; i < arrStr.length; i++){
				if(i == 0){
					result.push(<div dangerouslySetInnerHTML={{__html: arrStr[i]}}></div>)
				} else {
					let temp = arrStr[i].split("</blockquote>");
					result.push(<div dangerouslySetInnerHTML={{__html:parse(temp[0])}}></div>);
					result.push(<div dangerouslySetInnerHTML={{__html:temp[1]}}></div>);
				}
			}
			return (
				result
			)
		} else {
			return (
				<div dangerouslySetInnerHTML={{__html: data?.description}}></div>
			)
		}
	}

	return (
		<div className="bg-gray-100">
			<PageDetail data={data} />
			<div className="py-4 md:flex md:justify-between md:py-8 md:px-12">
				<div className="w-full w-full px-4 md:w-2/3 md:px-8">
					<div
						color="black"
						className="mb-0 opacity-80 text-justify"
					>
						{data?.description != null ?
						<>
							{generateContent(data?.description)}
						</>
						: ""}
					</div>
				</div>
				<div className="w-full p-4 md:w-1/3">
					<Typography
						variant="h5"
						color="black"
						className="mb-5 opacity-80 text-justify md:pl-4"
					>
						{
							clientLang == 1033 ? "Others:" : "Lainnya:"
						}
					</Typography>
					<OthersList module="news" otherList={newsList} />
				</div>
			</div>
		</div>
	);
};

export default NewsDetail;
