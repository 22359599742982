import React, { useEffect, useState } from "react";
import { BannerPage, MainStoryList } from "../../../components/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getClientStory, setLoaderVisibility } from "../../../app/actions/action-creators.js";
import { Button, CardFooter, Typography } from "@material-tailwind/react";

const Story = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state?.combineReducer?.clientStory);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const [pageNum, setPageNum] = useState(1);
	const pageTotal = useSelector((state) => state?.combineReducer?.clientStory?.pages?.pageTotal);
	const [searchKey, setSearchKey] = useState("");

	useEffect(() => {
		if (Object.keys(data).length === 0) {
		  dispatch(setLoaderVisibility(true))
		} else {
		  dispatch(setLoaderVisibility(false))
		}
	  }, [data]);

	const getSearchKey = (string) => {
		setSearchKey(string);
	};

	useEffect(() => {
		let req = {
			languageCode: languageCode,
			page: pageNum,
			searchKey: searchKey,
			limit: 12
		};
		dispatch(getClientStory(req))
	}, [languageCode, pageNum, searchKey]);

	const handlePageButton = (operator) => {
		setPageNum(pageNum + operator);
	};

	return (
		<div className="bg-gray-100">
			<BannerPage dataComp={data?.banner} />
			<div className="w-full px-6 py-3 md:px-20 md:py-10">
				<MainStoryList latestNews={data?.storylist} cbSearch={(params) => getSearchKey(params)} />
			</div>
			<div className="md:mx-16">
				<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
					<Typography variant="small" color="blue-gray" className="font-normal">
						{data?.pages?.title}
					</Typography>
					<div className="flex gap-2">
						<Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
							{data?.pages?.prev}
						</Button>
						<Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
							{data?.pages?.next}
						</Button>
					</div>
				</CardFooter>
			</div>
		</div>
	);
};

export default Story;
