import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendResetPassword } from "../../app/actions/action-creators";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";

const ChangePassword = () => {
    const [searchParams, _] = useSearchParams();
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const logo = useSelector((state) => state?.combineReducer?.clientFooter?.logo);
    const userId = searchParams.get("userId");
    const token = searchParams.get("token");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const processClick = (e) => {
        e.preventDefault();

        if (validatePassword()) {
            let payload = {
                password: password
            }

            let url = `${userId}/${token}`

            let response = dispatch(sendResetPassword(url, payload));
            swal("Password berhasil diubah", "", "success").then((_) => {
                navigate(`/`);
            }).catch(console.error);
        } else {
            swal("Password tidak sama dengan confirm password, silahkan ulangi", "", "error").then((_) => {
                navigate(`/password-reset?userId=${userId}&token=${token}`);
            }).catch(console.error);
        }
    };

    const validatePassword = () => {
        if (password != confPassword) {
            return false
        } else {
            return true
        }
    }

    return (
        <main id="content" role="main" className="w-full h-screen max-w-md p-6 mx-auto">
            <div className="bg-white border shadow-lg mt-7 rounded-xl">
                <div className="p-4 sm:p-7">
                    <div className="text-center">
                        <div classNameName="mb-2 p-4">
                            {
                                logo != null ? <img
                                    classNameName="h-20 w-full p-4 rounded-lg object-contain object-center"
                                    src={logo[0].logoUrl}
                                    alt="nature image"
                                /> : null
                            }
                        </div>
                        <h1 className="block text-lg font-bold text-gray-800">Reset Password</h1>
                    </div>

                    <div className="mt-5">
                        <form onSubmit={processClick}>
                            <div className="grid gap-y-4">
                                <div>
                                    <label for="new_password" className="block mb-2 ml-1 text-xs font-semibold ">New
                                        password</label>
                                    <div className="relative">
                                        <input type="password" id="new_password" name="new_password"
                                            className="block w-full px-4 py-3 text-sm border-2 border-gray-200 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            required aria-describedby="new-password-error"
                                            placeholder="Enter a new password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <p className="hidden mt-2 text-xs text-red-600" id="new-password-error">Please include a
                                        password that
                                        complies with the rules to ensure security</p>
                                </div>
                                <div>
                                    <label for="confirmn_new_password"
                                        className="block mb-2 ml-1 text-xs font-semibold ">Confirm new password</label>
                                    <div className="relative">
                                        <input type="password" id="confirmn_new_password" name="confirmn_new_password"
                                            className="block w-full px-4 py-3 text-sm border-2 border-gray-200 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            required aria-describedby="confirmn_new-password-error"
                                            placeholder="Enter a new password" value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                    </div>
                                    <p className="hidden mt-2 text-xs text-red-600" id="confirmn_new-password-error">Please
                                        include a password that
                                        complies with the rules to ensure security</p>
                                </div>
                                <button type="submit"
                                    className="inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-green-900 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Reset
                                    my password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default ChangePassword;