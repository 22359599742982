import { combineReducers } from "redux";

import clientLanguage from "./client-language.js";
import clientHome from "./client-home.js";
import clientNavbar from "./client-navbar.js";
import clientProgram from "./client-program.js";
import clientPartner from "./client-partner.js";
import clientAbout from "./client-about.js";
import detailProgram from "./detail-program.js";
import clientNews from "./client-news.js";
import detailNews from "./detail-news.js";
import clientVideos from "./client-videos.js";
import clientReport from "./client-report.js";
import clientBooks from "./client-books.js";
import clientFactSheet from "./client-factsheet.js";
import clientPressRelease from "./client-pressrelease.js";
import clientFooter from "./client-footer.js";
import clientDonate from "./client-donate.js";
import clientPublication from "./client-publication.js";
import clientStory from "./client-story.js";
import detailStory from "./detail-story.js";

import loader from "./loader.js";

import adminToken from "./admin/admin-token.js";
import adminOrganizations from "./admin/admin-organizations.js";
import settings from "./admin/settings.js";
import adminCharts from "./admin/admin-charts.js";

export default combineReducers({ clientLanguage, clientHome, clientNavbar, clientProgram, clientPartner, clientAbout, detailProgram, clientNews, detailNews, clientVideos, clientReport, clientBooks, clientFactSheet, clientPressRelease, clientFooter, adminToken, adminOrganizations, clientDonate, settings, adminCharts, loader, clientPublication, clientStory, detailStory });