import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Typography,
  Button
} from "@material-tailwind/react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../../data/static-config";
import { setLoaderVisibility } from "../../../app/actions/action-creators";

export function ModalFormUser({ callback, navUrl, id }) {
  const nameInput = useRef(null);
  const userNameInput = useRef(null);
  const emailInput = useRef(null);
  const pwdInput = useRef(null);
  const [label, setLabel] = useState("Add User");
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setLoaderVisibility(true));
    const reqParams = {
      name: nameInput.current.value,
      username: userNameInput.current.value,
      email: emailInput.current.value,
      password: pwdInput.current.value,
      confPassword: pwdInput.current.value,
      securityroleid: 1
    }

    try {
      axios
        .post(`${API_BASE_URL}/api/user`, reqParams, {
          headers: {
            access_token: token
          }
        })
        .then(({ data }) => {
          dispatch(setLoaderVisibility(false));
          swal("User Added", "", "success").then((_) => {
            callback();
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoaderVisibility(false));
        })
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
              <Typography variant="h5">
                {label}
              </Typography>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="relative p-2 flex-auto">
                <div className="shadow-md rounded px-8 pt-6 pb-8 w-full grid grid-cols-2 gap-4">
                  <div>
                    <Typography className="">Email</Typography>
                    <input label="Email" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" ref={emailInput} required />
                  </div>
                  <div>
                    <Typography>Name</Typography>
                    <input label="Name" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" ref={nameInput} />
                  </div>
                  <div>
                    <Typography>Username</Typography>
                    <input label="Username" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" ref={userNameInput} />
                  </div>
                  <div>
                    <Typography>Password</Typography>
                    <input label="Password" type="password" className="shadow appearance-none border rounded w-full py-2 px-1 text-black" ref={pwdInput} />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={callback}
                >
                  Close
                </button>
                <Button
                  className="text-white bg-green-900 active:bg-green-700 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}