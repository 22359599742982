import React, { useEffect, useRef, useState } from "react";
import { BannerPage } from "../../../components/index.js";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
  Tooltip,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getClientPublication, setLoaderVisibility } from "../../../app/actions/action-creators.js";
import parse from "html-react-parser";

const Publication = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.combineReducer?.clientPublication);
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const menus = data?.menus;

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      dispatch(setLoaderVisibility(true))
    } else {
      dispatch(setLoaderVisibility(false))
    }
  }, [data]);

  useEffect(() => {
    let params = {
      languageCode: languageCode,
      categoryCode: 6
    }
    dispatch(getClientPublication(params));
  }, [languageCode]);

  const generateLink = (categoryCode) => {
    let result = "";
    if (categoryCode === 1) {
      result = "/books"
    } else if (categoryCode === 2) {
      result = "/reports"
    } else if (categoryCode === 3) {
      result = "/pressrelease"
    } else if (categoryCode === 4) {
      result = "/factsheet"
    } else if (categoryCode === 5) {
      result = "/videos"
    } else if (categoryCode === 8) {
      result = "/story"
    } else if (categoryCode === 9) {
      result = "/sop"
    } else {
      result = "/news"
    }
    return result
  }

  return (
    <div className="bg-gray-100">
      <BannerPage dataComp={data?.banner} />
      <div className="w-full px-8 py-8 md:px-20 md:pb-20 md:pt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {
          menus != null ? menus?.map(({ id, title, categoryCode = -1, imgBanner, description }) => (
            <Link key={id + title} to={generateLink(categoryCode)} className="transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none">
              <figure className="relative h-96 w-full bg-gray-900 rounded-xl">
                <img
                  className="h-full w-full rounded-xl object-cover object-center opacity-50 blur-sm"
                  src={imgBanner}
                  alt="nature image"
                />
                <figcaption className="absolute bottom-8 left-2/4 flex w-[calc(100%-4rem)] -translate-x-2/4 justify-between rounded-xl border border-white bg-white/75 py-4 px-6 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                  <div>
                    <Typography variant="h5" color="blue-gray">
                      {title}
                    </Typography>
                    <Typography color="gray" className="mt-2 font-normal line-clamp-5">
                      {description != null ? parse(description) : null}
                    </Typography>
                  </div>
                </figcaption>
              </figure>
            </Link>
          )) : null
        }
      </div>
    </div>
  );
};

export default Publication;
