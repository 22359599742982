import React from "react";
import {
    Card,
    CardBody,
    Typography,
} from "@material-tailwind/react";

export function OurMission({data}) {
    return (
        <Card className="md:mt-6 md:w-full bg-ifmpallet2 rounded-br-[5em] md:rounded-br-[100em] rounded-tl-[5em] md:rounded-tl-[100em] mb-2 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none" shadow={true}>
            <CardBody className="md:flex md:justify-center md:items-center">
                <Typography variant="h2" color="white" className="w-full md:w-1/4 p-1 text-center">
                    {data?.title}
                </Typography>
                <Typography variant="lead" color="ifmpallet3" className="w-full md:w-3/4 text-center text-ifmpallet3 pr-8">
                    {data?.content}
                </Typography>
            </CardBody>
        </Card>
    );
}