import React from "react";
import { Button, Card, Typography } from "@material-tailwind/react";
import { TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { setLoaderVisibility } from "../../../app/actions/action-creators.js";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';

export function TableUsers({ callback, handlePopUp }) {
  const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
  const TABLE_HEAD = languageCode == 1033 ? ["No", "Name", "Email", ""] : ["No", "Nama", "Email", ""];
  const TABLE_ROWS = useSelector((state) => state?.combineReducer?.adminCharts?.data?.users);
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true));
          axios.delete(`${API_BASE_URL}/api/user/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false));
            callback()
          }).catch((err) => {
            console.log(err);
            dispatch(setLoaderVisibility(false));
          })
        } else {
          dispatch(setLoaderVisibility(false));
        }
      });
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-end w-full pb-4 pr-5">
        <Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-1/5 md:w-36" color="black" onClick={() => handlePopUp(true)}>
          <PlusCircleIcon className="w-4" />
          {
            languageCode == 1033 ? "Add Data" : "Tambah Data"
          }
        </Button>
      </div>
      <Card className="w-full rounded-none">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-2">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS != null ? TABLE_ROWS?.map(({ id, name, email }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50">
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {index + 1}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {email}
                  </Typography>
                </td>
                <td className="p-4 h-full flex items-center justify-center">
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-1 m-1" onClick={() => handleDelete(id)} disabled={TABLE_ROWS.length === 1 ? true : false}>
                    <TrashIcon className="w-3" />
                    {languageCode == 1033 ? "Remove" : "Hapus"}
                  </Button>
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </Card>
    </div>
  );
}