import React from "react";
import { CardStory } from "../client/CardStory";

export function StoryList({progList}) {
    return (
        <div className="px-2 md:px-4 flex flex-col justify-around gap-4">
            {
                progList?.map((item) => (
                    <CardStory item={item} />
                ))
            }
        </div>

    );
}