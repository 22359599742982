//Development
// export const API_BASE_URL = "http://localhost:8000";
// export const MIDTRANS_SNAP_URL = "https://app.sandbox.midtrans.com/snap/snap.js";
// export const MIDTRANS_CLIENT_KEY = "SB-Mid-client-LoaCY8_tNrlrsUu5";

//Production
export const API_BASE_URL = "https://www.api.forestfund.or.id";
export const MIDTRANS_SNAP_URL =  "https://app.midtrans.com/snap/snap.js";
export const MIDTRANS_CLIENT_KEY = "Mid-client-YrTlN6x5Lx9s6mZG";

export const MIDTRANS_DASHBOARD_URL = "https://dashboard.midtrans.com/login";
export const LABEL_ENG = {
    dashboard: "Dashboard",
    dashboard_overview: {
        title: "Overview",
        navbar: "Dashboard - Overview"
    },
    dashboard_home_setup: {
        title: "Home Setup",
        navbar: "Dashboard - Home Setup",
    },
    timber_traceability: "Timber Traceability",
    timber_tracings: {
        title: "Timber Tracings",
        navbar: "Timber Tracings",
    },
    program: "Programs",
    program_general: {
        title: "General",
        navbar: "Program - General"
    },
    program_list: {
        title: "Program List",
        navbar: "Program - Program List"
    },
    proposal_list: {
        title: "Proposal List",
        navbar: "Program - Proposal List"
    },
    partner: "Partners",
    partner_general: {
        title: "General",
        navbar: "Partners - General"
    },
    partner_list: {
        title: "Partner List",
        navbar: "Partners - Partner List"
    },
    partner_findings: {
        title: "Findings",
        navbar: "Partner - Findings"
    },
    partner_stories: {
        title: "Story of The Monitor",
        navbar: "Partner - Story of The Monitor"
    },
    publication: "Publications",
    publication_general: {
        title: "General",
        navbar: "Publication - General"
    },
    publication_news: {
        title: "News",
        navbar: "Publication - News"
    },
    publication_document_list: {
        title: "Document List",
        navbar: "Publication - Document List"
    },
    publication_video: {
        title: "Videos",
        navbar: "Publication - Video"
    },
    publication_categories: {
        title: "Categories",
        navbar: "Publication - Categories"
    },
    about: "About us",
    about_general: {
        title: "General",
        navbar: "About us - General"
    },
    about_goals: {
        title: "Goals",
        navbar: "About us - Goals"
    },
    about_missions: {
        title: "Missions",
        navbar: "About us - Missions"
    },
    about_team: {
        title: "Our Team",
        navbar: "About us - Our Team"
    },
    donate: {
        title: "Donate",
        navbar: "Donate",
        open_midtrans: "Open Midtrans Dashboard"
    },
    genset: {
        title: "General Setup",
        navbar: "General Setup"
    },
    setting: "Setting",
    logout: "Logout",
    buttonAdd: "Add Data",
    search: "Search",
    popup: {
        areyousure: "Are you sure?",
        buttonOk: "Ok",
        buttonCancel: "Cancel"
    },
    story: "Independent Monitor Story"
}
export const LABEL_IDN = {
    dashboard: "Dasbor",
    dashboard_overview: {
        title: "Ringkasan",
        navbar: "Dasbor - Ringkasan"
    },
    dashboard_home_setup: {
        title: "Pengaturan Beranda",
        navbar: "Dasbor - Pengaturan Beranda",
    },
    timber_traceability: "Peredaran Kayu",
    timber_tracings: {
        title: "Peredaran Kayu",
        navbar: "Peredaran Kayu",
    },
    program: "Program",
    program_general: {
        title: "Umum",
        navbar: "Program - Umum"
    },
    program_list: {
        title: "Daftar Program",
        navbar: "Program - Daftar Program"
    },
    proposal_list: {
        title: "Daftar Proposal",
        navbar: "Program - Daftar Proposal"
    },
    partner: "Mitra",
    partner_general: {
        title: "Umum",
        navbar: "Mitra - Umum"
    },
    partner_list: {
        title: "Daftar Mitra",
        navbar: "Mitra - Daftar mitra"
    },
    partner_findings: {
        title: "Daftar Temuan",
        navbar: "Mitra - Daftar Temuan"
    },
    partner_stories: {
        title: "Cerita Pemantau",
        navbar: "Mitra - Cerita Pemantau"
    },
    publication: "Publikasi",
    publication_general: {
        title: "Umum",
        navbar: "Publikasi - Umum"
    },
    publication_news: {
        title: "Berita",
        navbar: "Publikasi - Berita"
    },
    publication_document_list: {
        title: "Daftar Dokumen",
        navbar: "Publikasi - Daftar Dokumen"
    },
    publication_video: {
        title: "Video",
        navbar: "Publikasi - Video"
    },
    publication_categories: {
        title: "Kategori",
        navbar: "Publikasi - Kategori"
    },
    about: "Tentang Kami",
    about_general: {
        title: "Umum",
        navbar: "Tentang Kami - Umum"
    },
    about_goals: {
        title: "Tujuan Kami",
        navbar: "Tentang Kami - Tujuan Kami"
    },
    about_missions: {
        title: "Misi",
        navbar: "Tentang Kami - Misi"
    },
    about_team: {
        title: "Tim Kami",
        navbar: "Tentang Kami - Tim Kami"
    },
    donate: {
        title: "Donasi",
        navbar: "Donasi",
        open_midtrans: "Buka Dashboard Midtrans"
    },
    genset: {
        title: "Pengaturan Umum",
        navbar: "Pengaturan Umum"
    },
    setting: "Pengaturan",
    logout: "Keluar",
    buttonAdd: "Tambah Data",
    search: "Cari",
    popup: {
        areyousure: "Apakah kamu yakin?",
        buttonOk: "Ok",
        buttonCancel: "Batal"
    },
    story: "Cerita Pemantau"
}

export const listProvince = [
    {
        "provinsi": "Daerah Istimewa Aceh"
    },
    {
        "provinsi": "Sumatera Utara"
    },
    {
        "provinsi": "Sumatera Barat"
    },
    {
        "provinsi": "Riau"
    },
    {
        "provinsi": "Jambi"
    },
    {
        "provinsi": "Sumatera Selatan"
    },
    {
        "provinsi": "Bengkulu"
    },
    {
        "provinsi": "Lampung"
    },
    {
        "provinsi": "Kepulauan Bangka Belitung"
    },
    {
        "provinsi": "Kepulauan Riau"
    },

    {
        "provinsi": "DKI Jakarta"
    },

    {
        "provinsi": "Jawa Barat"
    },

    {
        "provinsi": "Jawa Tengah"
    },

    {
        "provinsi": "DI Yogyakarta"
    },

    {
        "provinsi": "Jawa Timur"
    },

    {
        "provinsi": "Banten"
    },

    {
        "provinsi": "Bali"
    },

    {
        "provinsi": "Nusa Tenggara Barat"
    },

    {
        "provinsi": "Nusa Tenggara Timur"
    },
    {
        "provinsi": "Kalimantan Barat"
    },

    {
        "provinsi": "Kalimantan Tengah"
    },

    {
        "provinsi": "Kalimantan Selatan"
    },

    {
        "provinsi": "Kalimantan Timur"
    },

    {
        "provinsi": "Kalimantan Utara"
    },

    {
        "provinsi": "Sulawesi Utara"
    },

    {
        "provinsi": "Sulawesi Tengah"
    },

    {
        "provinsi": "Sulawesi Selatan"
    },

    {
        "provinsi": "Sulawesi Tenggara"
    },

    {
        "provinsi": "Gorontalo"
    },

    {
        "provinsi": "Sulawesi Barat"
    },

    {
        "provinsi": "Maluku"
    },

    {
        "provinsi": "Maluku Utara"
    },
    {
        "provinsi": "Papua"
    },
    {
        "provinsi": "Papua Barat"
    }
];

export const idLabel = {
    instagram: "Ikuti kami di instagram :",
    programs: "Program",
    news: "Berita Terbaru",
    publication: "Publikasi",
    partner: "Mitra",
    more: "Selengkapnya",
    story: "Cerita Pemantau"
};

export const enLabel = {
    instagram: "Follow Us on Instagram :",
    programs: "Programs",
    news: "Latest news",
    publication: "Publication",
    partner: "Partners",
    more: "See More",
    story: "Independent Monitor Story"
}

export const idLabelMitra = {
    form : {
        finding: "Temuan",
        org: "Lembaga/Individu",
        location: "Lokasi",
        email: "Email Lembaga/Individu",
        state: "Status Temuan",
        upload: "Unggah",
        search: "Cari",
        titleAdd: "Tambah Temuan",
        titleUpdate: "Ubah Temuan"
    },
    btn : {
        add: "Tambah Temuan",
        update: "Ubah",
        delete: "Hapus"
    }
}

export const enLabelMitra = {
    form : {
        finding: "Finding",
        org: "Organization/Individual",
        location: "location",
        email: "Email Organization/Individual",
        state: "Finding Status",
        upload: "Upload",
        search: "Search",
        titleAdd: "Add Finding",
        titleUpdate: "Update Finding"
    },
    btn : {
        add: "Add Findings",
        update: "Update",
        delete: "Delete"
    }
}