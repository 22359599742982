import React, { useEffect, useRef, useState } from "react";
import { MainProgramsList, } from "../../components";
import { BannerPage } from "../../components/index.js";
import { useDispatch, useSelector } from "react-redux";
import { getClientProgramData, setLoaderVisibility } from "../../app/actions/action-creators";
import {
	Card,
	Typography,
	Button,
	CardFooter,
} from "@material-tailwind/react";

const Program = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state?.combineReducer?.clientProgram);
	const languageCode = useSelector((state) => state?.combineReducer?.clientLanguage?.data);
	const [pageNum, setPageNum] = useState(1);
	const pageTotal = useSelector((state) => state?.combineReducer?.clientProgram?.pages?.pageTotal);
	const [searchKey, setSearchKey] = useState("");

	const getSearchKey = (string) => {
		setSearchKey(string);
	};

	useEffect(() => {
		if (Object.keys(data).length === 0 || !data) {
		  dispatch(setLoaderVisibility(true))
		} else {
		  dispatch(setLoaderVisibility(false))
		}
	  }, [data]);

	useEffect(() => {
		let req = {
			languageCode: languageCode,
			page: pageNum,
			searchKey: searchKey,
			limit: 10
		};
		dispatch(getClientProgramData(req));
	}, [languageCode, pageNum, searchKey]);


	const handlePageButton = (operator) => {
		setPageNum(pageNum + operator);
	};

	return (
		<div className="bg-gray-100">
			<BannerPage dataComp={data?.banner} />
			<div className="w-full md:px-20 md:pb-20">
				<div class="p-2 relative text-gray-600 flex justify-end">
					<input class="border-2 w-full md:w-1/2 border-gray-300 bg-white h-10 px-5 pr-8 md:pr-16 rounded-lg text-sm focus:outline-none"
						type="search" name="search" placeholder="Search" onChange={(e) => getSearchKey(e.target.value)} />
					<button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
						<svg class="text-gray-600 h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
							version="1.1" id="Capa_1" x="0px" y="0px"
							viewBox="0 0 56.966 56.966" space="preserve"
							width="512px" height="512px">
							<path
								d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
						</svg>
					</button>
				</div>
				<MainProgramsList progList={data?.lst} />
				<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
					<Typography variant="small" color="blue-gray" className="font-normal">
						{data?.pages?.title}
					</Typography>
					<div className="flex gap-2">
						<Button variant="outlined" size="sm" disabled={pageNum == 1 ? true : false} onClick={() => handlePageButton(-1)}>
							{data?.pages?.prev}
						</Button>
						<Button variant="outlined" size="sm" disabled={pageNum == pageTotal ? true : false} onClick={() => handlePageButton(1)}>
							{data?.pages?.next}
						</Button>
					</div>
				</CardFooter>
			</div>
		</div>
	);
};

export default Program;
