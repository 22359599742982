import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import './App.css';
import { Home, News, Program, Publication, About, Partner, MainPage, Donate, LoginAdmin, NewsDetail, AboutCMS, Dashboard, DonateCMS, NewsCMS, PartnersCMS, ProgramCMS, PublicationCMS, Setting, AdminPage, ResetPassword, ProgramDetail, FactSheet, PressRelease, BookPage, VideoPage, TimberTraceability, GrantsProgramDetail, Reports, GoalCMS, ChangePassword, Protected, MissionCMS, OurTeamCMS, PartnerGeneral, PartnerOpen, CategoriesPage, PublicationGeneral, VideosPageAdmin, ProgramGeneral, HomeSetupPage, TimberTracingPage, StoriesCMS, Story, PartnerList, ProposalCMS, SOP } from './pages/index.js';
import "../node_modules/flag-icons/css/flag-icons.min.css";
import { useSelector } from "react-redux";
import { FormDonateGeneral, FormGoal, FormHomeGeneral, FormMission, FormNewsGeneral, FormNewsList, FormPartnerGeneral, FormProgramGeneral, FormProgramList, FormPublicationGeneral, FormStoryList, FormTeamMember, OurMission, TableTimberTracings } from "./components";
import { FormAbout } from "./components/admin/Form/FormAbout";
import StoryDetail from "./pages/client/Publication/StoryDetail.js";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-QH2JFVNTQM');

function App() {
  const isSignedIn = useSelector((state) => state?.combineReducer?.adminToken?.auth);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route
            path=""
            element={<Home />}
          />
          <Route path="program" element={<Program />} />
          <Route path="program/:id/:title" element={<ProgramDetail />} />
          <Route path="program/grants/:id/:title" element={<GrantsProgramDetail />} />
          <Route path="publication" element={<Publication />} />
          <Route path="factsheet" element={<FactSheet />} />
          <Route path="pressrelease" element={<PressRelease />} />
          <Route path="books" element={<BookPage />} />
          <Route path="videos" element={<VideoPage />} />
          <Route path="partner" element={<Partner />} />
          <Route path="partner/:id/:title" element={<Partner />} />
          <Route path="story" element={<Story />} />
          <Route path="story/:id/:title" element={<StoryDetail />} />
          <Route path="news" element={<News />} />
          <Route path="news/:id/:title" element={<NewsDetail />} />
          <Route path="about" element={<About />} />
          <Route path="donate" element={<Donate />} />
          <Route path="timbertraceability" element={<TimberTraceability />} />
          <Route path="reports" element={<Reports />} />
          <Route path="sop" element={<SOP />} />
        </Route>
        <Route path="login" element={<LoginAdmin />} />
        <Route path="reset" element={<ResetPassword />} />
        <Route path="password-reset" element={ <ChangePassword />} />
        <Route element={<AdminPage />}>
          <Route path="cms/dashboard" element={<Protected isSignedIn={isSignedIn}><Dashboard /></Protected>} />
          <Route path="cms/homesetup" element={<Protected isSignedIn={isSignedIn}><HomeSetupPage /></Protected>} />
          <Route path="cms/homesetup/add" element={<Protected isSignedIn={isSignedIn}><FormHomeGeneral /></Protected>} />
          <Route path="cms/homesetup/update/:homeGenId" element={<Protected isSignedIn={isSignedIn}><FormHomeGeneral /></Protected>} />
          <Route path="cms/program" element={<Protected isSignedIn={isSignedIn}><ProgramCMS /></Protected>} />
          <Route path="cms/program/add" element={<Protected isSignedIn={isSignedIn}><FormProgramList /></Protected>} />
          <Route path="cms/program/update/:progId" element={<Protected isSignedIn={isSignedIn}><FormProgramList /></Protected>} />
          <Route path="cms/program/general" element={<Protected isSignedIn={isSignedIn}><ProgramGeneral /></Protected>} />
          <Route path="cms/programgeneral/add" element={<Protected isSignedIn={isSignedIn}><FormProgramGeneral /></Protected>} />
          <Route path="cms/programgeneral/update/:id" element={<Protected isSignedIn={isSignedIn}><FormProgramGeneral /></Protected>} />
          <Route path="cms/proposal" element={<Protected isSignedIn={isSignedIn}><ProposalCMS /></Protected>} />
          <Route path="cms/publication" element={<Protected isSignedIn={isSignedIn}><PublicationCMS /></Protected>} />
          <Route path="cms/publication/general" element={<Protected isSignedIn={isSignedIn}><PublicationGeneral /></Protected>} />
          <Route path="cms/publicationgeneral/add" element={<Protected isSignedIn={isSignedIn}><FormPublicationGeneral /></Protected>} />
          <Route path="cms/publicationgeneral/update/:pubGenId" element={<Protected isSignedIn={isSignedIn}><FormPublicationGeneral /></Protected>} />
          <Route path="cms/publication/video" element={<Protected isSignedIn={isSignedIn}><VideosPageAdmin /></Protected>} />
          <Route path="cms/publication/news" element={<Protected isSignedIn={isSignedIn}><NewsCMS /></Protected>} />
          <Route path="cms/publication/news/update/:newsGenId" element={<Protected isSignedIn={isSignedIn}><FormNewsGeneral /></Protected>} />
          <Route path="cms/publication/news/add" element={<Protected isSignedIn={isSignedIn}><FormNewsGeneral /></Protected>} />
          <Route path="cms/publication/news/list/add" element={<Protected isSignedIn={isSignedIn}><FormNewsList /></Protected>} />
          <Route path="cms/publication/news/list/update/:newsListId" element={<Protected isSignedIn={isSignedIn}><FormNewsList /></Protected>} />
          <Route path="cms/partner" element={<Protected isSignedIn={isSignedIn}><PartnerGeneral /></Protected>} />
          <Route path="cms/partner/add" element={<Protected isSignedIn={isSignedIn}><FormPartnerGeneral /></Protected>} />
          <Route path="cms/partner/update/:partGenId" element={<Protected isSignedIn={isSignedIn}><FormPartnerGeneral /></Protected>} />
          <Route path="cms/partner/open" element={<Protected isSignedIn={isSignedIn}><PartnerOpen /></Protected>} />
          <Route path="cms/partnerlist" element={<Protected isSignedIn={isSignedIn}><PartnerList /></Protected>} />
          <Route path="cms/news" element={<Protected isSignedIn={isSignedIn}><NewsCMS /></Protected>} />
          <Route path="cms/stories" element={<Protected isSignedIn={isSignedIn}><StoriesCMS /></Protected>} />
          <Route path="cms/stories/add" element={<Protected isSignedIn={isSignedIn}><FormStoryList /></Protected>} />
          <Route path="cms/stories/update/:storyId" element={<Protected isSignedIn={isSignedIn}><FormStoryList /></Protected>} />
          <Route path="cms/about" element={<Protected isSignedIn={isSignedIn}><AboutCMS /></Protected>} />
          <Route path="cms/about/add" element={<Protected isSignedIn={isSignedIn}><FormAbout /></Protected>} />
          <Route path="cms/about/update/:aboutId" element={<Protected isSignedIn={isSignedIn}><FormAbout /></Protected>} />
          <Route path="cms/about/goal" element={<Protected isSignedIn={isSignedIn}><GoalCMS /></Protected>} />
          <Route path="cms/about/goal/add" element={<Protected isSignedIn={isSignedIn}><FormGoal /></Protected>} />
          <Route path="cms/about/goal/update/:goalId" element={<Protected isSignedIn={isSignedIn}><FormGoal /></Protected>} />
          <Route path="cms/about/mission" element={<Protected isSignedIn={isSignedIn}><MissionCMS /></Protected>} />
          <Route path="cms/about/mission/add" element={<Protected isSignedIn={isSignedIn}><FormMission /></Protected>} />
          <Route path="cms/about/mission/update/:missionId" element={<Protected isSignedIn={isSignedIn}><FormMission /></Protected>} />
          <Route path="cms/about/team" element={<Protected isSignedIn={isSignedIn}><OurTeamCMS /></Protected>} />
          <Route path="cms/about/team/add" element={<Protected isSignedIn={isSignedIn}><FormTeamMember /></Protected>} />
          <Route path="cms/about/team/update/:teamId" element={<Protected isSignedIn={isSignedIn}><FormTeamMember /></Protected>} />
          <Route path="cms/categories" element={<Protected isSignedIn={isSignedIn}><CategoriesPage /></Protected>} />
          <Route path="cms/donate" element={<Protected isSignedIn={isSignedIn}><DonateCMS /></Protected>} />
          <Route path="cms/donate/add" element={<Protected isSignedIn={isSignedIn}><FormDonateGeneral /></Protected>} />
          <Route path="cms/donate/update/:id" element={<Protected isSignedIn={isSignedIn}><FormDonateGeneral /></Protected>} />
          <Route path="cms/generalsetup" element={<Protected isSignedIn={isSignedIn}><Setting /></Protected>} />
          <Route path="cms/timbertracings" element={<Protected isSignedIn={isSignedIn}><TimberTracingPage /></Protected>} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </Router>
  );
}

export default App;
