import React, { useEffect, useState } from "react";
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Textarea,
    Select,
    Option,
} from "@material-tailwind/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAdminGoals, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';
import { NavbarCMS } from "../NavbarCMS";

export function FormGoal() {
    const { goalId } = useParams();
    const [lable, setLabel] = useState({});
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [language, setLanguage] = useState();

    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
    const redirectLink = useSelector((state) => state?.combineReducer?.adminOrganizations?.redirect);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (goalId) {
            dispatch(setLoaderVisibility(true));
            setLabel({
                title: "Update Goal",
                subtitle: "Enter your data details for update the goal",
                buttonLabel: "Update Data"
            })
            axios.get(`${API_BASE_URL}/api/goal/${goalId}`, { headers: { access_token: token } }).then(({ data }) => {
                dispatch(setLoaderVisibility(false));
                setTitle(data.data.title);
                setDescription(data.data.description);
                setLanguage(data.data.languageCode);
            }).catch(console.error)
        } else {
            setLabel({
                title: "Add Goal",
                subtitle: "Enter your data details for add the goal",
                buttonLabel: "Add Data"
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true));
        try {
            let reqParams = {
                title: title,
                description: description,
                languageCode: language
            };
            if (goalId) {
                axios
                    .put(`${API_BASE_URL}/api/goal/${goalId}`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        const res = {
                            lst: data.data.data,
                            link: `/cms/about/goal`
                        }
                        dispatch(setAdminGoals(res));
                        dispatch(setLoaderVisibility(false));
                        swal("Data updated", "", "success").then(({ data }) => {
                            navigate("/cms/about/goal");
                        });
                    })
                    .catch(console.log)
            } else {
                axios
                    .post(`${API_BASE_URL}/api/goal`, reqParams, {
                        headers: {
                            access_token: token
                        }
                    })
                    .then(({ data }) => {
                        const res = {
                            lst: data.data,
                            link: `/cms/about/goal`
                        }
                        dispatch(setAdminGoals(res));
                        dispatch(setLoaderVisibility(false));
                        swal("Data created", "", "success").then((_) => {
                            navigate("/cms/about/goal");
                        });
                    })
                    .catch(console.log)
            }
        } catch (error) {
            console.log(error);
            dispatch(setLoaderVisibility(false));
        }
    };

    return (
        <>
            <NavbarCMS module={lable?.title} />
            <div className="w-full flex justify-center items-center pt-16">
                <Card color="transparent" shadow={false}>
                    <Typography variant="h4" color="blue-gray">
                        {lable?.title}
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        {lable.subtitle}
                    </Typography>
                    <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-1 flex flex-col gap-6">
                            <Typography variant="h6" color="blue-gray" className="-mb-3">
                                Title
                            </Typography>
                            <Input
                                size="lg"
                                placeholder="title"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <Typography variant="h6" color="blue-gray" className="-mb-3">
                                Description
                            </Typography>
                            <Textarea size="lg" placeholder="Description"
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)} />
                            <Typography variant="h6" color="blue-gray" className="-mb-3">
                                Language
                            </Typography>
                            <Select value={language} defaultValue={language} onChange={(e) => setLanguage(e)}>
                                <Option value={1033} aria-label="English">English</Option>
                                <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                            </Select>
                        </div>
                        <Button className="mt-6" fullWidth type="submit">
                            {lable?.buttonLabel}
                        </Button>
                    </form>
                </Card>
            </div>
        </>
    );
}