import { Bar } from "react-chartjs-2";

export const BarChart = ({ chartData }) => {
    return (
        <div className="App">
            {
                chartData ?             <div>
                <Bar
                    data={chartData}
                    height={400}
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    afterFit: function (scaleInstance) {
                                        scaleInstance.width = 100;
                                    }
                                },
                            ],
                        },
                        legend: {
                            labels: {
                                display: false
                            },
                        },
                    }}
                />
            </div> : <p>Data Tidak Tersedia</p>
            }
        </div>
    );
};