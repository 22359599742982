import React, { useEffect, useState } from "react";
import {
    Card,
    Input,
    Button,
    Typography,
    Select,
    Option,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { NavbarCMS } from "../NavbarCMS";
import EditorToolbar, { modules, formats } from "../../quill/EditorToolbar.js";

export function FormNewsList() {
    const { newsListId } = useParams();
    const [lable, setLabel] = useState({});
    const [title, setTitle] = useState();
    const [publishDate, setPublishDate] = useState();
    const [publisher, setPublisher] = useState();
    const [description, setDescription] = useState();
    const [author, setAuthor] = useState();
    const [sourceLink, setSourceLink] = useState();
    const [language, setLanguage] = useState(1033);
    const [file, setFile] = useState();
    const [imgPreview, setImgPreview] = useState();
    const [generateImg, setGenImg] = useState();

    const token = useSelector((state) => state?.combineReducer?.adminToken?.token);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (newsListId) {
            dispatch(setLoaderVisibility(true));
            setLabel({
                title: "Update News List Data",
                subtitle: "Enter your data details for update data",
                buttonLabel: "Update Data"
            })
            axios.get(`${API_BASE_URL}/api/news/${newsListId}`, { headers: { access_token: token } }).then(({ data }) => {
                dispatch(setLoaderVisibility(false));
                const currentDate = new Date(data?.data?.publishDate);
                currentDate.setHours(currentDate.getHours() + 7);
                setPublishDate(currentDate.toISOString().substring(0,10));
                setPublisher(data.data.publisher);
                setSourceLink(data.data.source);
                setAuthor(data.data.author);
                setTitle(data.data.title);
                setDescription(data.data.description);
                setImgPreview(data.data.imgUrl);
                setLanguage(data.data.languageCode);
                generateImgPreview();
            }).catch((e) => {
                console.log(e);
                dispatch(setLoaderVisibility(false));
            })
        } else {
            setLabel({
                title: "Add News List Data",
                subtitle: "Enter your data details for add data",
                buttonLabel: "Add Data"
            });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoaderVisibility(true));
        const reqParams = new FormData()
        reqParams.append("title", title);
        reqParams.append("publisher", publisher);
        reqParams.append("publishDate", publishDate);
        reqParams.append("description", description);
        reqParams.append("author", author);
        reqParams.append("source", sourceLink);
        reqParams.append("languageCode", language);
        reqParams.append("file", file);

        if (newsListId) {
            axios
                .put(`${API_BASE_URL}/api/news/${newsListId}`, reqParams, {
                    headers: {
                        access_token: token
                    }
                })
                .then(({ data }) => {
                    const res = {
                        lst: data.data.data,
                        link: `/cms/publication/news`
                    }
                    dispatch(setLoaderVisibility(false));
                    swal("Data updated", "", "success").then((_) => {
                        navigate("/cms/publication/news");
                    });
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setLoaderVisibility(false));
                })
        } else {
            axios
                .post(`${API_BASE_URL}/api/news`, reqParams, {
                    headers: {
                        access_token: token
                    }
                })
                .then(({ data }) => {
                    dispatch(setLoaderVisibility(false));
                    swal("Data created", "", "success").then((_) => {
                        navigate("/cms/publication/news");
                    });
                })
                .catch((e) => {
                    console.log(e);
                    dispatch(setLoaderVisibility(false));
                })
        }
    };

    function handleChangeFile(e) {
        setFile(e.target.files[0]);
        setImgPreview(e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : null);
        generateImgPreview();
    }

    const generateImgPreview = () => {
        imgPreview ? setGenImg("w-full h-80 rounded") : setGenImg("");
    }

    return (
        <>
            <NavbarCMS module={lable?.title} />
            <div className="w-full">
                <Card color="transparent" shadow={false} className="h-full w-full flex justify-center items-center p-4">
                    <Typography variant="h4" color="blue-gray">
                        {lable?.title}
                    </Typography>
                    <Typography color="gray" className="mt-1 font-normal">
                        {lable.subtitle}
                    </Typography>
                    <form className="mt-8 mb-2" onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-1 flex flex-col gap-6">
                            <div className="flex gap-2">
                                <Input
                                    label="Title"
                                    size="lg"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Input
                                    type="date"
                                    size="lg"
                                    label="Publish date"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={publishDate}
                                    onChange={(e) => setPublishDate(e.target.value)}
                                />
                            </div>
                            <div className="flex gap-2">
                                <Input
                                    type="text"
                                    size="lg"
                                    label="Publisher"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={publisher}
                                    onChange={(e) => setPublisher(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    size="lg"
                                    label="Subtitle"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={sourceLink}
                                    onChange={(e) => setSourceLink(e.target.value)}
                                />
                            </div>
                            <div className="flex gap-2">
                                <Input
                                    type="text"
                                    size="lg"
                                    label="Author"
                                    className="pr-20"
                                    containerProps={{
                                        className: "min-w-0",
                                    }}
                                    value={author}
                                    onChange={(e) => setAuthor(e.target.value)}
                                />
                                <Select value={language} onChange={(e) => setLanguage(e)} defaultValue={language} label="Language">
                                    <Option value={1033} aria-label="English">English</Option>
                                    <Option value={1045} aria-label="Bahasa Indonesia">Bahasa Indonesia</Option>
                                </Select>
                            </div>
                            <div className="flex gap-2">
                                <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="file" label="Image"
                                        className="pr-20" onChange={handleChangeFile}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                    />
                                </div>
                                <div className="relative flex w-full max-w-[28rem]">
                                    <img src={imgPreview} className={generateImg} />
                                </div>
                                {/* <div className="relative flex w-full max-w-[28rem]">
                                    <Input size="lg" placeholder="Content" type="file" label="Banner"
                                        className="pr-20" onChange={handleChangeFileBanner}
                                        containerProps={{
                                            className: "min-w-0",
                                        }}
                                    />
                                </div> */}
                            </div>
                            <div className="flex gap-2">
                                {/* <div className="relative flex w-full max-w-[28rem]">
                                    <img src={imgPreview} className={generateImg} />
                                </div> */}
                                {/* <div className="relative flex w-full max-w-[28rem]">
                                    <img src={imgPreview2} className={generateImg2} />
                                </div> */}
                            </div>
                            <div className="">
                                <Typography variant="h6" color="blue-gray" className="mb-1">
                                    Description
                                </Typography>
                                <EditorToolbar toolbarId={'t1'}/>
                                <ReactQuill theme="snow" value={description} onChange={setDescription} placeholder={"Write something awesome..."} modules={modules('t1')} formats={formats}/>
                            </div>
                        </div>
                        <Button className="mt-6" fullWidth type="submit">
                            {lable?.buttonLabel}
                        </Button>
                    </form>
                </Card>
            </div>
        </>
    );
}