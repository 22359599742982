import React, { useEffect, useState } from "react";
import { Button, Card, Typography } from "@material-tailwind/react";
import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminGeneral, getDonateGeneral, getPartnerGeneral, getProgramGeneral, setLoaderVisibility } from "../../../app/actions/action-creators";
import axios from "axios";
import { API_BASE_URL } from '../../../data/static-config.js';
import parse from 'html-react-parser';

const TABLE_HEAD = ["Title", "Subtitle", "Banner", "Language", <ButtonAdd />];

export function TableDonateGeneral() {
  const data = useSelector((state) => state?.combineReducer?.adminOrganizations?.donateGeneral);
  const TABLE_ROWS = data?.lst;
  const token = useSelector((state) => state?.combineReducer?.adminToken?.token);
  const [isNeedRefresh, setIsNeedRefresh] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
		if (TABLE_ROWS?.length === 0) {
			dispatch(setLoaderVisibility(true))
		} else {
			dispatch(setLoaderVisibility(false))
		}
	}, [TABLE_ROWS]);

  useEffect(() => {
    dispatch(getDonateGeneral({token}));
  }, [isNeedRefresh]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      dangerMode: true,
      buttons: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          dispatch(setLoaderVisibility(true))
          axios.delete(`${API_BASE_URL}/api/donategeneral/${id}`, {
            headers: {
              access_token: token
            }
          }).then((_) => {
            dispatch(setLoaderVisibility(false))
            setIsNeedRefresh(!isNeedRefresh)
          }).catch((error) => {
            dispatch(setLoaderVisibility(false))
            alert("Failed to delete")
          })
        }
      });
  };

  const handleUpdate = (id) => {
    navigate(`/cms/donate/update/${id}`);
  };

  return (
    <div className="p-2">
      <Card className="w-full rounded-none">
        <table className="w-full table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD?.map((head) => (
                <th key={head} className="border-y border-gray-400 bg-blue-gray-50/50 p-2">
                  <Typography
                    variant="small"
                    color="gray"
                    className="leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {TABLE_ROWS != null ? TABLE_ROWS?.map(({id, title, subtitle, languageCode, imgBanner }, index) => (
              <tr key={id} className="even:bg-blue-gray-50/50 hover:cursor-pointer hover:bg-blue-gray-50" >
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {title}
                  </Typography>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {subtitle ? parse(subtitle) : ""}
                  </Typography>
                </td>
                <td onClick={() => handleUpdate(id)}>
                  <img src={imgBanner} className="h-28 rounded"></img>
                </td>
                <td className="p-4" onClick={() => handleUpdate(id)}>
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {languageCode === 1033 ? "English" : "Bahasa Indonesia"}
                  </Typography>
                </td>
                <td className="p-4 h-full flex-col items-center justify-center">
                  <Button color="blue-gray" variant="solid" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleUpdate(id)}>
                    <PencilIcon className="w-4" color="white" />
                    Update
                  </Button>
                  <Button color="red" variant="gradient" size="sm" className="flex items-center gap-2 m-1" onClick={() => handleDelete(id)}>
                    <TrashIcon className="w-4" />
                    Delete
                  </Button>
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
      </Card>
    </div>
  );
}

function ButtonAdd() {
  const navigate = useNavigate()
  const handleAdd = () => {
    navigate("/cms/donate/add");
  }
  return (
    <Button variant="gradient" size="sm" className="flex items-end justify-center gap-2 w-full" color="black" onClick={handleAdd}>
      <PlusCircleIcon className="w-4" />
      Add
    </Button>
  )
}